import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import CreatePreliminaryOrderPage from '@Routes/static/CreatePreliminaryOrderPage';
import InvitationKeyNotFoundPage from '@Routes/static/InvitationKeyNotFoundPage';
import RentInfoPage from '@Routes/static/RentInfoPage';

import { RouteInfo } from '../appRoutes';

const FAQPage = lazy(() => import('./FAQPage'));
const IntroPage = lazy(() => import('./LandingPage/IntroPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage'));
const StaticPageContainer = lazy(() => import('./StaticPage/StaticPageContainer'));
const AboutPage = lazy(() => import('./AboutPage'));

const staticRoutes: RouteInfo[] = [
  {
    path: RouteConfig.Home.template,
    component: IntroPage,
    title: Messages.introPage,
  },
  {
    path: RouteConfig.StaticPage.template,
    component: StaticPageContainer,
    title: Messages.staticPage,
    exact: false,
  },
  {
    path: RouteConfig.NotFound.template,
    component: NotFoundPage,
    title: Messages.notFoundPage,
    exact: true,
  },
  {
    path: RouteConfig.About.template,
    component: AboutPage,
    title: 'msg_about_page_title',
    exact: true,
  },
  {
    path: RouteConfig.CreatePreliminaryOrder.template,
    component: CreatePreliminaryOrderPage,
    title: Messages.createPreliminaryOrder,
    exact: true,
  },
  {
    path: RouteConfig.Faq.template,
    component: FAQPage,
    title: Messages.faqPage,
    exact: true,
  },

  {
    path: RouteConfig.RentInfo.template,
    component: RentInfoPage,
    title: Messages.rentInfoPage,
    exact: true,
  },

  {
    path: RouteConfig.InvitationKeyNotFound.template,
    component: InvitationKeyNotFoundPage,
    title: Messages.invitationKeyNotFoundPage,
    exact: true,
  },
];

export default staticRoutes;
