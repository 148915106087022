import { Nil, Shape } from '@Utils/types';

export const isNil = (val: any): boolean => val === null || val === undefined;

export const isEmpty = (value: any, deep: boolean | undefined = false): boolean => {
  if (isNil(value) || (typeof value === 'string' && value.trim().length === 0)) {
    return true;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return true;
    }
    return deep && value.every(value => isEmpty(value));
  }

  if (value.constructor === Object) {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in value) {
      const notEmpty = !(deep && isEmpty(value[k]));
      if (notEmpty) {
        return false;
      }
    }
    return true;
  }

  return false;
};

export const toNumber = (...args: any[]) => args.map(val => Number.parseInt(val, 10));

export const toFiniteNumberOrNull = (arg: any): number | null => {
  const parsed: number = toNumber(arg)[0];
  return Number.isFinite(parsed) ? parsed : null;
};

export const areFiniteNumbers = (values: any[]): boolean => values.every(val => Number.isFinite(val));

export const areNotGrater = (val1: any, val2: any): boolean => {
  const numbers = toNumber(val1, val2);
  return areFiniteNumbers(numbers) && numbers[0] > numbers[1];
};

export const clearEmptyValues = (data: Shape): Shape =>
  Object.entries(data).reduce<Shape>(
    (result, [key, value]) => ({
      ...result,
      ...(isEmpty(value, true) ? {} : { [key]: value }),
    }),
    {},
  );

export const pick = (source: Shape, props: string[]) =>
  props.reduce((result, property) => ({ ...result, [property]: source[property] }), {});

export const parseJSON = (source: string | Nil, defaultValue: any): any => {
  if (source === undefined || source === null) {
    return defaultValue;
  }
  return JSON.parse(source);
};

export const desc = (sortAttributeName: string): string => `-${sortAttributeName}`;

export const convertToFloatNumber = (arg: any): number => {
  const parsed: number = Number.parseFloat(arg);
  return Number.isFinite(parsed) ? parsed : 0;
};
