import classNames from 'classnames';
import * as React from 'react';

import styles from './Col.module.scss';

export interface ColProps {
  size?: number;
  className?: string;
  textCenter?: boolean;
  textCenterMobile?: boolean;
  constant?: boolean;
}
type ColSizes =
  | 'size1'
  | 'size2'
  | 'size3'
  | 'size4'
  | 'size5'
  | 'size6'
  | 'size7'
  | 'size8'
  | 'size9'
  | 'size10'
  | 'size11'
  | 'size12'
  | 'size13'
  | 'auto';

const Col: React.FunctionComponent<ColProps> = ({
  size,
  children,
  className,
  textCenter,
  textCenterMobile,
  constant,
}) => {
  return (
    <div
      className={classNames(size ? styles[`size${size}` as ColSizes] : styles.auto, className, {
        [styles.textCenter]: textCenter,
        [styles.textCenterMobile]: textCenterMobile,
        [styles.constant]: constant,
      })}
    >
      {children}
    </div>
  );
};

export default Col;
