import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { AdFilterFields } from '@Components/forms/Filter/AdFilters';
import { LocalStorage } from '@Config/constants';
import { RouteConfig } from '@Config/routes';
import { clearEmptyValues } from '@Utils/convertions';
import { SearchParamsHolder, Shape } from '@Utils/types';

import { authActions } from '../store/actions';

import { getFromLocalStorage } from './localStorage';

export interface AuthenticatedComponentProps {
  actions: {
    getUserData: typeof authActions.getUserData.started;
  };
  dispatch: Dispatch;
}

/* tslint:disable: variable-name */
export const authenticatedOnlyComponent = (WrappedComponent: any) => {
  const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch,
    actions: {
      getUserData: bindActionCreators(authActions.getUserData.started, dispatch),
    },
  });

  class AuthenticatedComponent extends React.PureComponent<AuthenticatedComponentProps, {}> {
    componentDidMount() {
      if (this.isLoggedIn()) {
        this.props.actions.getUserData({});
      }
    }

    // eslint-disable-next-line class-methods-use-this
    isLoggedIn() {
      return getFromLocalStorage(LocalStorage.userToken) !== null;
    }

    render() {
      return this.isLoggedIn() ? <WrappedComponent {...this.props} /> : <Redirect to={{ pathname: '/' }} />;
    }
  }

  return connect(null, mapDispatchToProps)(AuthenticatedComponent);
};
/* tslint:enable */

export const buildCategoryPageOnMarketplaceUrl = (catId: string) => {
  const params: AdFilterFields = {
    categories: [catId],
  };

  return `${RouteConfig.Marketplace.buildLink()}${buildFilteredAdsUrl({ filter: params })}`;
};

export const buildCategoryPageOnCategoriesUrl = (categorySlug: string) => {
  return `${RouteConfig.Category.buildLink({ categorySlug })}`;
};

export const buildCategoryPageUrl = (catSlug: string) => {
  return `${RouteConfig.Category.buildLink({ categorySlug: catSlug })}`;
};
// TODO fix url
export const buildFilteredAdsUrl = (params: SearchParamsHolder) => {
  const searchParams: Shape = {};
  Object.keys(params).forEach((paramKey: string) => {
    searchParams[paramKey] = JSON.stringify(params[paramKey as keyof typeof params]);
  });
  return `?${qs.stringify(searchParams)}`;
};

export const mergeSearchParams = (searchParams: Shape, updateParams: Shape, removeEmpty = false) => {
  const mergedParams = {
    ...(searchParams || {}),
    ...updateParams,
  };

  // todo probably empty params should be removed only related to the provide addressvalue, not from all search
  return removeEmpty ? clearEmptyValues(mergedParams) : mergedParams;
};
