import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingSpinner from '@Components/LoadingSpinner';
import { LocalStorage } from '@Config/constants';
import { authActions } from '@Store/actions';
import { getUserData } from '@Store/auth/auth.selector';
import { UserData, UserWithData } from '@Store/auth/auth.types';
import { getFromLocalStorage } from '@Utils/localStorage';
import { HOC } from '@Utils/types';

export interface WithUser {
  user: UserWithData;
}

export default function withUser<P>(BaseComponent: HOC<P, {}>, withSpinner = true) {
  return (props: P) => {
    const user: UserData = useSelector(getUserData);
    const isLoggedIn = user.item === null && getFromLocalStorage(LocalStorage.userToken) !== null;

    if (isLoggedIn && !user.isFetching) {
      const dispatch = useDispatch();
      dispatch(authActions.getUserData.started({}));
    }

    if (!user.item) {
      return withSpinner ? <LoadingSpinner fullscreen /> : null;
    }

    return <BaseComponent user={user} {...props} />;
  };
}
