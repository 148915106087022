import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';

import { RouteInfo } from '../appRoutes';

const MarketplacePage = lazy(() => import('./MarketPlacePage'));

const routes: RouteInfo[] = [
  {
    path: RouteConfig.Marketplace.template,
    component: MarketplacePage,
    title: Messages.marketPlace,
    exact: false,
  },
];

export default routes;
