import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { authenticatedOnlyComponent } from '@Utils/router';

import { RouteInfo } from '../appRoutes';

// @NOTE: AdvertPage causes `Uncaught TypeError: error loading dynamically imported module`
// when opening in development with Firefox 111.0.1. FF Dev edition and other browsers work fine.
const AdvertPage = lazy(() => import('./AdPage'));
const CreateAdPage = lazy(() => import('./CreateAdPage'));
const MyAdvertAvailability = lazy(() => import('./MyAdvertAvailability'));
const MyAdvertEdit = lazy(() => import('./MyAdvertEdit/MyAdvertEdit'));
const MyAdvertsList = lazy(() => import('./MyAdvertsList/MyAdvertsList'));
const MyFavoriteAds = lazy(() => import('./MyFavoriteAds'));
const BulkUpload = lazy(() => import('./BulkUpload'));

const adsRoutes: RouteInfo[] = [
  {
    path: RouteConfig.Advert.template,
    component: AdvertPage,
    title: Messages.advertPageTitle,
    exact: false,
  },

  {
    path: RouteConfig.AdvertNew.template,
    component: authenticatedOnlyComponent(CreateAdPage),
    title: Messages.newAdPage,
  },
  {
    path: RouteConfig.FavoriteAdverts.template,
    component: authenticatedOnlyComponent(MyFavoriteAds),
    title: Messages.myFavourites,
  },

  {
    path: RouteConfig.MyAdverts.template,
    component: authenticatedOnlyComponent(MyAdvertsList),
    title: Messages.companyAdsPage,
  },
  {
    path: RouteConfig.AdvertEdit.template,
    component: authenticatedOnlyComponent(MyAdvertEdit),
    title: Messages.companyAdsPage,
  },
  {
    path: RouteConfig.AdvertAvailability.template,
    component: authenticatedOnlyComponent(MyAdvertAvailability),
    title: Messages.myAdsAvailabilityPage,
  },
  {
    path: RouteConfig.BulkAdvertsUpload.template,
    component: authenticatedOnlyComponent(BulkUpload),
    title: Messages.bulkAdvertsUploadPage,
  },
];

export default adsRoutes;
