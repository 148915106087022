import classNames from 'classnames';
import React, { lazy, Suspense, useMemo } from 'react';
import { FieldInputProps } from 'react-final-form';

import Icon, { IconSizes } from '@Components/Icon';
import LoadingSpinner from '@Components/LoadingSpinner';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { Translator, MessageKeysWithoutParams, getTranslationValue } from '@Utils/translation';

import styles from './Select.module.scss';

const ReactSelect = lazy(() => import('react-select'));

export type OptionItem = {
  label: string;
  value: any;
  options?: OptionItem[];
};

export type SelectSizes = 's' | 'm' | 'l' | 'flex';

export interface SelectProps {
  label?: string;
  selectedValue?: OptionItem;
  placeholder?: MessageKeysWithoutParams | string;
  disabled?: boolean;
  options?: OptionItem[];
  input?: FieldInputProps<OptionItem>;
  isMulti?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  size?: SelectSizes;
  defaultValue?: OptionItem;
  localizeSelectLabels?: boolean;
  onChange?: (value: OptionItem) => void;
  withError?: boolean;
  smallerSelect?: boolean;
  largeDropdown?: boolean;
}

const translateOptions = ({
  options,
  localizeSelectLabels,
  t,
}: {
  options: OptionItem[];
  localizeSelectLabels?: boolean;
  t: Translator;
}) => {
  if (!localizeSelectLabels) {
    return options;
  }

  return options.map(item => ({
    ...item,
    label: t(item.label as MessageKeysWithoutParams),
  }));
};

const DropdownIndicator: React.FunctionComponent = () => {
  return <Icon size={IconSizes.s} icon="arrow-down" className={styles.dropDownPosition} />;
};

// For css we have hardcoded string classes to use global styling
const Select: React.FunctionComponent<SelectProps> = props => {
  const {
    options = [],
    placeholder,
    input,
    isClearable,
    isMulti,
    isSearchable = true,
    isLoading = false,
    size = 'm',
    localizeSelectLabels = false,
    withError,
    selectedValue,
    onChange,
    smallerSelect = false,
    disabled,
    largeDropdown,
  } = props;

  const t = useTranslations();

  const translatedOptions = useMemo(
    () => translateOptions({ options, localizeSelectLabels, t }),
    [options, localizeSelectLabels],
  );

  const selectedValueResult = () => {
    if (!selectedValue) {
      return null;
    }
    let { label } = selectedValue;
    const value = options && options.find(option => option.value === selectedValue.value);
    if (localizeSelectLabels) {
      label = t(selectedValue.label as MessageKeysWithoutParams);
    }
    return { ...value, label };
  };

  const selectValue = () => {
    const optionsListFlattened = options.flatMap(item => (item.options ? [item, ...item.options] : [item]));
    const inputValue = input?.value;
    if (!inputValue) {
      return;
    }
    if (isMulti && options) {
      const arrayValue = inputValue instanceof Array ? inputValue : [inputValue];

      let result = optionsListFlattened.filter(option => {
        return arrayValue.indexOf(option.value) > -1;
      });
      if (localizeSelectLabels) {
        result = result.map(({ label, ...rest }) => ({
          ...rest,
          label: t(label as MessageKeysWithoutParams),
        }));
      }
      return result;
    }

    const value = optionsListFlattened.find(option => option.value === inputValue);
    if (!value) {
      return {
        value: inputValue,
        label: inputValue,
      };
    }
    return { ...value, label: localizeSelectLabels ? t(value.label as MessageKeysWithoutParams) : value.label };
  };

  const handleChange = (value: any) => {
    if (input) {
      if (!value) {
        return input.onChange(isMulti ? [] : null);
      }
      return input.onChange(isMulti ? value.map((entry: { value: any }) => entry.value) : value.value);
    }
    if (onChange) {
      return onChange(value);
    }
  };

  const translatedPlaceholder = getTranslationValue({ value: placeholder || Messages.defaultSelectPlaceholder, t });

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ReactSelect
        {...input}
        isSearchable={isSearchable}
        placeholder={translatedPlaceholder}
        onChange={handleChange}
        options={translatedOptions}
        className={classNames('Select__container', `size-${size}`, {
          'Select--with-error': withError,
          'Select--with-large-dropdown': largeDropdown,
          smallerSelect,
        })}
        classNamePrefix="Select"
        isMulti={isMulti}
        isLoading={isLoading}
        isClearable={isClearable}
        components={{ DropdownIndicator }}
        isDisabled={disabled}
        value={selectedValueResult() || selectValue()}
      />
    </Suspense>
  );
};

export default Select;
