export enum NodeEnvEnum {
  development = 'development',
  production = 'production',
  test = 'test',
}

const config = {
  NODE_ENV: (import.meta.env.VITE_NODE_ENV as NodeEnvEnum) || NodeEnvEnum.development,
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL || '',
  DISABLE_COMPANY_PAGE: import.meta.env.VITE_DISABLE_COMPANY_PAGE === 'true',
  WS_BASE_URL: import.meta.env.VITE_WS_BASE_URL || '',
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN || '',
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT || '',
  DEFAULT_LANGUAGE: import.meta.env.VITE_DEFAULT_LANGUAGE || '',
  SEJICO_FINANCE_URL: import.meta.env.VITE_SEJICO_FINANCE_URL || '',
};

export default config;
