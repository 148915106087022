import React, { lazy } from 'react';
import { CalendarProps } from 'react-big-calendar';

import LoadingSpinner from '@Components/LoadingSpinner';

const CalendarRenderer = lazy(() => import('./CalendarRenderer'));

export interface CalendarComponentProps {
  calendarProps: Omit<CalendarProps, 'localizer'>;
  view?: CalendarViews;
  disableCustomEvent?: boolean;
}

export interface EventProps {
  id?: string;
  title?: string;
  description?: string;
  start?: Date;
  end?: Date;
  type?: EventTypes;
}

export enum CalendarViews {
  day = 'day',
  week = 'week',
  month = 'month',
}

export const EventStyles = {
  default: {
    backgroundColor: '#E6f7f7',
    color: '#00A6A6',
  },
  order: {
    backgroundColor: '#F5EAFE',
    borderColor: '#992BF1',
    color: '#992BF1',
  },
  customEvent: {
    backgroundColor: '#EAEBF3',
    borderColor: '#979FC6',
    color: '#979FC6',
  },
};

export enum EventTypes {
  order = 'order',
  default = 'default',
  customEvent = 'customEvent',
}

const Calendar: React.FunctionComponent<CalendarComponentProps> = ({ calendarProps, view, disableCustomEvent }) => {
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <CalendarRenderer calendarProps={calendarProps} view={view} disableCustomEvent={disableCustomEvent} />
    </React.Suspense>
  );
};

export default Calendar;
