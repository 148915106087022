import classNames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { ButtonProps, ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import styles from '@Components/Buttons/Button/Button.module.scss';
import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography';

interface NavigationLinkProps extends ButtonProps {
  link: string;
  disabledActive?: boolean;
}

const NavigationLink: React.FunctionComponent<NavigationLinkProps> = props => {
  const {
    onClick,
    disabled = false,
    loading = false,
    children = null,
    label = null,
    type = ButtonTypes.button,
    size = ButtonSizes.l,
    link,
    style = ButtonStyles.regular,
    disabledActive = false,
  } = props;

  return (
    <NavLink
      to={link}
      onClick={onClick}
      type={type}
      className={classNames(styles.button, styles[size], styles[style], { [styles.disabled]: disabled })}
      activeClassName={disabledActive ? '' : styles.linkActive}
    >
      {loading ? (
        <Icon icon="spinner" spin size={IconSizes.s} />
      ) : (
        <span className={styles.childrenContainer}>
          {label && <Typography msg={label} tag="span" />}
          {children}
        </span>
      )}
    </NavLink>
  );
};

export default NavigationLink;
