import classNames from 'classnames';
import * as React from 'react';

import { hasFieldError } from '@Utils/form';

import ErrorDisplay from '../ErrorDisplay';

import styles from './inputContainer.module.scss';

export interface InputContainerProps {
  label?: string;
  disabled?: boolean;
  required?: boolean;
  showErrorIcon?: boolean;
  showErrorIconTooltip?: boolean;
  className?: string;
  containerClassName?: string;
  errorDisplayClassName?: string;
  inline?: boolean;
  meta: any;
  fullWidth?: boolean;
  noMargin?: boolean;
  wrapperClassName?: string;
  bottomSpacing?: boolean;
}

const inputContainer: React.FunctionComponent<InputContainerProps> = props => {
  const {
    label,
    disabled = false,
    children = [],
    required = false,
    showErrorIcon = false,
    showErrorIconTooltip = false,
    meta,
    containerClassName,
    className,
    inline,
    fullWidth,
    noMargin,
    wrapperClassName,
    bottomSpacing = true,
    errorDisplayClassName,
  } = props;

  const hasError = hasFieldError(meta);
  return (
    <div
      className={classNames(
        styles.container,
        {
          disabled,
          required,
          [styles.noMargin]: noMargin,
          [styles.inline]: inline,
          [styles.fullWidth]: fullWidth,
          [styles.bottomSpacing]: bottomSpacing,
        },
        wrapperClassName,
        containerClassName,
      )}
    >
      {label && <div className={styles.label}>{label}</div>}
      {required && <span> *</span>}
      <div className={classNames(className)}>{children}</div>
      {hasError && (
        <ErrorDisplay
          className={errorDisplayClassName}
          msg={meta.error || meta.submitError}
          showErrorIcon={showErrorIcon}
          showErrorIconTooltip={showErrorIconTooltip}
        />
      )}
    </div>
  );
};

export default inputContainer;
