import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';

import { RouteInfo } from '../appRoutes';

const CompanyPage = lazy(() => import('./CompanyPage'));

const routes: RouteInfo[] = [
  {
    path: RouteConfig.Company.template,
    component: CompanyPage,
    title: Messages.companyAdsPage,
    exact: false,
  },
];

export default routes;
