import { ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import * as React from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import HorizontalRule from '@Components/HorizontalRule';
import Row from '@Components/layout/Row';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { useTranslations } from '@Hooks/useTranslations';
import { resetFocus } from '@Utils/util';

import styles from './FormWizard.module.scss';

interface FormWizardProps<T> {
  onSubmit: (values: T) => Promise<{}>;
  initialValues?: T;
  validate: (values: T) => ValidationErrors | Promise<ValidationErrors> | undefined;
  renderTopElement: (changeStep: (index: number) => void, activePage: number) => JSX.Element;
  redirectOnCancelClickTo: string;
  idKey?: keyof T;
}

interface ButtonsRowProps {
  handleStepChange: (values: boolean) => void;
  submitting: boolean;
  isLastPage: boolean;
  redirectOnCancelClickTo: string;
  isEditing: boolean;
}

interface FormWizardStateProps<T> {
  page: number;
  values: T | false;
}

const ButtonsRow: React.FunctionComponent<ButtonsRowProps> = ({
  redirectOnCancelClickTo,
  handleStepChange,
  submitting,
  isLastPage,
  isEditing,
}) => {
  const t = useTranslations();

  return (
    <div className={styles.buttonsRowWrapper}>
      <Row justifyBetween alignCenter>
        {!isLastPage ? (
          <Link to={RouteConfig.MyAdverts.buildLink()} className={styles.marginBottomOnSmallerScreen}>
            <Button
              size={ButtonSizes.fixed}
              onClick={() => redirectOnCancelClickTo}
              style={ButtonStyles.outlinedGray}
              label={t(Messages.btnCancel)}
            />
          </Link>
        ) : (
          <Button
            label={t(Messages.btnGoBack)}
            style={ButtonStyles.outlinedGray}
            type={ButtonTypes.button}
            className={styles.marginBottomOnSmallerScreen}
            onClick={e => {
              resetFocus(e);
              handleStepChange(false);
            }}
            size={ButtonSizes.fixed}
          />
        )}
        {!isLastPage ? (
          <Button
            label={t(Messages.btnContinue)}
            type={ButtonTypes.submit}
            size={ButtonSizes.fixed}
            className={styles.marginBottomOnSmallerScreen}
            onClick={resetFocus}
          />
        ) : (
          <Button
            label={t(isEditing ? Messages.updateAdSaveBtn : Messages.createAdSaveAdBtn)}
            type={ButtonTypes.submit}
            disabled={submitting}
            loading={submitting}
            size={ButtonSizes.fixed}
            className={styles.marginBottomOnSmallerScreen}
          />
        )}
      </Row>
    </div>
  );
};

export default class FormWizard<T extends { id?: string }> extends React.PureComponent<
  FormWizardProps<T>,
  FormWizardStateProps<T>
> {
  constructor(props: FormWizardProps<T>) {
    super(props);
    this.state = {
      page: 0,
      // eslint-disable-next-line react/no-unused-state
      values: this.props.initialValues || false,
    };
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  static Page: React.FunctionComponent = ({ children }) => <>{children}</>;

  handleStepChange = (values: T | false) => {
    if (values) {
      return this.setState(state => ({
        // eslint-disable-next-line react/no-unused-state
        values,
        page: Math.min(state.page + 1, React.Children.count(this.props.children) - 1),
      }));
    }
    return this.setState(state => ({
      page: Math.max(state.page - 1, 0),
    }));
  };

  changeStep = (index: number) => this.setState({ page: index });

  render() {
    const {
      children,
      onSubmit,
      initialValues,
      validate,
      renderTopElement,
      redirectOnCancelClickTo,
      idKey = 'id',
    } = this.props;

    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    const isEditing = !!(initialValues && initialValues[idKey]);

    return (
      <Form<T>
        onSubmit={values => (isLastPage ? onSubmit(values) : this.handleStepChange(values))}
        initialValues={initialValues}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <>
            {renderTopElement(this.changeStep, page)}
            <form onSubmit={handleSubmit}>
              {React.Children.toArray(children)[page]}
              <HorizontalRule className={styles.marginBottom} marginTop />
              <ButtonsRow
                handleStepChange={() => {
                  this.handleStepChange(false);
                }}
                submitting={submitting}
                isLastPage={isLastPage}
                redirectOnCancelClickTo={redirectOnCancelClickTo}
                isEditing={isEditing}
              />
            </form>
          </>
        )}
      </Form>
    );
  }
}
