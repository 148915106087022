import classNames from 'classnames';
import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography';

import styles from './Button.module.scss';

export interface ButtonProps {
  loading?: boolean;
  onClick?: (e: React.SyntheticEvent) => any;
  disabled?: boolean;
  type?: ButtonTypes;
  style?: ButtonStyles;
  size?: ButtonSizes;
  label?: string;
  reversed?: boolean;
  className?: string;
}

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export enum ButtonSizes {
  s = 'small',
  l = 'large',
  m = 'medium',
  xl = 'xlarge',
  fill = 'fill',
  fixed = 'fixed',
  unset = 'unset',
}

export enum ButtonStyles {
  outlined = 'outlined',
  outlinedGreen = 'outlinedGreen',
  outlinedGray = 'outlinedGray',
  outlinedRed = 'outlinedRed',
  plain = 'plain',
  plainBold = 'plainBold',
  regular = 'regular',
  link = 'link',
  linkActive = 'linkActive',
  linkTransparent = 'linkTransparent',
  withIcon = 'withIcon',
  withIconWithoutBg = 'withIconWithoutBg',
  withIconOutlinedPrimary = 'withIconOutlinedPrimary',
  withoutStyling = 'withoutStyling',
  plainWithUnderline = 'plainWithUnderline',
  theme1 = 'theme1',
  theme2 = 'theme2',
}

const Button: React.FunctionComponent<ButtonProps> = props => {
  const {
    onClick,
    disabled = false,
    loading = false,
    children = null,
    label = null,
    reversed = false,
    type = ButtonTypes.button,
    size = ButtonSizes.s,
    style = ButtonStyles.regular,
    className,
  } = props;

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNames(
        styles.button,
        styles[size],
        styles[style],
        disabled && styles.disabled,
        reversed && styles.reversed,
        className,
      )}
    >
      {loading && <Icon icon="spinner" size={IconSizes.s} className={styles.loadingIcon} />}
      <span className={classNames(styles.childrenContainer, { [styles.hidden]: loading })}>
        {label && <Typography msg={label} tag="span" />}
        {children}
      </span>
    </button>
  );
};

export default Button;
