import classNames from 'classnames';
import * as React from 'react';

import styles from './GridCol.module.scss';

export interface ColProps {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  constant?: boolean;
  className?: string;
}

type ColSizes = `gridSize${ColProps['size']}`;

const GridCol: React.FunctionComponent<ColProps> = ({ size, constant, children, className }) => {
  return (
    <div className={classNames(styles[`gridSize${size}` as ColSizes], { [styles.constant]: constant }, className)}>
      {children}
    </div>
  );
};

export default GridCol;
