import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';

import { RouteInfo } from '../appRoutes';

const CreateThreadPage = lazy(() => import('./CreateThreadPage'));
const ForumPage = lazy(() => import('./ForumPage'));
const ForumThreadsPage = lazy(() => import('./ForumThreadsPage'));
const ThreadPage = lazy(() => import('./ThreadPage'));
const ExpertsPage = lazy(() => import('./ExpertsPage'));

const forumRoutes: RouteInfo[] = [
  {
    path: RouteConfig.Forum.template,
    component: ForumPage,
    title: Messages.forumPage,
    exact: true,
  },
  {
    path: RouteConfig.ForumThreadList.template,
    component: ForumThreadsPage,
    title: Messages.forumThreadsPageTitle,
  },
  {
    path: RouteConfig.ForumThreadPage.template,
    component: ThreadPage,
    title: Messages.forumThreadPageTitle,
  },
  {
    path: RouteConfig.CreateForumThread.template,
    component: CreateThreadPage,
    title: Messages.createForumThreadPageTitle,
  },
  {
    path: RouteConfig.ExpertsPage.template,
    component: ExpertsPage,
    title: Messages.expertsPageTitle,
  },
];

export default forumRoutes;
