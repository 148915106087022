import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import DocumentTitle from '@Components/DocumentTitle/DocumentTitle';
import LoadingSpinner from '@Components/LoadingSpinner';
import { DEFAULT_HOMEPAGE } from '@Config/routes';
import { useTranslations } from '@Hooks/useTranslations';
import { RouteInfo } from '@Routes/appRoutes';
import routes from '@Routes/index';
import { getUserData } from '@Store/auth/auth.selector';
import { checkMissingPhone } from '@Utils/misc';

const UserPage = lazy(() => import('../routes/user/UserPage'));

export const renderRouteComponent = (props: JSX.IntrinsicAttributes, route: RouteInfo) => {
  const t = useTranslations();
  const user = useSelector(getUserData);
  const isMissingPhone = checkMissingPhone(user.item?.myCompanies);

  if (!user) {
    return <LoadingSpinner />;
  }

  if (isMissingPhone) {
    // this is because of "withUser" decorator on Component.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <UserPage />;
  }

  return (
    <DocumentTitle title={t(route.title)}>
      <route.component {...props} />
    </DocumentTitle>
  );
};

const RoutesRenderer = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        {routes.map(route => (
          <Route
            path={route.path}
            exact={typeof route?.exact === 'undefined' ? true : route?.exact}
            key={route.path}
            component={(props: JSX.IntrinsicAttributes) => renderRouteComponent(props, route)}
          />
        ))}
        <Redirect to={DEFAULT_HOMEPAGE} />
      </Switch>
    </Suspense>
  );
};

export default RoutesRenderer;
