import { Cookies } from 'react-cookie';

export function getCookie(cookieName: string) {
  const cookies = new Cookies();
  return cookies.get(cookieName);
}

export function getCookieBooleanValue(cookieName: string): boolean | null {
  return getCookie(cookieName) ? getCookie(cookieName) === 'true' : null;
}
export function getCookieBooleanValueWithDefault(cookieName: string, defaultValue: boolean): boolean {
  const value = getCookieBooleanValue(cookieName);
  return value === null ? defaultValue : value;
}
export function setCookie(cookieName: string, value: string | object | boolean) {
  const cookies = new Cookies();
  return cookies.set(cookieName, value, { path: '/' });
}
