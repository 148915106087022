import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { useState, useMemo, createContext, useContext } from 'react';
import { Provider } from 'react-redux';

import { LocalStorage, WebViewMessagesTypes, isKindFeatureDisabled } from '@Config/constants';
import { CategoryKind } from '@Graphql/graphqlTypes.generated';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { postMessageToWebView } from '@Utils/mobileWebView';

import App from './app';

interface RootContainerProps {
  store: any;
  history: any;
}

const AppKindContext = createContext({
  appKind: CategoryKind.Agro,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAppKind: (_: CategoryKind) => {},
});

export const useAppKindContext = () => useContext(AppKindContext);

const Root: React.FunctionComponent<RootContainerProps> = ({ store, history }) => {
  const initialKind = isKindFeatureDisabled
    ? CategoryKind.Agro
    : (getFromLocalStorage(LocalStorage.kind) as CategoryKind) || CategoryKind.Agro;

  const [appKind, setAppKind] = useState<CategoryKind>(initialKind);

  const setAppKindAndStorage = (newKind: CategoryKind) => {
    setToLocalStorage(LocalStorage.kind, newKind);
    setAppKind(newKind);
    postMessageToWebView(WebViewMessagesTypes.appKind, newKind);
  };

  const value = useMemo(() => ({ appKind, setAppKind: setAppKindAndStorage }), [appKind]);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppKindContext.Provider value={value}>
          <App />
        </AppKindContext.Provider>
      </ConnectedRouter>
    </Provider>
  );
};

export default Root;
