import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import React, { useMemo, useState } from 'react';

import AdvertMapCard from '@Components/Advert/AdvertMapCard';
import { SearchBarData } from '@Components/Search/SearchBar';
import { MAP_WINDOW_POSITION } from '@Config/constants';
import { RouteConfig } from '@Config/routes';
import { AdvertFragment } from '@Graphql/graphqlTypes.generated';
import bluePin from '@Images/icons/map-pin-blue.png';
import redPin from '@Images/icons/map-pin-red.png';

import styles from './SearchResultsMap.module.scss';

const getBounds = (searchItemsWithLocation: AdvertFragment[]) => {
  const bounds = new google.maps.LatLngBounds();
  searchItemsWithLocation.forEach(item => {
    const { latitude, longitude } = item.coordinates;
    bounds.extend(new google.maps.LatLng(latitude, longitude));
  });
  return bounds;
};

export const MarkerContext = React.createContext({
  close: () => {},
});

interface MapMarkerProps {
  coordinates: { longitude: number; latitude: number };
  icon?: google.maps.Icon;
  slug?: string;
}

const MapMarker: React.FunctionComponent<MapMarkerProps> = ({ coordinates, slug, children, icon }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const markerIcon = icon || {
    url: redPin,
    scaledSize: new google.maps.Size(28, 50),
  };

  const onMarkerClick = () => {
    slug && window.open(RouteConfig.Advert.buildLink({ slug }));
    setIsOpen(false);
  };

  const value = useMemo(() => {
    return { close: () => setIsOpen(false) };
  }, []);

  return (
    <Marker
      position={{ lat: coordinates.latitude, lng: coordinates.longitude }}
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
      onClick={onMarkerClick}
      icon={markerIcon}
    >
      <MarkerContext.Provider value={value}>{isOpen && children}</MarkerContext.Provider>
    </Marker>
  );
};

interface MapMarkerInfoWindowProps {
  item: AdvertFragment;
}

const MapMarkerInfoWindow: React.FunctionComponent<MapMarkerInfoWindowProps> = ({ item }) => {
  return (
    <MarkerContext.Consumer>
      {() => (
        <InfoWindow options={{ disableAutoPan: true }} position={MAP_WINDOW_POSITION}>
          <div className={styles.searchItemCard}>
            <AdvertMapCard advert={item} />
          </div>
        </InfoWindow>
      )}
    </MarkerContext.Consumer>
  );
};

interface MapProps {
  searchItems: AdvertFragment[];
  location?: SearchBarData;
}

const SearchResultsMap: React.FunctionComponent<MapProps> = ({ searchItems, location }) => {
  const cords: google.maps.LatLngLiteral = { lat: 55.953143, lng: 23.2892977 };
  const geoSearch = location?.geoSearch;

  const searchItemsWithLocation = searchItems.filter(searchItem => {
    if (searchItem.coordinates?.latitude && searchItem.coordinates?.latitude) {
      return searchItem;
    }
    return undefined;
  });

  const locationMarkerIcon = {
    url: bluePin,
    scaledSize: new google.maps.Size(28, 50),
  };

  return (
    <GoogleMap
      zoom={8}
      center={cords}
      onLoad={map => {
        map.fitBounds(getBounds(searchItemsWithLocation));
      }}
      mapContainerStyle={{ height: '80vh' }}
    >
      {searchItemsWithLocation.map(item => (
        <MapMarker coordinates={item.coordinates} slug={item.slug} key={item.id}>
          <MapMarkerInfoWindow item={item} />
        </MapMarker>
      ))}
      {geoSearch && (
        <MapMarker
          coordinates={geoSearch}
          key={`${geoSearch.latitude}${geoSearch.longitude}`}
          icon={locationMarkerIcon}
        />
      )}
    </GoogleMap>
  );
};

export default SearchResultsMap;
