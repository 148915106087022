import config, { NodeEnvEnum } from '@Config/config';
import { Messages } from '@Config/messages';
import { OrderStatus } from '@Graphql/graphqlTypes.generated';
import { getInternalFullUrl } from '@Utils/image';
import { CalendarEventType } from '@Utils/types';

export const VERSION = {
  version: '01.30.09',
};

export enum Cookies {
  defaultLang = 'lang',
}

export enum ApplicationLanguages {
  LT = 'lt',
  EN = 'en',
  RU = 'ru',
  PL = 'pl',
}

export const PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.sejicoapp';
export const APPLE_STORE = 'https://apps.apple.com/lt/app/sejico/id1500863916';
export const FB = 'https://www.facebook.com/sejico.lt/';
export const INSTAGRAM = 'https://www.instagram.com/sejicolt/';
export const LINKEDIN = 'https://www.linkedin.com/company/sejico';

export const DEFAULT_LANG = config.DEFAULT_LANGUAGE;

export enum LocalStorage {
  userToken = 'userToken',
  appConfig = 'appConfig',
  translations = 'translations',
  lastPreviewedAds = 'lastPreviewedAds',
  kind = 'kind',
}

export enum ApiErrorStatusCodes {
  forbidden = 403,
}

export const FETCH_LIMIT = 20;
export const FEATURED_FETCH_LIMIT = 4;
export const TOPICAL_FETCH_LIMIT = 8;
export const MARKETPLACE_FETCH_LIMIT = 24;

export enum ErrorCodes {
  noActiveCompany = 'no_active_company',
}

export enum TimeFormat {
  date = 'yyyy-MM-dd',
  dateFormatMoment = 'YYYY-MM-DD',
  timeFormat = 'HH:mm',
  mutationDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'",
  timeSlot = 'h:mm A',
  year = 'yyyy',
}

export const ORDER_MIN_DIFF = 1;
export const ORDER_DATE_DIFF = 3;

export const acceptedImageTypes = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
};

export const acceptedFileTypes = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  ...acceptedImageTypes,
};

export enum WebViewMessagesTypes {
  loginFacebook = 'LOGIN_FACEBOOK',
  loginApple = 'LOGIN_APPLE',
  loginAppleSuccess = 'LOGIN_APPLE_SUCCESS',
  loginGoogle = 'LOGIN_GOOGLE',
  loginFacebookSuccess = 'LOGIN_FACEBOOK_SUCCESS',
  loginGoogleSuccess = 'LOGIN_GOOGLE_SUCCESS',
  loginFacebookFailure = 'LOGIN_FACEBOOK_FAILURE',
  loginGoogleFailure = 'LOGIN_GOOGLE_FAILURE',
  userToken = 'USER_TOKEN',
  logout = 'LOGOUT',
  headerColor = 'HEADER_COLOR',
  appKind = 'APP_KIND',
}

export enum StaticPages {
  privacy = 'privacy',
  terms = 'terms',
  about_us = 'aboutUs',
}

export enum DateFormats {
  day = 'day',
  shortDate = 'shotDate',
  dateWithTime = 'dateWithTime',
  timeSlot = 'timeSlot',
  dayName = 'dayName',
  dayNameShort = 'dayNameShort',
}

export type CategoryEntry = {
  [lng in ApplicationLanguages]: string;
};

export type FormatCategories = {
  [cat in DateFormats]: CategoryEntry;
};

export const languageBasedFormatsMoment: FormatCategories = {
  [DateFormats.shortDate]: {
    [ApplicationLanguages.LT]: 'YYYY-MM-DD',
    [ApplicationLanguages.EN]: 'DD-MM-YYYY',
    [ApplicationLanguages.RU]: 'DD/MM/YYYY',
    [ApplicationLanguages.PL]: 'DD/MM/YYYY',
  },
  [DateFormats.dateWithTime]: {
    [ApplicationLanguages.LT]: 'YYYY-MM-DD HH:mm',
    [ApplicationLanguages.EN]: 'DD-MM-YYYY HH:mm',
    [ApplicationLanguages.RU]: 'DD/MM/YYYY HH:mm',
    [ApplicationLanguages.PL]: 'DD/MM/YYYY HH:mm',
  },
  [DateFormats.timeSlot]: {
    // TODO replace with actual language based formats
    [ApplicationLanguages.LT]: 'HH:mm',
    [ApplicationLanguages.EN]: 'h:mm A',
    [ApplicationLanguages.RU]: 'h:mm A',
    [ApplicationLanguages.PL]: 'h:mm A',
  },
  [DateFormats.dayName]: {
    [ApplicationLanguages.LT]: 'dddd',
    [ApplicationLanguages.EN]: 'dddd',
    [ApplicationLanguages.RU]: 'dddd',
    [ApplicationLanguages.PL]: 'dddd',
  },
  [DateFormats.dayNameShort]: {
    [ApplicationLanguages.LT]: 'dd',
    [ApplicationLanguages.EN]: 'dd',
    [ApplicationLanguages.RU]: 'dd',
    [ApplicationLanguages.PL]: 'dd',
  },
  [DateFormats.day]: {
    [ApplicationLanguages.LT]: 'DD',
    [ApplicationLanguages.EN]: 'DD',
    [ApplicationLanguages.RU]: 'DD',
    [ApplicationLanguages.PL]: 'DD',
  },
};

export const languageBasedFormats: FormatCategories = {
  [DateFormats.shortDate]: {
    [ApplicationLanguages.LT]: 'yyyy-MM-dd',
    [ApplicationLanguages.EN]: 'dd-MM-yyyy',
    [ApplicationLanguages.RU]: 'dd/MM/yyyy',
    [ApplicationLanguages.PL]: 'dd/MM/yyyy',
  },
  [DateFormats.dateWithTime]: {
    [ApplicationLanguages.LT]: 'yyyy-MM-dd HH:mm',
    [ApplicationLanguages.EN]: 'dd-MM-yyyy HH:mm',
    [ApplicationLanguages.RU]: 'dd/MM/yyyy HH:mm',
    [ApplicationLanguages.PL]: 'dd/MM/yyyy HH:mm',
  },
  [DateFormats.timeSlot]: {
    // TODO replace with actual language based formats
    [ApplicationLanguages.LT]: 'HH:mm',
    [ApplicationLanguages.EN]: 'h:mm A',
    [ApplicationLanguages.RU]: 'h:mm A',
    [ApplicationLanguages.PL]: 'h:mm A',
  },
  [DateFormats.dayName]: {
    [ApplicationLanguages.LT]: 'EEEE',
    [ApplicationLanguages.EN]: 'EEEE',
    [ApplicationLanguages.RU]: 'EEEE',
    [ApplicationLanguages.PL]: 'EEEE',
  },
  [DateFormats.dayNameShort]: {
    [ApplicationLanguages.LT]: 'E',
    [ApplicationLanguages.EN]: 'E',
    [ApplicationLanguages.RU]: 'E',
    [ApplicationLanguages.PL]: 'E',
  },
  [DateFormats.day]: {
    [ApplicationLanguages.LT]: 'dd',
    [ApplicationLanguages.EN]: 'dd',
    [ApplicationLanguages.RU]: 'dd',
    [ApplicationLanguages.PL]: 'dd',
  },
};

export const defaultStartTime = '07:00';
export const defaultEndTime = '23:00';
export const defaultMinutesToAdd = 60;

export const ADMINISTRATION_FEE = 0;
export const CURRENCY_EUR = 'eur';
export const CURRENCY_PLN = 'zł';
export const AUTO_SUGGESTION_COUNTRIES = ['lt'];

export const MAX_IMG_SIZE = 5;
export const MAX_FILE_SIZE = 1;
export const MAIN_GREEN_COLOR_HEX = '#4CAF50';
export const MAX_ADVERT_IMAGES = 5;
export const MAX_AUCTION_IMAGES = 5;
export const SEARCH_ITEM_TITLE_LENGTH = 40;
export const META_DEFAULTS = {
  title: Messages.defaultMetaTitle,
  description: Messages.defaultMetaDescription,
  type: 'website',
  url: window.location.origin,
  image: getInternalFullUrl('/ogImage.png'),
} as const;

export const MediaQueries = {
  desktopL: 1440,
  desktop: 1200,
  desktopS: 1024,
  tablet: 768,
  mobile: 576,
};

export const MAX_DESC_LENGTH = 600;
export const MAX_TITLE_LENGTH = 100;
export const SCROLL_THRESHOLD_PX = 40;
export const DELAY_MS = 1000;

enum IconSizes {
  xs = 'xs',
  ss = 'ss',
  s = 's',
  m = 'm',
  l = 'l',
  logo = 'logo',
  logoBig = 'logoBig',
  auto = 'auto',
  paymentCard = 'paymentCard',
}

export const size: Record<IconSizes, {}> = {
  xs: {
    width: 10,
    height: 10,
  },
  ss: {
    width: 15,
    height: 15,
  },
  s: {
    width: 20,
    height: 20,
  },
  m: {
    width: 25,
    height: 25,
  },
  l: {
    width: 100,
    height: 100,
  },
  logo: {
    width: 127,
    height: 36,
  },
  logoBig: {
    width: 156,
    height: 45,
  },
  paymentCard: {
    width: 90,
    height: 30,
  },
  auto: {
    width: '100%',
    height: '100%',
  },
};

export const orderPendingStatuses: ReadonlyArray<OrderStatus> = [OrderStatus.Created];

export const orderAcceptedStatuses: ReadonlyArray<OrderStatus> = [
  OrderStatus.Accepted,
  OrderStatus.Active,
  OrderStatus.Finished,
  OrderStatus.Unfinished,
  OrderStatus.Ended,
];

export const cancelledStatuses: ReadonlyArray<OrderStatus> = [
  OrderStatus.Declined,
  OrderStatus.Canceled,
  OrderStatus.Expired,
];

export const orderEventTypesByCalendarEvent = {
  [CalendarEventType.PENDING]: orderPendingStatuses,
  [CalendarEventType.ACCEPTED]: orderAcceptedStatuses,
  [CalendarEventType.CUSTOM]: [] as ReadonlyArray<OrderStatus>,
  [CalendarEventType.DEFAULT]: cancelledStatuses,
} as const;

export enum InvoiceTypes {
  PDF = 'application/pdf',
}

export const UserTourSteps = {
  advertPage: {
    adStep1: 'ad_step1',
    adStep2: 'ad_step2',
    adStep3: 'ad_step3',
    adStep4: 'ad_step4',
  },
  authenticationPage: {
    authStep1: 'auth_step1',
    authStep2: 'auth_step2',
    authStep3: 'auth_step3',
  },
  companyPage: {
    companyStep1: 'company_step1',
    companyStep2: 'company_step2',
    companyStep3: 'company_step3',
  },
};

export enum USER_TOURS {
  advertPageTour = 'advertPageTour',
  authenticationPageTour = 'authenticationPageTour',
  companyPageTour = 'companyPageTour',
}

export enum UserTourStates {
  on = 'on',
  off = 'off',
}

export enum FeatureToggles {
  auction = 'auction',
  payment = 'payment',
}

export enum StripeDeclineCodes {
  authenticationRequired = 'authentication_required',
  approveWithId = 'approve_with_id',
  callIssuer = 'call_issuer',
  cardNotSupported = 'card_not_supported',
  cardVelocityExceeded = 'card_velocity_exceeded',
  currencyNotSupported = 'currency_not_supported',
  doNotHonor = 'do_not_honor',
  doNotTryAgain = 'do_not_try_again',
  duplicateTransaction = 'duplicate_transaction',
  expiredCard = 'expired_card',
  fraudulent = 'fraudulent',
  genericDecline = 'generic_decline',
  incorrectNumber = 'incorrect_number',
  incorrectCvc = 'incorrect_cvc',
  incorrectPin = 'incorrect_pin',
  incorrectZip = 'incorrect_zip',
  insufficientFunds = 'insufficient_funds',
  invalidAccount = 'invalid_account',
  invalidAmount = 'invalid_amount',
  invalidCvc = 'invalid_cvc',
  invalidExpiryYear = 'invalid_expiry_year',
  invalidNumber = 'invalid_number',
  invalidPin = 'invalid_pin',
  issuerNotAvailable = 'issuer_not_available',
  lostCard = 'lost_card',
  merchantBlacklist = 'merchant_blacklist',
  newAccountInformationAvailable = 'new_account_information_available',
  noActionTaken = 'no_action_taken',
  notPermitted = 'not_permitted',
  offlinePinRequired = 'offline_pin_required',
  onlineOrOfflinePinRequired = 'online_or_offline_pin_required',
  pickupCard = 'pickup_card',
  pinTryExceeded = 'pin_try_exceeded',
  processingError = 'processing_error',
  reenterTransaction = 'reenter_transaction',
  restrictedCard = 'restricted_card',
  revocationOfAllAuthorizations = 'revocation_of_all_authorizations',
  revocationOfAuthorization = 'revocation_of_authorization',
  securityViolation = 'security_violation',
  serviceNotAllowed = 'service_not_allowed ',
  stolenCard = 'stolen_card',
  stopPaymentOrder = 'stop_payment_order',
  testmodeDecline = 'testmode_decline',
  transactionNotAllowed = 'transaction_not_allowed',
  tryAgainLater = 'try_again_later',
  withdrawalCountLimitExceeded = 'withdrawal_count_limit_exceeded',
}

export const isKindFeatureDisabled = config.NODE_ENV === NodeEnvEnum.production;

export const MAP_WINDOW_POSITION = {
  lat: 33.772,
  lng: -117.214,
};
