// `Yield` yields `any` anytime you use it, therefore TS above v4.2 says it's not happy.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Action, Success } from 'typescript-fsa';

import { WebViewMessagesTypes } from '@Config/constants';
import { RouteConfig } from '@Config/routes';
import {
  acceptCompanyInvitation,
  authApple,
  authFacebook,
  authGoogle,
  executeLoginQuery,
  executeRegisterQuery,
} from '@Graphql/auth/auth.mutations';
import { getUser } from '@Graphql/auth/auth.queries';
import {
  AppleLoginInput,
  AppleLoginPayload,
  FacebookLoginInput,
  FacebookLoginPayload,
  GetUserQuery,
  GoogleLoginInput,
  GoogleLoginPayload,
  RegisterUserMutation,
} from '@Graphql/graphqlTypes.generated';
import { getInvitationKey } from '@Store/auth/auth.selector';
import { removeUserToken, saveUserToken } from '@Utils/auth';
import { makeAppActionType } from '@Utils/mobileWebView';
import { callGraphql, saveFormWithGraphql } from '@Utils/sagas';
import { UserUrlTypes } from '@Utils/types';

import { locationChange } from '../navigation/navigation.actions';

import {
  getUserData,
  linkUserToCompany,
  login,
  loginWithApple,
  loginWithFacebook,
  loginWithGoogle,
  logout,
  register,
} from './auth.actions';
import { InvitationKeyPayload, LoginActionPayload, LoginActionSuccess, RegisterActionPayload } from './auth.types';

export function* loginSaga(action: Action<LoginActionPayload>) {
  yield removeUserToken();
  yield saveFormWithGraphql(executeLoginQuery, action, login);
}

export function* loginSuccessSaga(action: Action<LoginActionSuccess>) {
  const { referer } = action.payload.params;
  yield saveUserToken(action.payload.result.token);

  const invitationKey = yield select(getInvitationKey);

  if (invitationKey) {
    yield put(linkUserToCompany.started({ invitationKey }));
  } else {
    yield put(locationChange({ path: referer || RouteConfig.Marketplace.buildLink() }));
  }
}

export function* registerSaga(action: Action<RegisterActionPayload>) {
  yield saveFormWithGraphql<RegisterActionPayload, RegisterUserMutation, any>(executeRegisterQuery, action, register);
}

export function* registerSuccessSaga(action: Action<Success<RegisterActionPayload, RegisterUserMutation>>) {
  saveUserToken(action.payload.result.registerUser?.token);
  const invitationKey = yield select(getInvitationKey);

  if (invitationKey) {
    yield put(linkUserToCompany.started({ invitationKey }));
  } else {
    yield put(locationChange({ path: RouteConfig.User.buildLink({ type: UserUrlTypes.company }) }));
  }
}

export function* getUserDataSaga(action: Action<{}>) {
  yield callGraphql<{}, GetUserQuery>(getUser, action, getUserData);
}

export function* onGetUserSuccess(action: Action<Success<{}, GetUserQuery>>) {
  const { result } = action.payload;
  if (!result || !result.me.info.id) {
    yield put(locationChange({ path: RouteConfig.Home.buildLink() }));
  }
}

export function* logoutSaga() {
  yield removeUserToken();
}

function* loginWithFacebookSaga(action: Action<FacebookLoginInput>) {
  const { accessToken } = action.payload;
  if (accessToken) {
    yield removeUserToken();
    yield callGraphql<FacebookLoginInput, FacebookLoginPayload>(authFacebook, action, loginWithFacebook);
  } else {
    throw new Error('msg_error_facebook_login_missing_token');
  }
}

function* loginWithAppleSaga(action: Action<AppleLoginInput>) {
  try {
    yield removeUserToken();
    yield callGraphql<AppleLoginInput, AppleLoginPayload>(authApple, action, loginWithApple);
  } catch (error) {
    yield put(loginWithApple.failed({ error, params: action.payload }));
  }
}

function* loginWithGoogleSaga(action: Action<GoogleLoginInput>) {
  try {
    const { accessToken } = action.payload;
    if (accessToken) {
      yield removeUserToken();
      yield callGraphql<GoogleLoginInput, GoogleLoginPayload>(authGoogle, action, loginWithGoogle);
    } else {
      throw new Error('msg_error_google_login_missing_token');
    }
  } catch (error) {
    yield put(loginWithGoogle.failed({ error, params: action.payload }));
  }
}

export function* refreshToken(action: Action<{ token: string }>) {
  yield saveUserToken(action.payload.token);
}

export function* linkUserToCompanyStarted(action: Action<InvitationKeyPayload>) {
  yield callGraphql<InvitationKeyPayload, {}>(acceptCompanyInvitation, action, linkUserToCompany);
}

export function* linkUserToCompanyDone() {
  yield put(locationChange({ path: RouteConfig.User.buildLink({ type: UserUrlTypes.company }) }));
}

export function* watchAuthSaga() {
  yield all([
    yield takeLatest(login.started, loginSaga),
    yield takeLatest(login.done, loginSuccessSaga),
    yield takeLatest(register.started, registerSaga),
    yield takeLatest(register.done, registerSuccessSaga),

    yield takeLatest(logout.started, logoutSaga),
    yield takeLatest(getUserData.started, getUserDataSaga),
    yield takeLatest(getUserData.done, onGetUserSuccess),
    yield takeLatest(loginWithFacebook.started, loginWithFacebookSaga),
    yield takeLatest(loginWithFacebook.done, loginSuccessSaga),
    yield takeLatest(loginWithGoogle.started, loginWithGoogleSaga),
    yield takeLatest(loginWithGoogle.done, loginSuccessSaga),
    yield takeLatest(loginWithApple.started, loginWithAppleSaga),
    yield takeLatest(loginWithApple.done, loginSuccessSaga),
    yield takeLatest(linkUserToCompany.started, linkUserToCompanyStarted),
    yield takeLatest(linkUserToCompany.done, linkUserToCompanyDone),
    yield takeLatest(makeAppActionType(WebViewMessagesTypes.userToken), refreshToken),
  ]);
}
