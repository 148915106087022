import { isAfter, eachDayOfInterval, startOfDay, format, isBefore, addDays } from 'date-fns';

import { EventType } from '@Components/Modal/CalendarEventModal';
import {
  ApplicationLanguages,
  DateFormats,
  DEFAULT_LANG,
  TimeFormat,
  defaultEndTime,
  defaultStartTime,
  languageBasedFormats,
} from '@Config/constants';

export const buildDateAndTime = (dateString: string | Date | null, timeString: string): Date => {
  const [hours, minutes] = timeString.split(':').map(Number);

  if (!dateString) {
    const today = new Date();
    today.setHours(hours);
    today.setMinutes(minutes);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today;
  }

  const date = dateString instanceof Date ? format(dateString, TimeFormat.date) : dateString;
  const [year, month, day] = date.split('-').map(Number);

  return new Date(year, month - 1, day, hours, minutes);
};

export const formatDateOrTimeToString = (dateOrTime: Date, dateOrTimeFormat: string): string => {
  return format(dateOrTime, dateOrTimeFormat);
};

export const selectedDayIsAfterYesterday = (selectedDate: Date): boolean => {
  return !!selectedDate && !isBefore(startOfDay(selectedDate), startOfDay(new Date()));
};

export const getDateFromDateTime = (date: string | Date, currentLanguage?: ApplicationLanguages) => {
  const dateFormat = currentLanguage ? getDateFormat(currentLanguage) : TimeFormat.date;
  return format(new Date(date), dateFormat);
};

export const getTimeFromDateTime = (date: string | Date) => {
  return format(new Date(date), TimeFormat.timeFormat);
};

export const getDateDiffInHours = (startDate: Date, endDate: Date) => {
  const diffInMilliseconds = endDate.getTime() - startDate.getTime();
  return Math.floor(diffInMilliseconds / (1000 * 60 * 60));
};

export const getDateDiffInDays = (startDate: Date, endDate: Date) => {
  const diffInMilliseconds = startOfDay(endDate).getTime() - startOfDay(startDate).getTime();
  return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
};

export const getDateFormat = (currentLanguage: ApplicationLanguages, dateFormatted?: DateFormats) => {
  const language = currentLanguage || DEFAULT_LANG;
  const format = dateFormatted || DateFormats.shortDate;
  return languageBasedFormats[format][language];
};

export const languageBasedDate = (date: string | Date, language: ApplicationLanguages, dateFormatted?: DateFormats) => {
  const dateFormat = getDateFormat(language, dateFormatted);
  return format(new Date(date), dateFormat || TimeFormat.date);
};

export const languageBasedTimeSlot = (
  slotStart: string | Date,
  slotEnd: string | Date,
  language: ApplicationLanguages,
) => {
  const timeFormat = languageBasedFormats.timeSlot[language];
  const startDate = format(new Date(slotStart), timeFormat || TimeFormat.timeSlot);
  const endDate = format(new Date(slotEnd), timeFormat || TimeFormat.timeSlot);
  return `${startDate} - ${endDate}`;
};

/*Splits dates by day in available time zones */
export const splitDateRangeToDays = (startDate: Date, endDate: Date): EventType[] => {
  const calculationDate = startDate;
  const endDateStartOfDay = startOfDay(endDate);
  const result = [];
  let start = calculationDate;
  let end = buildDateAndTime(format(new Date(start), TimeFormat.date), defaultEndTime);
  while (isBefore(end, endDateStartOfDay)) {
    result.push({ start, end });
    start = buildDateAndTime(format(addDays(start, 1), TimeFormat.date), defaultStartTime);
    end = buildDateAndTime(format(new Date(start), TimeFormat.date), defaultEndTime);
  }
  if (isBefore(start, endDate)) {
    result.push({ start, end: endDate });
  }

  return result;
};

const calculateDuration = (diffInMilliseconds: number): { days: number; hours: number; minutes: number } => {
  const MS_PER_MINUTE = 60000;
  const MS_PER_HOUR = MS_PER_MINUTE * 60;
  const MS_PER_DAY = MS_PER_HOUR * 24;

  const days = Math.floor(diffInMilliseconds / MS_PER_DAY);
  const hours = Math.floor((diffInMilliseconds % MS_PER_DAY) / MS_PER_HOUR);
  const minutes = Math.floor((diffInMilliseconds % MS_PER_HOUR) / MS_PER_MINUTE);

  return { days, hours, minutes };
};

export const formatDuration = (remainingTime: number) => {
  const result = [];
  const { days, hours, minutes } = calculateDuration(remainingTime);

  if (days > 0) {
    result.push(`${days}D`);
  }

  if (hours > 0) {
    result.push(`${hours}H`);
  }

  result.push(`${minutes}MIN`);

  return result.join(' ');
};

export const formatSingleValueDuration = (createdAt: Date): string => {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - createdAt.getTime();
  const { days, hours, minutes } = calculateDuration(diffInMilliseconds);

  if (days > 0) {
    return `${days}d`;
  }

  if (hours > 0) {
    return `${hours}h`;
  }

  return `${minutes}min`;
};

export const getDateRange = (startDate: Date, endDate: Date) => eachDayOfInterval({ start: startDate, end: endDate });

const getLaterDate = (a: Date, b: Date) => (isAfter(b, a) ? b : a);

export const countSlots = (startDate: Date, endDate: Date, calendarDate: Date): number => {
  const startDateVisible = startOfDay(getLaterDate(calendarDate, startDate));
  return getDateDiffInDays(startDateVisible, startOfDay(endDate)) + 1;
};

export const getInitialDates = () => {
  const currentTime = new Date();
  const currentMonthStart = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
  const currentMonthEnd = new Date(currentTime.getFullYear(), currentTime.getMonth() + 1, 0);
  const initialStartDate = currentMonthStart.toISOString().slice(0, 10);
  const initialEndDate = currentMonthEnd.toISOString().slice(0, 10);

  return { initialStartDate, initialEndDate };
};

export function getRelativeTimeString(date: Date | number | undefined, lang = navigator.language): string {
  if (!date) {
    return '';
  }

  const CUTOFF_ALLOWANCE = 0.8;
  const SECS_MINUTE = 60;
  const SECS_HOUR = SECS_MINUTE * 60;
  const SECS_DAY = SECS_HOUR * 24;
  const SECS_WEEK = SECS_DAY * 7;
  const SECS_MONTH = SECS_DAY * 30;
  const SECS_YEAR = SECS_DAY * 365;
  const cutoffs = [SECS_MINUTE, SECS_HOUR, SECS_DAY, SECS_WEEK, SECS_MONTH, SECS_YEAR, Infinity];

  const timeMs = typeof date === 'number' ? date : date.getTime();

  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  const cutoffWithAllowance = cutoffs.map(cut => Math.floor(cut / CUTOFF_ALLOWANCE));

  const units: Intl.RelativeTimeFormatUnit[] = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

  const unitIndex = cutoffWithAllowance.findIndex(cutoff => cutoff > Math.abs(deltaSeconds));

  const divisor = unitIndex ? cutoffWithAllowance[unitIndex - 1] : 1;

  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}
