import { useSelector } from 'react-redux';

import { CURRENCY_EUR, CURRENCY_PLN } from '@Config/constants';
import { getCurrentLanguage } from '@Store/app/app.selectors';

export const useCurrency = () => {
  const currentLanguage = useSelector(getCurrentLanguage);

  return currentLanguage === 'pl' ? CURRENCY_PLN : CURRENCY_EUR;
};
