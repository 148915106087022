/* eslint-disable max-lines */
export default {
  msg_page_bulk_adverts_upload: 'Bulk ad upload',
  msg_error_invalid_advert_category: 'Wrong category',
  msg_my_ads_bulk_upload_button: 'Upload bulk ads file',
  msg_bulk_upload_page_hero_title: 'You can upload multiple ads at once!',
  msg_bulk_upload_page_hero_subtext: 'You can upload bulk ads file, which can be public or private.',
  msg_bulk_upload_form_title: 'Bulk ads upload form',
  msg_bulk_upload_form_ad_privacy_field: 'Please indicate the status of your ads.',
  msg_bulk_upload_form_ad_privacy_public_type: 'Public ads',
  msg_bulk_upload_form_ad_privacy_private_type: 'Private ads',
  msg_bulk_upload_form_upload_file_field: 'Bulk ads list (.csv format)',
  msg_btn_csv_upload: 'Upload a file',
  msg_button_submit_bulk_upload: 'Submit',
  msg_error_csv_import_invalid_file_format: 'Wrong format, please upload .csv file.',
  msg_bulk_upload_completed_title: 'Bulk ads were successfully uploaded!',
  msg_bulk_upload_completed_subtitle: 'You can find these ads under My ads section',
  msg_error_csv_import_invalid_value: 'Error in line {{lineNumber}}: {{columnTitle}} value "{{value}}" is invalid',
  msg_error_csv_import_specify_error_location: 'Error in line {{lineNumber}}: {{columnTitle}} value "{{value}}"',
  msg_company_phone_label: 'Phone',
  msg_advert_create_advert_location_field_explanation:
    'Please indicate your address, let users find your ad in the map.',
  msg_advert_create_advert_valid_until: 'Valid until',
  msg_orders_map_no_results_found: 'No results were found',
  msg_btn_submit_filters: 'Search',
  msg_advert_create_status_selection_field_field_description: 'Choose ad privacy',
  msg_advert_create_status_selection_placeholder: 'Select ad privacy',
  msg_ad_status_active_selection: 'Public ad',
  msg_ad_status_private_selection: 'Private ad',
  msg_order_total_order_price: 'Total order price',
  msg_order_item_order_date: 'Order date',
  msg_bank_info_updated_title_create: 'Bank transfer information saved!',
  msg_bank_info_updated_subtitle_create: 'You will be later able to update this information.',
  msg_userpage_transfer_info_button_create: 'Add bank transfer information',
  msg_error_invalid_iban: 'Incorrect IBAN',
  btn_label_clear_filters: 'Clear filters',
  msg_btn_hide_location_bar: 'Hide filters',
  msg_btn_show_location_bar: 'Show filters',
  msg_label_authentication_tour_auth_step1: 'Please complete the highlighted fields: email, username and password.',
  msg_label_authentication_tour_auth_step2:
    'Please check the box and confirm that you agree with our Privacy policy and to receive newsletters.',
  msg_label_authentication_tour_auth_step3: 'Click "Register" and become a member of our community!',
  msg_label_company_tour_company_step1: 'Please indicate your status and complete the form below.',
  msg_label_company_tour_company_step2: 'Save the provided information.',
  msg_label_company_tour_company_step3: 'Thats it! You can now go to marketplace and enjoy all Sejico benefits!',
  msg_company_advert_is_set_to_private: 'Your ad is private!',
  msg_company_advert_private_status_title: 'Click to make this ad private',
  msg_company_advert_private_status_subtitle: 'The ad will only be visible to your group members',
  msg_status_created_email: 'You have received a new order!',
  msg_status_canceled_email: 'Order was cancelled!',
  msg_status_unfinished_email: 'Order is unfinished!',
  msg_status_finished_email: 'Order is finished!',
  msg_status_expired_email: 'Order has expired. Please make a new order.',
  msg_status_ended_email: 'Order is ended!',
  msg_status_declined_email: 'Order was declined!',
  msg_status_active_email: 'Order is active!',
  msg_status_accepted_email: 'Order was accepted!',
  msg_my_adverts_display_views_count: '{{viewCount}} views',
  msg_thread_created_at_date: 'Created at {{date}}',
  msg_order_id_in_confirm_services_received: 'No. {{orderId}}',
  msg_label_footer_text:
    'The project is funded under 2014 – 2020 Operational Programme for the European Union Funds Investments 1st priority “Promotion of Research, Experimental Development and Innovation” implementation measure No. J05-LVPA-K “Intellect. Joint Science – Business Projects” (Intelektas. Bendri mokslo – verslo projektai.)',
  msg_auction_bidders: '{{value}} bidders',
  msg_auction_active_status_with_expiration: 'Ends in {{value}}',
  msg_auction_active_status_no_expiration: '-',
  msg_label_ago: '{{value}} ago',
  msg_verification_email_title: 'Your email was verified!',
  msg_verification_email_subtitle: 'You can start using Sejico!',
  msg_userpage_update_company_tab: 'Company information',
  msg_userpage_update_billing_info_tab: 'Billing information',
  msg_userpage_invoice_info_tab: 'Invoices',
  msg_userpage_company_reviews_tab: 'My reviews',
  msg_sort_adverts_option_advert_newest: 'Newest on top',
  msg_userpage_change_payments_tab: 'Credit cards',
  msg_userpage_change_password_tab: 'Users information',
  msg_switcher_topic: 'Choose kind',
  msg_switcher_kind_agro: 'Sejico Agro',
  msg_switcher_kind_agro_short: 'Agro',
  msg_switcher_kind_constructions_short: 'Constructions',
  msg_switcher_kind_constructions: 'Sejico Constructions',
  msg_switcher_kind_agro_paragraph: 'Sejico Agro',
  msg_switcher_kind_constructions_paragraph: 'Sejico Constructions',
  msg_switcher_button_go: 'Go',
  msg_tooltip_company_phone_title: 'Please enter the phone number',
  msg_tooltip_company_phone_subtitle: 'Please use international format +00',
  msg_tooltip_company_name_title: 'Company title',
  msg_sort_adverts_option_advert_lowest_price: 'Lowest price on top',
  msg_tooltip_company_name_subtitle: 'Enter the title, which will be displayed for other users.',
  msg_tooltip_company_area_title: 'Choose activity code.',
  msg_tooltip_company_area_subtitle: 'Please choose the activity closest to your operating area.',
  msg_ton_amount: 'Indicate the price (eur/t)',
  msg_title_website_title: 'Sejico',
  msg_thread_your_thread_reply: 'Comment',
  msg_thread_reply_success_title: 'Thank you for your comment!',
  msg_thread_reply_logged_out_form: 'Only logged in users are able to comment.',
  msg_thread_reply_author_info: 'Author message',
  msg_thread_posted_by_author: 'Author {{author}}',
  msg_thread_posted_by: 'Author',
  msg_thread_logged_out_create_form: 'Only the logged in users are able to comment.',
  msg_thread_created_at: 'Created',
  msg_thread_add_thread_reply: 'Comment',
  msg_sort_adverts_option_advert_highest_rating: 'Higher rated on top',
  msg_sort_adverts_option_advert_highest_price: 'Highest price on top',
  msg_sort_adverts_desc_title: 'Title (Z-A)',
  msg_sort_adverts_by: 'Sort by',
  msg_sort_adverts_asc_title: 'Title (A-Z)',
  msg_service_is_not_received_subheader: 'Services or goods were not delivered.',
  msg_service_is_not_received_modal_button: 'I confirm that the order was not completed',
  msg_service_is_not_received_header: 'The order was not completed!',
  msg_sent_orders_tab: 'Sent orders',
  msg_search_results: 'Search results',
  msg_search_bar_small_placeholder: 'Search',
  msg_search_bar_placeholder: 'Enter the keyword',
  msg_reset_password_title: 'Enter new password',
  msg_reset_password_success_title: 'Password was changed successfully!',
  msg_reset_password_success_subtitle: 'Please login with your new password.',
  msg_calendar_next: 'Next',
  msg_calendar_date: 'Date',
  msg_reset_password_subtitle: 'The password should be at least 5 characters long, there should be at least 1 digit.',
  msg_register_terms_of_usage:
    'I have read and agree with <a href="/page/salygos" target="_blank"> naudojimosi taisyklėmis</a> and <a href="/page/privatumo-politika" target="_blank">privatumo politika</a>.',
  msg_register_marketing_material: 'I agree to receive marketing material.',
  msg_register_form_register: 'Register',
  msg_received_orders_tab: 'Received orders',
  msg_payment_pay_with_selected_card: 'Pay with selected card',
  msg_payment_no_cards_yet: 'There are no cards yet.',
  msg_payment_card_number_display_format: '•••• •••• •••• {{last4}}',
  msg_btn_delete_credit_card: 'Delete card',
  msg_payment_card_delete_success_modal_title_gerai: 'The card was removed!',
  msg_payment_card_delete_success_modal_title: 'Card was removed from the list',
  msg_payment_card_delete_modal_title: 'Are you sure you want to remove card from the list?',
  msg_page_user: 'My settings',
  msg_page_category: 'Category',
  msg_page_register: 'Register',
  msg_page_not_found: 'Page was not found!',
  msg_page_new_advert: 'Create ad',
  msg_page_my_orders: 'My orders',
  msg_page_my_favourites: 'Favorite ads',
  msg_page_my_auctions: 'My auctions',
  msg_page_my_adverts: 'My ads',
  msg_page_my_account: 'My account',
  msg_page_marketplace: 'Marketplace',
  msg_page_login: 'Login',
  msg_page_landing: 'Sejico - an innovative platform for all agriculture and farming people.',
  msg_page_intro: 'Sejico - an innovative platform for all agriculture and farming people.',
  msg_page_go_to_new_auction: 'Create auction',
  msg_page_go_to_new_ad: 'Create ad',
  msg_page_forum: 'Forum',
  msg_page_static: 'Static page',
  msg_page_forgot_password: 'Forgot password?',
  msg_page_faq_title: 'FAQ',
  msg_page_contact: 'Contacts',
  msg_page_about: 'About',
  msg_page_company_orders: 'Orders',
  msg_page_company_ads: 'My ads',
  msg_page_calendar_title: 'Calendar',
  msg_page_auctions: 'Auctions',
  msg_page_advert_title: 'Ad',
  msg_page_activity_title: 'Activity',
  msg_order_summary_title_order_was_declined: 'Order was declined!',
  msg_order_summary_title_order_was_cancelled: 'Order was cancelled!',
  msg_order_summary_title_order_was_accepted: 'Order was accepted!',
  msg_order_summary_subtitle_at_the_bottom:
    '*Payment is under the responsibility of seller and buyer by their agreement.',
  msg_order_summary_start_date: 'Start',
  msg_order_summary_price_ton: 'Indicative price (eur/t)',
  msg_order_summary_price_night: 'Indicative price (eur/day)',
  msg_order_summary_price_meter: 'Indicative price (eur/m)',
  msg_order_summary_price_liter: 'Indicative price (eur/l)',
  msg_order_summary_price_kg: 'Indicative price (eur/kg)',
  msg_order_summary_price_item: 'Price',
  msg_order_summary_price_he: 'Price',
  msg_order_summary_price: 'Price',
  msg_order_summary_phone_number: 'Phone no.',
  msg_order_summary_order_was_finished: 'Order was completed!',
  msg_order_summary_negotiable_price: 'Negotiable price',
  msg_order_summary_location: 'Location',
  msg_order_summary_header_title: 'Order summary',
  msg_order_summary_free_item_price: 'Free item',
  msg_order_summary_final_price: 'Final price',
  msg_order_summary_end_date: 'End',
  msg_order_summary_duration: 'Duration',
  msg_order_summary_amount: 'Amount',
  msg_orders_requested_orders: 'Sent',
  msg_orders_received_orders: 'Received',
  msg_orders_all_orders: 'All',
  msg_order_review_your_rating: 'Review your order',
  msg_order_review_form_placeholder: 'Please leave the comment for other users.',
  msg_order_review_create: 'Review the order!',
  msg_order_payment_type_placeholder: 'Choose payment method',
  msg_order_payment_form_header: 'Credit card information',
  msg_order_in_confirm_services_received: 'Order',
  msg_order_formulate_order: 'Create order',
  msg_order_finish_title: 'Order was finished!',
  msg_order_finish_subtitle: 'Services or goods were received.',
  msg_order_edit_order_success: 'Order was successfully updated!',
  msg_order_edit_description:
    'You can leave a message for seller here. The message will be displayed after accepting the order.',
  msg_order_delivery_price: 'Delivery price',
  msg_order_delivery_option: 'Delivery unavailable',
  msg_order_decline_title: 'Are you sure you want to decline the order?',
  msg_order_decline_subtitle: 'The declined order will appear in your orders list.',
  msg_order_date_in_confirm_services_received: 'Order date',
  msg_order_create_review_success: 'Your review was submitted! Thank you!',
  msg_order_create_order_to: 'Date to',
  msg_order_create_order_success: 'Order was placed successfully!',
  msg_order_create_order_from: 'Date from',
  msg_order_delivery_option_with_price: 'Delivery price {{price}}',
  msg_order_create_order: 'Create order',
  msg_order_create_item_amount: 'Specify the amount (units)',
  msg_order_create_hectare_amount: 'Specify the area (ha)',
  msg_order_confirm_services_received_header: 'Order confirmation',
  msg_order_choose_preliminary_dates: 'Please choose the preliminary dates',
  msg_order_card_payment_success: 'Order was successfully paid!',
  msg_order_card_payment_form_submit: 'Submit',
  msg_order_card_payment_form_expiry_date_field: 'Card expiry date',
  msg_order_card_payment_form_cvc_code_field: 'CVC code',
  msg_order_card_payment_form_card_number_field: 'Card number',
  msg_order_cancel_title: 'Are you sure you want to cancel the order?',
  msg_order_cancel_subtitle: 'Please choose the action below',
  msg_order_advert_title_in_confirm_services_received: 'Ad title',
  msg_order_advert_summary_header_title: 'Ad summary',
  msg_order_advert_summary_description_title: 'Description',
  msg_order_advert_company_in_confirm_services_received: 'Seller',
  msg_order_accept_title: 'Are you sure you want to confirm the order?',
  msg_no_activity_found: 'No results found.',
  msg_navbar_icon_upload_ad: 'Upload an ad',
  msg_order_accept_subtitle: 'Buyer will be informed about the order confirmation.',
  msg_navbar_icon_profile: 'Profile',
  msg_navbar_icon_my_orders: 'My orders',
  msg_navbar_icon_my_ads: 'My ads',
  msg_navbar_icon_forum: 'Forum',
  msg_my_adverts_availability_schedule_page: 'Ads availability schedule',
  msg_my_adverts_availability_page_hero_title: 'Service availability',
  msg_my_adverts_availability_page_hero_subtitle: 'Here you can see service/machinery availability schedule.',
  msg_meter_amount: 'Specify the amount (m)',
  msg_login_form_login: 'Login',
  msg_login_form_forgot_password: 'Forgot password?',
  msg_liter_amount: 'Amount (l)',
  msg_landing_page_competitive_advantage_title_3: 'Place for collaboration',
  msg_landing_page_competitive_advantage_title_2: 'Mobile application',
  msg_landing_page_competitive_advantage_title_1: 'Ads and orders management system',
  msg_landing_page_competitive_advantage_description_1:
    'Reliable, user-friendly and transparent ads and orders management system allowing to communicate and publish you goods and services.',
  msg_landing_page_competitive_advantage_description_2:
    'The mobile application is under development currently. It will allow to easily manage your ads, orders, communicate with service/goods providers, discuss the relevant topics in forum.',
  msg_landing_page_competitive_advantage_description_3:
    'Sejico is a place to share the ideas, advices and discuss about relevant agriculture problems and issues.',
  msg_landing_page_competitive_advantage_section_title: 'Why Sejico?',
  msg_landing_featured_auctions_title: 'Featured auctions',
  msg_landing_featured_adverts_title: 'Featured ads',
  msg_landing_topical_adverts_title: 'Topical ads',
  msg_label_week: 'Week',
  msg_label_vegetables: 'Vegetables',
  msg_label_username: 'User name',
  msg_label_upload_main: 'Upload main photo',
  msg_label_upload_file_description: 'You can upload the main photo here. You can upload 4 additional photos.',
  msg_label_upload_file: 'Upload photo',
  msg_label_uploaded_image: 'The photo was uploaded',
  msg_label_upload: 'Upload photo',
  msg_label_unfinished: 'Unfinished',
  msg_label_telescopic_loader_rent: 'Telescopic loader rent',
  msg_label_tillage: 'Tillage',
  msg_label_to: 'To',
  msg_label_tractors_rent: 'Tractors rent',
  msg_label_trade: 'Trade',
  msg_label_transportation_services: 'Transportation services',
  msg_label_targeted: 'Targeted',
  msg_label_status_unfinished: 'Unfinished',
  msg_label_status_payment_pending: 'Payment pending',
  msg_label_status_finished: 'Finished',
  msg_label_status_expired: 'Expired',
  msg_label_status_ended: 'Ended',
  msg_label_status_declined: 'Declined',
  msg_label_status_created: 'Created',
  msg_label_status_canceled: 'Cancelled',
  msg_order_payment_pending_finish: 'Order confirmed!',
  msg_label_status_active: 'Active',
  msg_label_status_accepted: 'Accepted',
  msg_label_sprayer_rent: 'Sprayer rent',
  msg_label_soil_preparation_for_sowing: 'Soil preparation',
  msg_label_service: 'Service',
  msg_label_sent_orders: 'Sent',
  msg_label_sell_tractors: 'Tractors',
  msg_label_seller: 'Seller',
  msg_label_sell_agregate: 'Agregate',
  msg_label_see_more_auctions: 'Show more auctions',
  msg_label_see_more_adverts: 'Show more ads',
  msg_label_seedlings: 'Seedlings',
  msg_label_seeder_rent: 'Seeder rent',
  msg_label_section_related_adverts_title: 'Related ads',
  msg_label_section_last_previewed_adverts_title: 'Previewed ads',
  msg_label_section_featured_adverts_title: 'Featured ads',
  msg_label_scraper_rent: 'Scraper rent',
  msg_label_schedule_display: 'Show calendar',
  msg_label_sale: 'Sell',
  msg_label_return_back: 'Back',
  msg_label_rent: 'Rent',
  msg_label_register: 'Register',
  msg_label_received_orders: 'Received',
  msg_label_real_estate: 'Real estate',
  msg_label_purchase: 'Buy',
  msg_label_plows_rent: 'Plows rent',
  msg_label_pesticide_spraying: 'Pesticide spraying',
  msg_label_payment_pending: 'Payment pending',
  msg_label_password: 'Password',
  msg_label_other_services: 'Other services',
  msg_label_other_machinery_rent: 'Other machinery rent',
  msg_label_other: 'Other',
  msg_label_order_unfinished: 'Order was unfinished!',
  msg_label_order_type_requested: 'Requested',
  msg_label_order_type_received: 'Received',
  msg_label_order_status_created: 'Created',
  msg_label_order_status_accepted: 'Accepted',
  msg_label_order_needs_payment: 'Payment is pending for order!',
  msg_label_order_item_status: 'Status',
  msg_label_order_item_seller: 'Seller',
  msg_label_order_item_order_price: 'Price',
  msg_label_order_item_order_created_at: 'Order created',
  msg_label_order_item_buyer: 'Buyer',
  msg_label_order_finished: 'Order finished!',
  msg_label_order_expired: 'Order expired!',
  msg_label_order_ended: 'Order ended!',
  msg_label_order_declined: 'Order declined!',
  msg_label_order_created: 'You have received a new order!',
  msg_label_order_canceled: 'Order was cancelled!',
  msg_label_order_active: 'Order is active!',
  msg_label_order_accepted: 'Order is accepted!',
  msg_label_or: 'OR',
  msg_label_no_results: 'No results were found.',
  msg_label_no_payment_cards: 'You do not have any saved cards.',
  msg_label_new_chat_message: 'New message',
  msg_label_natural: 'Natural person',
  msg_label_mushrooms: 'Mushrooms',
  msg_label_month: 'Month',
  msg_label_milling: 'Milling',
  msg_label_milk_products: 'Milk products',
  msg_label_meta_title: 'Sejico.lt',
  msg_label_meta_description: 'Sejico - an innovative platform for all agriculture and farming people.',
  msg_label_meat_products: 'Meat products',
  msg_label_manure_equipment_rent: 'Manure equipment',
  msg_label_mandatory: 'Mandatory',
  msg_label_machinery_repair: 'Machinery repair',
  msg_label_machinery_rent: 'Machinery rent',
  msg_label_login: 'Login',
  msg_label_list_view: 'List',
  msg_label_legal: 'Legal person/company',
  msg_label_last_previewed_adverts_no_adverts: 'You do not have any previewed ads yet.',
  msg_label_job_ads: 'Job ads',
  msg_label_good: 'Good',
  msg_label_grains_products: 'Grains products',
  msg_label_grid_view: 'Grid',
  msg_label_hay_baling: 'Hay baling',
  msg_label_hay_rolls: 'Hay rolls',
  msg_label_herbs: 'Herbs',
  msg_label_implements: 'Implements',
  msg_label_invoice_file: 'Invoice file',
  msg_label_invoice_number: 'Invoice number',
  msg_label_eur_per_kilogram: 'eur/kg',
  msg_label_eur_per_liter: 'eur/l',
  msg_label_eur_per_meter: 'eur/m',
  msg_label_eur_per_night: 'eur/night',
  msg_label_eur_per_ton: 'eur/t',
  msg_label_expired: 'Expired',
  msg_label_farm: 'Farm',
  msg_label_favorite: 'Save',
  msg_label_favorited: 'Saved',
  msg_label_featured_auctions_no_auctions: 'Currently there are no active auctions.',
  msg_label_finished: 'Finished',
  msg_label_fish_products: 'Fish products',
  msg_label_forum_thread_created: 'Created on {{date}}',
  msg_label_from: 'From',
  msg_label_fruits: 'Fruits',
  msg_label_fruit_trees: 'Fruit trees',
  msg_label_functional: 'Functional',
  msg_btn_user_tour_next: 'Next',
  msg_label_eur_per_item: 'eur/item',
  msg_label_eur_per_hour: 'eur/hour',
  msg_label_eur_per_hectare: 'eur/ha',
  msg_label_eur: 'Eur',
  msg_label_ended: 'Ended',
  msg_label_email: 'Email',
  msg_label_edit_advert_title: 'Ad was updated!',
  msg_label_edit_advert_subtitle: 'Changes were saved!',
  msg_label_default_select_placeholder: 'Select',
  msg_label_declined: 'Declined',
  msg_label_day: 'Day',
  msg_label_cultivators_rent: 'Cultivators rent',
  msg_label_cultivation: 'Cultivation',
  msg_label_credit_card_payment_type: 'Credit/Debit card',
  msg_label_credit_card: 'Credit/Debit card',
  msg_label_created: 'Created',
  msg_label_create_company_hectare: 'Area (ha)',
  msg_label_create_company_cattle: 'Cattle',
  msg_label_construction_services: 'Construction services',
  msg_label_company_form_vat_code: 'VAT code',
  msg_label_company_form_phone: 'Phone no.',
  msg_label_company_form_name: 'Title',
  msg_label_company_form_hectares: 'Area (ha)',
  msg_label_company_form_farm_code: 'Farm code',
  msg_label_company_form_farm_activity: 'Main activity',
  msg_label_company_form_company_code: 'Company code',
  msg_label_company_created: 'Company was created!',
  msg_label_combine_rent: 'Combines rent',
  msg_label_canceled: 'Cancelled',
  msg_label_buyer: 'Buyer',
  msg_label_billing_info_form_vat_code: 'VAT code (if applicable)',
  msg_label_billing_info_form_name: 'Title',
  msg_label_billing_info_form_company_code: 'Company/Individual activity code',
  msg_label_billing_info_form_adress: 'Address',
  msg_label_berries: 'Berries',
  msg_label_beekeping_products: 'Beekeeping products',
  msg_label_auction_new_bid_inform_creator: 'There was new bid in an auction!',
  msg_calendar_day: 'Day',
  msg_calendar_event: 'Event',
  msg_label_auction_new_bid_inform_bidders: 'There was new bid in an auction!',
  msg_label_auction_ended_inform_creator: 'Auction has ended!',
  msg_label_auction_ended_inform_bidders: 'Auction has ended!',
  msg_label_auction_declined: 'Auction was declined!',
  msg_label_auction_approved: 'Auction was approved!',
  msg_label_analytics: 'Analytics',
  msg_label_all_orders: 'All',
  msg_label_agro_services: 'Agriculture services',
  msg_label_agro_machinery: 'Machinery',
  msg_label_advert_id_label: 'Advert ID',
  msg_label_ad_status_inactive: 'Inactive ad',
  msg_label_ad_status_active: 'Active ad',
  msg_label_active: 'Active',
  msg_label_accepted: 'Accepted',
  msg_label_agreed_price: 'Agreed price',
  msg_label_credit_card_deletion_info: 'If you delete the card, it will not appear in your payment methods',
  msg_label_add_payment_method: 'ADD PAYMENT METHOD',
  msg_label_add_card: 'Add card',
  msg_kilogram_amount: 'Indicate the amount (kg)',
  msg_item_amount: 'Indicative amount',
  msg_label_advert_tour_ad_step2: 'Here you can choose the preliminary order duration and preferred payment type.',
  msg_label_advert_tour_ad_step3: 'Platfrom will automatically calculate the peliminary price of your order.',
  msg_label_advert_tour_ad_step4:
    'Here you can leave a message for the buyer, which will be displayed after the order will be accepted. Click "Submit" if you would like to create an order.',
  msg_label_advert_tour_ad_step1: 'Here you can see all advert information.',
  msg_invite_user_success: 'User was invited!',
  msg_invite_user_modal_title: 'Please enter the email of the user you would like to invite to Sejico.',
  msg_helper_text_company_form_name_natural:
    'If the natural person needs an invoice, please indicate name and surname.',
  msg_helper_text_company_form_name_company: 'Indicate company for which the invoice should be generated.',
  msg_helper_text_company_form_name: 'Indicate the username, which will be displayed for other users.',
  msg_forum_threads_table_subtopic: 'Thread',
  msg_forum_threads_table_last_comment: 'Last comment',
  msg_forum_threads_table_comments: 'Comments',
  msg_forum_threads_table_comment_count: 'Threads',
  msg_forum_threads_table_author: 'Author',
  msg_forum_threads_page_title: 'Forum thread',
  msg_filters_label_categories: 'Categories',
  msg_filters_label_filters: 'Filters',
  msg_filters_price_range_from_placeholder: 'From',
  msg_filters_price_range_to_placeholder: 'To',
  msg_forgot_password_sent_sub_title: 'Check your email.',
  msg_forgot_password_sent_title: 'The password was sent to your email!',
  msg_forgot_password_sub_title: 'Enter your email and we will send you the password.',
  msg_forgot_password_title: 'Forgot password?',
  msg_forum_replay_to_message: 'Reply',
  msg_forum_thread_no_records_message: 'There are no comments.',
  msg_forum_thread_page_title: 'Forum threads',
  msg_filters_distance: 'Distance',
  msg_filters_field_group_label_add_type: 'Ad type',
  msg_filters_field_group_label_price_type: 'Price type',
  msg_filters_field_group_label_service_type: 'Ad type',
  msg_filters_fields_accepted_events: 'Active',
  msg_filters_fields_adType_label_all: 'All',
  msg_filters_fields_adType_label_sale: 'Sale',
  msg_filters_fields_adType_label_purchase: 'Purchase',
  msg_filters_fields_ad_service_type_label_all: 'All service types',
  msg_filters_fields_ad_service_type_label_good: 'Good',
  msg_filters_fields_ad_service_type_label_service: 'Service',
  msg_filters_fields_adType_label_offers: 'Sell',
  msg_filters_fields_adType_label_search: 'Buy',
  msg_filters_fields_custom_events: 'Custom events',
  msg_filters_fields_pending_events: 'Pending events',
  msg_filters_fields_pricing_label_per_ha: 'eur/ha',
  msg_filters_fields_pricing_label_per_hour: 'eur/hour',
  msg_filters_fields_pricing_label_per_item: 'eur/item',
  msg_filters_fields_pricing_label_per_kilogram: 'eur/kg',
  msg_filters_fields_pricing_label_per_liter: 'eur/l',
  msg_filters_fields_pricing_label_per_meter: 'eur/m',
  msg_filters_fields_pricing_label_per_night: 'eur/day',
  msg_filters_fields_pricing_label_per_ton: 'eur/t',
  msg_btn_create_invoice: 'Create invoice',
  msg_filter_field_group_label_price_range: 'Price',
  msg_field_size: 'Field size (ha)',
  msg_favorite_no_adverts_found: 'No favorite ads were found.',
  msg_favorite_adverts_header_name: 'Title',
  msg_favorite_adverts_header_farm_name: 'Provider',
  msg_favorite_adverts_header_action: 'Action',
  msg_faq_contact_us:
    'You question was not answered? Contact us at <a href="mailto:info@sejico.lt" ><span> info@sejico.lt </span></a>',
  msg_error_wrong_company_input: 'Wrong input!',
  msg_error_company_phone_missing: 'Missing company info: phone number',
  msg_internal_server_error: 'Internal error. Try again',
  msg_error_user_with_this_email_already_exists: 'User with this email already exists!',
  msg_error_username_exists: 'This username already exists!',
  msg_error_time_is_before_start_time: 'End time cannot be before the start time!',
  msg_error_this_password_is_too_common: 'The password is too common, please choose a safer password.',
  msg_error_smaller_number: 'The price should be higher than the previous price.',
  msg_error_selected_day_is_before_today: 'Please choose the day starting from the current date.',
  msg_error_passwords_are_equal: 'Passwords cannot be the same.',
  msg_error_password_format: 'Password must contain at least 1 digit and should be min 5 symbols length.',
  msg_error_text_contains_number: 'Your text should not contain a phone number',
  msg_error_over_max_size: 'Max field size - {{maxSize}} ha',
  msg_error_over_max_price_amount: 'Max price - 21 474 836 Eur',
  msg_error_over_max_length: 'You can enter max {{maxLen}} symbols',
  msg_error_over_max_amount: 'Max amount {{maxAmount}}',
  msg_error_second_number_should_be_grater: 'Second number should be greater',
  msg_error_order_only_for_logged_in_users:
    '<a href="/authentication/login"><h4>Please login,</h4></a><span>to create an order</span>',
  msg_error_order_dates_out_of_schedule: 'Selected dates are out of schedule!',
  msg_error_order_date_diff_too_short: 'Min order time - 1 day.',
  msg_company_users_list_title: 'Users list',
  msg_error_order_date_diff_invalid: 'Min order duration - 3 hours',
  msg_error_only_numbers_allowed: 'Only numbers are allowed in this field!',
  msg_error_not_whole_number: 'You can only enter the decimal numbers.',
  msg_error_min_text_length: 'To search enter at least {{minLen}} symbols.',
  msg_error_min_amount: 'The digit should be greater than 0.',
  msg_error_invited_user_already_exists_in_company: 'The user already exists in your list.',
  msg_error_invalid_username: 'Min 4 symbols for the username should be used.',
  msg_error_invalid_price_format: 'Only numbers can be entered here.',
  msg_error_invalid_phone: 'Wrong phone number.',
  msg_error_invalid_field: 'Please use international format +00..',
  msg_error_invalid_email: 'Invalid email address.',
  msg_error_invalid_date: 'Invalid date.',
  msg_error_invalid_credentials: 'Invalid credentials.',
  msg_error_invalid_amount_value: 'Amount should be greater than 0.',
  msg_error_invalid_amount: 'Invalid amount.',
  msg_error_invalid_advert_categories: 'Please choose the category of good or service.',
  msg_error_incorrect_password: 'Incorrect password.',
  msg_error_image_size_too_large: 'Max size of image - 5 MB',
  msg_error_greater_number: 'The price should be lower than the price before.',
  msg_error_field_required: 'Required field',
  msg_error_error_occurred: 'The error occurred, please refresh the page.',
  msg_error_end_time_is_equal_start_time: 'Start time should be before the end time.',
  msg_error_end_time_is_before_start_time: 'End time should be after the start time.',
  msg_error_dates_is_overlapping: 'Dates are overlapping!',
  msg_error_dates_difference_too_small: 'Min order duration - 3 hours.',
  msg_error_auction_only_for_logged_in_users:
    '<a href="/authentication/login"><h4>Please login,</h4></a><span>to participate in an auction.</span>',
  msg_error_auction_create_only_for_logged_in_users: 'Please login to create an auction.',
  msg_custom_fields_unexpected_error_unknown: 'Wrongly entered information - wrong field format.',
  msg_custom_fields_unexpected_error_unexpected_field: 'Wrong information - this field is not in this category.',
  msg_custom_fields_unexpected_error_should_be_empty_because_no_schema:
    'Wrong information - the additional fields do not apply for this category.',
  msg_custom_fields_unexpected_error_invalid_string: 'Wrong information - only text can be entered here.',
  msg_custom_fields_unexpected_error_invalid_boolean:
    "Wrong information - you can only chose 'yes' or 'no' as an option.",
  msg_custom_fields_error_required_field: 'Required field',
  msg_custom_fields_error_more_than_max: 'The input cannot be less than {{limit}}.',
  msg_custom_fields_error_more_than_exclusive_max: 'The input should be less than {{limit}}.',
  msg_custom_fields_error_min_length: 'Enter at least {{limit}} symbols.',
  msg_custom_fields_error_max_length: 'Max length -  {{limit}} symbols.',
  msg_label_order_status_active: 'Active',
  msg_custom_fields_error_less_than_min: 'Value cannot be less than {{limit}}.',
  msg_custom_fields_error_less_than_exclusive_min: 'Value should be more than {{limit}}.',
  msg_error_custom_fields_invalid_integer: 'You can only enter integers here.',
  msg_custom_fields_error_invalid_floating_point: 'You can only enter digits here.',
  msg_create_thread_title_placeholder: 'Title',
  msg_create_thread_header_title: 'New thread',
  msg_create_thread_header_subtitle:
    'Forum is the place where our members gather to communicate and share experience and advices. Before creating a new thread, please make sure it does not exist yet. This will help our members to find the topic of interest easier.',
  msg_create_thread_content_placeholder: 'Text',
  msg_create_order_ton_amount_placeholder: 'Amount (t)',
  msg_create_order_meter_amount_placeholder: 'Amount (m)',
  msg_create_order_liter_amount_placeholder: 'Indicate the amount (l)',
  msg_create_order_kilogram_amount: 'Amount (kg)',
  msg_create_order_hours_placeholder: "o'clock",
  msg_create_order_date_to_placeholder: 'Date',
  msg_create_order_date_from_placeholder: 'Date',
  msg_create_forum_thread_success_title: 'Thread was created',
  msg_create_forum_thread_success_subtitle: 'Thank you for the thread! Have great discussions!',
  msg_create_forum_thread_success_button: 'Close',
  msg_create_forum_thread_page_title: 'Create forum thread',
  msg_create_company_skip_this_step: 'Skip this step',
  msg_create_company_profile_title: 'Create your company!',
  msg_create_company_profile_subtitle: 'The company information is required to create an order.',
  msg_create_company_phone: 'Phone no.',
  msg_create_company_p_entity_code: "Choose company's activity",
  msg_create_company_name: "Create company's name",
  msg_create_company_hectare: 'Field size (ha)',
  msg_create_company_delete_your_account: 'Willing to delete your account? Contact us.',
  msg_create_company_continue_creation_title: 'Congrats! Registration was successful!',
  msg_create_company_continue_creation_subtitle: 'You can start using Sejico!',
  msg_calendar_event_update_success: 'Event was changed!',
  msg_calendar_all_day: 'Day',
  msg_cookies_text:
    'Our website is using cookies, which ensure efficient performance of the website and its improvements. This is why the mandatory cookies (technical, functional and analytical) are installed automatically. When you click "Accept", you agree with the installation and usage of cookies. You can cancel your agreement at any time by changing the browser settings and deleting the cookies. More information can be found in <a href="/page/privatumo-politika" target="_blank"><span>„Privacy Policy“</span></a>.',
  msg_cookies_settings: 'Cookies setting',
  msg_cookies_modal_text:
    'You can find out more about cookie policy here <a href="/page/privatumo-politika" target="_blank">privatumo politikoje</a>.',
  msg_cookies_modal_header: 'Cookies settings',
  msg_cookies_header: 'Sejico cookies',
  msg_contact_page_mobile_label: 'Call us: <p><a href="tel:tel:+37061355755">tel:+37061355755</a></p>',
  msg_contact_page_heading: 'Contact us',
  msg_contact_page_facebook_label:
    'Follow us on <a href="https://www.facebook.com/Sejico-102719424809555" target="_blank"> Facebook </a>',
  msg_contact_page_email_label: 'General enquiries: <p><a href="mailto:info@sejico.lt">info@sejico.lt</a></p>',
  msg_contact_page_description: 'We care about our customers and we are here to help you!',
  msg_billing_info_updated_title_edit: 'The information was updated!',
  msg_billing_info_updated_subtitle_edit: 'This information will be used for invoice generating.',
  msg_billing_info_updated_title_create: 'Your billing information was saved!',
  msg_billing_info_updated_subtitle_create: 'This information will be used for generating your invoice.',
  msg_company_users_info_tab: 'Farm users',
  msg_company_updated_title: 'Updated!',
  msg_company_updated_subtitle: 'Changes have been saved.',
  msg_company_reviews: 'Reviews',
  msg_company_orders_page: 'My orders',
  msg_company_orders_header_client: 'Buyer',
  msg_company_orders_filter_all: 'All',
  msg_company_no_orders_sent: 'No orders',
  msg_company_no_orders_requested: 'No orders',
  msg_company_no_auctions: 'You have no auctions',
  msg_company_my_company_reviews: 'My farm reviews',
  msg_company_joined_label: 'Registration date',
  msg_company_form_modal_title: 'New farm information',
  msg_company_empty_reviews: 'No reviews',
  msg_company_edit_action: 'Edit',
  msg_company_customer_support_email: 'info@sejico.lt',
  msg_company_calendar_action: 'Calendar',
  msg_company_advert_is_hidden: 'Your listing is hidden!',
  msg_company_advert_invisible_status_title: 'Click to hide your listing',
  msg_company_advert_invisible_status_subtitle:
    'This ad will be only visible to you. You can re-activate it by clicking on this icon',
  msg_company_ads_visibility_title: 'Click to unhide your listing',
  msg_company_ads_visibility_description:
    'The active listing will be visible in the marketplace. You can hide it by clicking on the icon',
  msg_company_ads_header_status: 'Listing status',
  msg_join_company_title: 'Please join the company!',
  msg_company_ads_header_stats: 'Ad previews',
  msg_company_update_advert_top_title: 'Edit ad',
  msg_company_preview_action: 'View ad',
  msg_company_advert_is_visible: 'Your ad is live!',
  msg_company_empty_ads: 'You have no ads',
  msg_company_ads_header_price: 'Ad price',
  msg_company_ads_header_name: 'Ad title',
  msg_company_ads_header_actions: 'Ad action',
  msg_company_ads_filter_placeholder: 'Show',
  msg_company_ads_filter_inactive: 'Inactive',
  msg_company_ads_filter_all: 'All',
  msg_company_ads_filter_active: 'Active',
  msg_company_active_ads: 'Active ads',
  msg_change_username_success: 'Username was changed!',
  msg_change_username_subtitle: 'Your current username',
  msg_change_username_new_username_placeholder: 'Enter new username',
  msg_change_username_heading: 'Change username',
  msg_change_password_success: 'Your password was changed successfully!',
  msg_change_password_subheading: 'Your email',
  msg_change_password_old_password_placeholder: 'Enter old password',
  msg_change_password_new_password_placeholder: 'Enter new password',
  msg_calendar_no_events_in_range: 'There are no events.',
  msg_calendar_previous: 'Back',
  msg_calendar_show_more: 'Show more',
  msg_calendar_time: 'Time',
  msg_calendar_title: 'Ads',
  msg_categories_btn: 'Categories',
  msg_calendar_today: 'Today',
  msg_calendar_tomorrow: 'Tomorrow',
  msg_calendar_week: 'Week',
  msg_calendar_work_week: 'Work days',
  msg_calendar_yesterday: 'Yesterday',
  msg_change_password_heading: 'Password change',
  msg_calendar_event_description: 'Description',
  msg_calendar_event_edit: 'Save',
  msg_calendar_event_end_date: 'End date',
  msg_calendar_event_end_time: 'End time',
  msg_calendar_event_modal_edit_title: 'Edit title',
  msg_calendar_event_modal_title: 'New event',
  msg_calendar_event_start_date: 'Start date',
  msg_calendar_event_start_time: 'Start time',
  msg_calendar_event_title: 'Event title',
  msg_calendar_month: 'Month',
  msg_calendar_event_cancel_changes: 'Cancel',
  msg_calendar_event_create_success: 'Event was created!',
  msg_calendar_event_default_title: 'Event',
  msg_btn_make_company_active: 'Make farm as active',
  msg_btn_modal_close: 'Close',
  msg_btn_not_found: 'Go to marketplace',
  msg_btn_ok: 'OK',
  msg_btn_order_decline: 'Decline',
  msg_btn_order_edit_accept: 'Accept',
  msg_btn_order_edit_cancel: 'Cancel',
  msg_btn_order_finish: 'Finish order',
  msg_btn_order_no: 'Order was not completed',
  msg_btn_preview_add: 'Preview',
  msg_btn_preview_in_map: 'Show in map',
  msg_btn_search: 'Search',
  msg_btn_send: 'Send',
  msg_btn_slider_cta: 'Check the offers',
  msg_btn_submit: 'Submit',
  msg_btn_submit_new_thread: 'Submit',
  msg_btn_thread_reply_success_button: 'Close',
  msg_btn_update_advert_form_submit: 'Save changes',
  msg_btn_update_form: 'Change information',
  msg_btn_verification: 'OK',
  msg_btn_view_company_order: 'View',
  msg_calendar_add_custom_event: 'Create event',
  msg_calendar_agenda: 'Agenda',
  msg_btn_logout: 'Logout',
  msg_btn_login_google: 'Google',
  msg_btn_login_fb: 'Facebook',
  msg_btn_invite_user_modal: 'Invite',
  msg_btn_invite_user: 'Invite user',
  msg_btn_go_back: 'Back',
  msg_btn_filters_modal_search: 'Search',
  msg_btn_filters_modal: 'Filters',
  msg_btn_fetch_more_items: 'Show more',
  msg_btn_edit_order: 'Change order',
  msg_btn_edit_advert_modal: 'OK',
  msg_btn_edit_advert: 'Change ad',
  msg_btn_create_forum_thread: 'Forum thread',
  msg_btn_ask_experts_button: 'Ask an expert',
  msg_experts_page_title: 'Our experts',
  msg_btn_done: 'Close',
  msg_btn_create_company: 'Create farm',
  msg_btn_create_advert_form_submit: 'Create ad',
  msg_btn_company_orders_go_to_marketplace: 'Go to marketplace',
  msg_btn_continue: 'Continue',
  msg_btn_continue_company_creation: 'Start',
  msg_btn_cookies_accept: 'Accept',
  msg_btn_cookies_accept_all: 'Accept all',
  msg_btn_change_order_status_finished: 'Confirm order',
  msg_btn_cookies_modal_btn_accept: 'Save cookies settings',
  msg_btn_create_additional_company: 'Create new farm',
  msg_btn_accept_order: 'Accept order',
  msg_btn_add_card_success: 'Card was added!',
  msg_btn_add_image: 'Add an image',
  msg_btn_add_new_add: 'Add new ad',
  msg_btn_add_schedule: 'Add schedule',
  msg_btn_auction_created_modal: 'Create auction',
  msg_btn_cancel: 'Cancel',
  msg_btn_cancel_action: 'Cancel',
  msg_btn_cancel_order: 'Cancel',
  msg_btn_change: 'Change',
  msg_auctions_filter_value_label: 'Filter',
  msg_auctions_participating_tab: 'Participating',
  msg_auctions_sort_by_expiration_date: 'Least time left',
  msg_auctions_sort_by_expiration_date_desc: 'Most time left',
  msg_auction_starting_price: 'Starting price',
  msg_auction_total_bids: 'Total bids',
  msg_auction_waiting_for_approval_status: 'Pending',
  msg_auction_winner_information_text: 'has won the auction! Final price:',
  msg_billing_info_updated_subtitle: 'The invoice will be generated based on the information provided.',
  msg_billing_info_updated_title: 'Billing information was updated!',
  msg_auction_create_save_auction_btn: 'Create auction',
  msg_auction_create_title_field_placeholder: 'Title',
  msg_auction_create_type_buy: 'Buy',
  msg_auction_create_type_sell: 'Sell',
  msg_auction_declined_status: 'Declined',
  msg_auction_delete_modal_title: 'Are you sure you want to delete an auction?',
  msg_auction_delete_success_modal_title: 'Auction was deleted!',
  msg_auction_description_label: 'Description',
  msg_auction_ended_status: 'Ended',
  msg_auction_expired_status: 'Expired',
  msg_auction_location_label: 'Location',
  msg_auction_no_bidders_placeholder: '-',
  msg_auction_page_hero_title: 'Create an auction',
  msg_auctions_created_tab: 'Created',
  msg_auctions_filter_placeholder: 'Show',
  msg_auction_create_bid_success_modal_title: 'Bid was placed!',
  msg_auction_create_category_select_placeholder: 'Select category',
  msg_auction_create_description_field: 'Please provide a detailed description.',
  msg_auction_create_description_field_placeholder: 'Description',
  msg_auction_create_duration_field_description: 'Indicate auction duration (days)',
  msg_auction_create_duration_field_description_placeholder: 'Indicate auction duration (days)',
  msg_auction_create_image_field: 'Photos',
  msg_auction_create_price_field_description_when_purchase:
    'In order to attract more bidders, please choose a competitive price.',
  msg_auction_create_price_field_placeholder: 'Indicate starting price',
  msg_btn_decline_order: 'Decline order',
  msg_btn_create_payment_card: 'Add',
  msg_auction_bid_price: 'Last bid',
  msg_auction_breadcrumb: 'Auctions',
  msg_auction_create_auction_main_information: 'Main information',
  msg_auction_create_auction_price_and_time: 'Price and duration',
  msg_auction_create_auction_top_title: 'Auction information',
  msg_auction_create_bid_amount_placeholder: 'Indicate price',
  msg_auction_create_bid_place_amount_btn: 'Place bid',
  msg_auction_create_bid_purchase_amount_description: 'To participate in the auction place a lower price.',
  msg_auction_create_bid_sale_amount_description: 'To participate in the auction place a higher price.',
  msg_advert_display_no_my_ads_found: 'No ads',
  msg_advert_event_delete_modal_title: 'Are you sure you want to delete an ad?',
  msg_advert_event_delete_success_modal_title: 'Event was deleted!',
  msg_advert_images_field: 'Photos',
  msg_advert_page_hero_subtitle: 'Upload unlimited amount of ads. To publish your ad, complete the form below.',
  msg_advert_page_hero_title: 'Upload an ad for free!',
  msg_advert_pricing_type_free_item_price: 'Free item',
  msg_advert_pricing_type_negotiable_price: 'Agreed price',
  msg_advert_update_advert_save_advert_btn: 'Change ad',
  msg_auction_auction_created_title: 'Auction created!',
  msg_advert_create_service_type: 'Ad type',
  msg_advert_create_service_type_good: 'Item',
  msg_advert_create_service_type_service: 'Service',
  msg_advert_create_tab_additional_information: 'Additional information',
  msg_advert_create_tab_general_information: 'General information',
  msg_advert_create_title_field: 'Ad title',
  msg_advert_create_title_field_placeholder: 'Indicate what you are willing to sell or buy.',
  msg_advert_delivery_type_no_delivery: 'Delivery price is not included.',
  msg_advert_delivery_type_optional: 'The delivery is optional',
  msg_advert_delivery_type_required: 'Delivery is included',
  msg_advert_display_ad_created_title: 'congrats on ad creation!',
  msg_advert_display_advert_views_count: 'Views',
  msg_advert_display_create_company_first: 'To please the order, create a farm.',
  msg_ad_expired: 'Ad expired',
  msg_advert_create_advert_save_advert_btn: 'Create ad',
  msg_advert_create_advert_top_title: 'Ad form',
  msg_advert_create_advert_type: 'Ad objective',
  msg_advert_create_advert_type_buy: 'Buy',
  msg_advert_create_advert_type_sell: 'Sell',
  msg_advert_create_delivery_price_field: 'Indicate delivery price',
  msg_advert_create_delivery_price_field_description:
    'Indicate preliminary delivery price only if you can provide the delivery.',
  msg_advert_create_delivery_price_field_placeholder: 'Indicate delivery price',
  msg_advert_create_description_field: 'Description',
  msg_advert_create_description_field_description:
    'Describe any relevant information such as delivery information, etc.',
  msg_advert_create_description_field_placeholder: 'Describe an item or service',
  msg_advert_create_other_information: 'Other information',
  msg_advert_create_price_field: 'Indicate price and measurement value',
  msg_advert_create_price_field_placeholder: 'Indicate price',
  msg_advert_create_price_type_selection_placeholder: 'Choose price type',
  msg_advert_create_schedule_enabled_field: 'Create availability schedule',
  msg_add_auction_btn: 'Create auction',
  msg_advert_additional_images_field: 'Additional photos',
  msg_advert_change_delivery_type: 'DELIVERY',
  msg_advert_create_advert_category_select: 'Ad category',
  msg_advert_create_advert_category_select_placeholder: 'Select category from the list',
  msg_advert_create_advert_location_field: 'Choose location',
  msg_advert_create_advert_location_field_placeholder: 'Location',
  msg_advert_create_advert_pricing_type_free_item: 'Free item',
  msg_advert_create_advert_pricing_type_negotiable_price: 'Agreed price',
  msg_calendar_event_delete: 'Delete event',
  msg_error_invalid_min_amount: 'Wrong amount.',
  msg_preview_my_auction_title: 'Preview auction',
  msg_landing_page_categories_header: 'Categories',
  msg_btn_login_apple: 'Sign in with Apple',
  msg_label_bank_transfer_payment_type: 'Bank transfer',
  msg_label_bank_transfer: 'Bank transfer',
  msg_order_transfer_payment_form_header_seller: 'Confirmation of bank transfer',
  msg_order_transfer_received_header: 'Please confirm that you have received the payment via bank transfer.',
  msg_btn_confirm_transfer_received: 'Confirm',
  msg_userpage_transfer_info_tab: 'Bank transfer',
  msg_userpage_transfer_info_form_company_name: 'Company name/Name, surname',
  msg_userpage_transfer_info_form_iban: 'IBAN number',
  msg_userpage_transfer_info_form_bank_name: 'Bank name',
  msg_userpage_transfer_info_button_update: 'Submit',
  msg_bank_info_updated_title_edit: 'Information was saved!',
  msg_bank_info_updated_subtitle_edit: 'You will be able to change this info in the settings.',
  msg_confirm_transfer_received_title: 'I confirm the received payment',
  msg_confirm_transfer_received_subtitle:
    'I confirm that I have received the payment for this order via bank transfer.',
  msg_confirm_transfer_received_btn: 'Confirm',
  msg_confirm_transfer_received_confirmed_title: 'They payment was successfully received and confirmed.',
  msg_error_only_letters_and_numbers_allowed: 'Only letters and numbers are allowed!',
  msg_label_order_payment_done: 'Payment was made!',
  msg_page_invitation_key_not_found: 'The invitation key has expired!',
  msg_btn_invitation_key_not_found: 'OK',
  msg_page_join_company: 'Join the company!',
  msg_page_switch_kind: 'Switch',
  msg_auction_create_new_auction: 'Create new auction',
  msg_label_auction_ended_inform_winner: 'Congrats! you have won the auction!',
  msg_error_invalid_date_diff: 'Min 3 hours difference.',
  msg_order_transfer_payment_form_header_buyer: 'Bank transfer info',
  msg_transfer_info_for_buyer_company_name: 'Company title/Name, surname',
  msg_transfer_info_for_buyer_iban: 'IBAN',
  msg_transfer_info_for_buyer_bank_name: 'Bank name',
  msg_advert_additional_images_description: 'You can upload 4 additional photos (max 5MB)',
  msg_label_agreed_payment_type: 'Agreed price',
  msg_auction_create_price_field_description_when_sell: 'Please indicate the  initial selling price',
  msg_advert_create_schedule_enabled_field_description: 'Add schedule',
  msg_advert_main_image_field: 'Main image',
  msg_lt_lang: 'LT',
  msg_preview_auction_title: 'Preview auction',
  msg_error_dates_out_of_schedule: 'Invalid dates, check the schedule!',
  msg_error_invalid_advert_pricing_type: 'Invalid pricing type',
  msg_open_preliminary_order: 'Create order',
  msg_submit_preliminary_order: 'Submit',
  msg_cancel_preliminary_order: 'Cancel',
  msg_create_preliminary_order: 'Create request',
  msg_order_form_is_disabled: 'Order form is currently disabled',
  msg_preliminary_order_start_date_older_today: 'Cannot choose a date from the past',
  msg_preliminary_order_email_not_valid: 'Invalid email',
  msg_preliminary_form_contact_method_missing: 'Please choose desired contact method',
  msg_preliminary_form_contact_only_one_method: 'Pick only one contact method',
  msg_preliminary_form_phone_not_provided: 'Phone is required',
  msg_preliminary_form_email_not_provided: 'Email is required',
  msg_preliminary_form_contact_platform_no_user: 'User not found',
  msg_preliminary_order_creation: 'Order creation',
  msg_preliminary_order_date: 'Start date',
  msg_preliminary_order_comment: 'Your order',
  msg_preliminary_order_contact_type_title: 'How can we reach you?',
  msg_preliminary_order_contact_via_phone: 'Via phone',
  msg_preliminary_order_contact_via_email: 'Via email',
  msg_preliminary_order_contact_via_platform: 'Via platform',
  msg_preliminary_order_phone: 'Phone',
  msg_preliminary_order_email: 'Email',
  msg_preliminary_order_name: 'Name',
  msg_contact_phone: 'Contact us: {{phone}}',
  msg_preliminary_order_create_order_success: 'Request received',
  msg_preliminary_order_create_order_success_subtext: 'We have received your request and we gonna contact shortly',
  msg_rent_banner_title: 'Rent Valtra tractor',
  msg_page_rent_info_title: 'Rent Valtra tractor',
  msg_no_ads_in_category_found: 'No adverts found',
  msg_ad_contact_us: 'Contact',
  msg_error_wrong_image_format: 'Wrong image format',
  msg_error_file_size_too_large: 'File size too large',
  msg_error_wrong_number: 'Wrong number',
  msg_error_only_whole_numbers_allowed: 'Only whole numbers allowed',
  msg_label_order_status_canceled: 'Canceled',
  msg_label_order_status_payment_pending: 'Payment pending',
  msg_label_order_status_declined: 'Declined',
  msg_label_order_status_expired: 'Expired',
  msg_label_order_status_ended: 'Ended',
  msg_label_order_status_finished: 'Finished',
  msg_label_order_status_unfinished: 'Unfinished',
  msg_order_summary_administration_fee: 'Administration fee',
  msg_order_summary_vat: 'VAT',
  msg_label_something_went_wrong: 'Something went wrong',
  msg_label_order_updated_status: 'Order updated status!',
  msg_edit_auction_page_hero_title: 'msg_edit_auction_page_hero_title',
  msg_edit_auction_page_hero_subtitle: 'msg_edit_auction_page_hero_subtitle',
  msg_edit_auction_success_btn: 'msg_edit_auction_success_btn',
  msg_edit_auction_success_title: 'msg_edit_auction_success_title',
  msg_edit_auction_success_subtitle: 'msg_edit_auction_success_subtitle',
  msg_page_email_verification: 'msg_page_email_verification',
  msg_page_register_success: 'msg_page_register_success',
  msg_edit_auction_title: 'msg_edit_auction_title',
  msg_sejico_agro_header_link: 'Sejico Agro',
  msg_sejico_construction_header_link: 'Sejico Construction',
  msg_landing_page_hero_title_agro: 'New Valtra tractors for rent',
  msg_landing_page_hero_title_constructions: 'Rental of construction equipment',
  msg_landing_page_hero_subtitle_agro: 'We offer you to rent Scandinavian Valtra tractors of various capacities',
  msg_landing_page_hero_subtitle_constructions: 'We offer you various construction equipment for rent',
  msg_landing_page_hero_button: 'Check out the offers',
  msg_landing_search_form_search_input: 'Enter a search word',
  msg_landing_search_form_categories_select_input: 'Select a category from the list',
  msg_landing_clear_filters_button: 'Clean up',
  msg_landing_detailed_search_button: 'Detailed search',
  msg_delete_user_section_heading: 'Delete user account',
  msg_delete_user_section_button: 'Delete',
  msg_delete_user_modal_title: 'Are you sure to delete your user account?',
  msg_delete_user_success_modal_subtitle: 'The account will be deleted in 30 days period',
  msg_other_ads_category_title: 'Other ads',
  msg_category_equipment_rent: 'Equipment rental',
  msg_category_agricultural_machinery_services: 'Agricultural machinery services',
  msg_category_construction_machinery: 'Construction machinery',
  msg_category_construction_machinery_services: 'Construction machinery services',
  msg_landing_popular_cats_title: 'Popular categories',
  msg_button_view_add: 'View',
  msg_featured_tab_daily_promotion: 'Daily promotion',
  msg_featured_tab_most_expensive: 'Most expensive',
  msg_featured_tab_most_recent: 'Most recent',
  msg_featured_tab_most_popular: 'Most popular',
  msg_featured_ads_section_agro_title: 'Agricultural machinery services',
  msg_featured_ads_section_construction_title: 'Construction machinery services',
  msg_loan_form_title: 'Loan Calculator',
  msg_loan_form_loan_sum: 'Select loan amount',
  msg_loan_form_how_to:
    'How to buy a car on installments? You can borrow up to €30,000. Select the desired monthly payment and fill out the application for financing. If the answer is positive - we will transfer the money to your account. There are no restrictions on the age of the car and you can choose it from any dealer, and CASKO insurance is not required. You can return it before the due date without additional fees, for example after selling the car.',
  msg_loan_form_monthly_payment: 'Select monthly payment:',
  msg_loan_form_loan_price: 'Your loan price:',
  msg_loan_form_contact: 'How to contact you?',
  msg_loan_form_phone: 'By phone',
  msg_loan_form_email: 'Email',
  msg_loan_form_enter_email: 'Enter your email address',
  msg_loan_form_enter_phone: 'Enter your phone number',
  msg_loan_form_get_offer_label: 'Get an offer',
  msg_loan_form_example:
    "For example, if the amount of the granted loan is €18,000, when the contract is concluded for 72 months. for the term, variable annual interest rate - 3.5% + 6 months. EURIBOR (4.074%), contract conclusion fee – 3%, monthly administration fee – €9.5, BVKKMN – 10.13%, total payable amount – €23,807.84, monthly payment – €330.66. You may be offered other online loan terms that depend on the bank's assessment of your creditworthiness and risk.",
  msg_mobile_header_favorite_ads: 'Favorite ads',
  msg_mobile_header_activity: 'Notifications',
  msg_advert_loan_form_success_message: 'Request sent successfully. We will contact you shortly.',
  msg_header_link_sejico_finance_title: ' Sejico Finance',
  msg_label_default_date_input_placeholder: 'Choose date',
  msg_error_loan_duration_cannot_be_greater: 'Loan duration cannot be greater than max loan duration',
  msg_error_loan_amount_cannot_be_greater: 'Loan amount cannot be greater than max loan amount',
  msg_newsletter_section_title: 'Newsletter',
  msg_newsletter_section_subtitle:
    'Subscribe to the Sejico newsletter - be the first to receive information and offers!',
  msg_newsletter_section_email_input_placeholder: 'Enter email address',
  msg_subscribe_button: 'Subscribe',
  msg_newsletter_section_error: 'An error has occurred',
  msg_newsletter_section_subscribe_success: 'Successfully subscribed to the newsletter!',
  msg_make: 'Brand / model',
  msg_model: 'Model',
  msg_year_of_manufacture: 'Year of manufacture',
  msg_defects: 'Defects',
  msg_make_date: 'Date of manufacture',
  msg_type: 'Type',
  msg_engine_make: 'Engine',
  msg_gross_weight: 'Gross weight',
  msg_about_page_title: 'About Us',
  msg_about_page_subtitle:
    'Our mission is to bring farmers and businesses together for cooperation to buy/sell services and/or goods.',
  msg_about_page_advantage_title_1: 'Rent a Sejico Technician',
  msg_about_page_advantage_subtitle_1:
    'Long-term and short-term rental of tractors from one week to one year. We currently offer Valtra tractors from 170 HP to 300 HP for rent.',
  msg_about_page_advantage_title_2: 'Get a services from other farmers',
  msg_about_page_advantage_subtitle_2:
    'In case of need to receive services from fellow farmers. With the help of Sejico, you will find fellow farmers who will help you perform the agricultural work you need.',
  msg_about_page_advantage_title_3: 'Provide services to other farmers',
  msg_about_page_advantage_subtitle_3:
    'In my free time from working on my farm, I can earn by doing services to other farmers. Sejico - mediates and provides conditions for farmers to rent their own equipment and perform various operations. works.',
  msg_about_page_benefits_section_title: 'Sejico - where farmers meet!',
  msg_about_page_benefits_section_subtitle:
    'Sejico provides benefits to farmers providing ž.ū. services or equipment rental:',
  msg_about_page_benefits_section_benefit_1: 'Efficient utilization of technique leads to much faster payback.',
  msg_about_page_benefits_section_benefit_2: 'The opportunity to work only with trusted clients is provided.',
  msg_about_page_benefits_section_benefit_3:
    'The possibility of saving without buying new equipment if it is used irrationally.',
  msg_about_page_benefits_section_benefit_4:
    'Short-term equipment rental will allow you to perform work in the event of a breakdown of your own equipment.',
  msg_about_page_benefits_section_benefit_5:
    'Rent or rent the service provides an opportunity to test equipment and the latest technologies before making a new purchase.',
  msg_landing_partners_section_title: 'Partners',
} as const;
