interface PathParam<T extends string> {
  param: T;
}

type PathPart<T extends string = string> = string | PathParam<T>;

type ParamsFromPathArray<T extends PathPart<any>[]> = {
  [K in keyof T]: T[K] extends PathParam<infer ParamName> ? ParamName : never;
};

const param = <T extends string>(name: T): PathParam<T> => {
  return { param: name };
};

function isParam<T extends string>(part: PathPart<T>): part is PathParam<T> {
  return (part as PathParam<T>).param !== undefined;
}

const replaceParams = (template: string, params: {} = {}) => {
  return Object.keys(params).reduce(
    (acc, key) => acc.replace(`:${key}`, String(params[key as keyof typeof params])),
    template,
  );
};

const defineRoute = <P extends PathPart[]>(...parts: P) => {
  const template = `/${parts.map(part => (isParam(part) ? `:${part.param}` : part)).join('/')}`;
  type Params = ParamsFromPathArray<P>[number];
  return {
    template,
    buildLink: (params: Params extends never ? void : Record<Params, string>) =>
      replaceParams(template, params as Record<Params, string> | undefined),
  } as const;
};

export const RouteConfig = {
  Home: defineRoute('home', param('appKind')),
  StaticPage: defineRoute('page', param('slug')),
  NotFound: defineRoute('404'),
  Contact: defineRoute('contact'),
  Faq: defineRoute('faq'),
  RentInfo: defineRoute('rent-info'),
  Activity: defineRoute('activity'),
  InvitationKeyNotFound: defineRoute('invitation-key-not-found'),
  About: defineRoute('about'),

  // Advert
  Advert: defineRoute('advert', param('slug')),
  AdvertAvailability: defineRoute('my-adverts', 'availability', param('id')),
  MyAdverts: defineRoute('my-adverts'),
  AdvertEdit: defineRoute('my-adverts', 'edit', param('id')),
  AdvertNew: defineRoute('my-adverts', 'new'),
  FavoriteAdverts: defineRoute('my-favorite-ads'),
  BulkAdvertsUpload: defineRoute('my-adverts', 'bulk-upload'),

  // Forum
  Forum: defineRoute('forum'),
  ForumThreadList: defineRoute('forum', param('slug'), 'thread'),
  ForumThreadPage: defineRoute('forum', param('forumSlug'), 'thread', param('slug')),
  CreateForumThread: defineRoute('forum', param('slug'), 'new'),
  ExpertsPage: defineRoute('forum', 'experts'),

  // Company
  Company: defineRoute('company', param('slug')),

  // Order
  Order: defineRoute('order', param('id')),
  MyOrders: defineRoute('my-orders'),
  Marketplace: defineRoute('marketplace'),
  Calendar: defineRoute('calendar'),

  // Preliminary Order
  CreatePreliminaryOrder: defineRoute('preliminary-order'),

  // Auction
  MyAuctionPreview: defineRoute('my-auctions', param('slug')),
  AuctionPreview: defineRoute('auctions', param('slug')),
  AuctionEdit: defineRoute('auctions', 'edit', param('slug')),
  AuctionNew: defineRoute('auctions', 'new-auction'),
  Auctions: defineRoute('auctions'),
  MyAuctions: defineRoute('my-auctions'),

  // Authentication
  User: defineRoute('user', param('type')),
  EmailVerification: defineRoute('/verify', param('token')),
  RegisterSuccess: defineRoute('register-success'),
  ResetPassword: defineRoute('reset-password', param('passwordKey')),
  ForgotPassword: defineRoute('forgot-password'),
  Authentication: defineRoute('authentication', param('authType')),
  JoinCompany: defineRoute('invitation-to-company', param('invitationKeyFromUrl')),
  SwitchKind: defineRoute('switch'),

  // Category
  Category: defineRoute('category', param('categorySlug')),
};

export const DEFAULT_HOMEPAGE = RouteConfig.Home.buildLink({ appKind: 'agro' });
