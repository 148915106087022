import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';

import { RouteInfo } from '../appRoutes';

const ContactPage = lazy(() => import('@Routes/contact/ContactPage'));

const routes: RouteInfo[] = [
  {
    path: RouteConfig.Contact.template,
    component: ContactPage,
    title: Messages.contactPage,
    exact: true,
  },
];

export default routes;
