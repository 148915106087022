import classNames from 'classnames';
import React from 'react';

import styles from './Paper.module.scss';

export interface PaperProps {
  depth?: PaperDepth;
  marginBottom?: boolean;
  width?: PaperWidth;
  bordered?: boolean;
  className?: string;
}
export enum PaperWidth {
  s = 'widthS',
  m = 'widthM',
  l = 'widthL',
  full = 'widthFull',
}
export enum PaperDepth {
  one = 'depth1',
}

const Paper: React.FunctionComponent<PaperProps> = props => {
  const { depth, marginBottom, width, children, bordered = false, className } = props;
  return (
    <div
      className={classNames(
        styles.paper,
        { [styles.marginBottom]: marginBottom, [styles.bordered]: bordered },
        width && styles[width],
        depth && styles[depth],
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Paper;
