import React from 'react';

import Paper, { PaperWidth } from '@Components/Paper';

import styles from './FilterBlock.module.scss';

const FilterBlock: React.FC = ({ children }) => {
  return (
    <div className={styles.filterBlock}>
      <Paper width={PaperWidth.full}>
        <div className={styles.content}>
          <div>{children}</div>
        </div>
      </Paper>
    </div>
  );
};

export default FilterBlock;
