import * as React from 'react';

import { useAppKindContext } from '@Components/root';
import { OptionItem } from '@Components/Select';
import {
  CategoryFragment,
  CategoryServiceType,
  GetCategoriesQuery,
  useGetCategoriesQuery,
  CategoryKind,
} from '@Graphql/graphqlTypes.generated';
import { useTranslateObject } from '@Hooks/useTranslateObject';
import { transformToTree } from '@Utils/transformations';
import { TranslationSelector } from '@Utils/types';

import SelectInput, { SelectInputProps } from '../../SelectInput';

interface CategorySelectProps {
  serviceType: string;
  bottomSpacing?: boolean;
  largeDropdown?: boolean;
}

type AdCategorySelectProps = SelectInputProps & CategorySelectProps;

export enum AllCategoriesProps {
  allCategories = 'allCategories',
}

const categorySelectOptions = (
  data: GetCategoriesQuery | undefined,
  serviceType: string,
  selectTranslation: TranslationSelector,
  appKind: CategoryKind,
): OptionItem[] => {
  if (!data) {
    return [];
  }

  let categoriesList = data.categories.filter(cat => cat.kind === appKind);

  if (serviceType !== AllCategoriesProps.allCategories) {
    categoriesList = data.categories.filter(
      cat => cat.serviceType === serviceType || cat.serviceType === CategoryServiceType.Both,
    );
  }

  const categoriesTree = transformToTree<CategoryFragment, CategoryFragment['id']>(categoriesList, 'orderNum');

  return categoriesTree
    .filter(category => !!category)
    .map(category => ({
      value: category.element.id,
      label: selectTranslation(category.element.name),
      options: category.children.map(child => ({
        label: selectTranslation(child.element.name),
        value: child.element.id,
      })),
    }));
};

const AdCategorySelect: React.FunctionComponent<AdCategorySelectProps> = ({ serviceType, largeDropdown, ...props }) => {
  const { appKind } = useAppKindContext();
  const { loading: queryLoading, data: categoriesData } = useGetCategoriesQuery({});
  const selectTranslation = useTranslateObject();
  const options = queryLoading ? [] : categorySelectOptions(categoriesData, serviceType, selectTranslation, appKind);

  return (
    <SelectInput
      {...props}
      options={options}
      loading={queryLoading}
      isClearable={false}
      localizeSelectLabels={false}
      largeDropdown={largeDropdown}
    />
  );
};

export default React.memo(AdCategorySelect);
