import { select } from 'redux-saga/effects';

import { getGoogleTagManagerId } from '@Store/app/app.selectors';

import { isMobileApp } from './mobileWebView';

export enum GAAction {
  click = 'click',
  render = 'render',
  create = 'create',
  register = 'register',
}

export enum GACategory {
  relatedAdvert = 'relatedAdvert',
  registrationForm = 'registrationForm',
  auctionForm = 'auctionForm',
  advertForm = 'advertForm',
  advertFormSecondStep = 'advertFormSecondStep',
  featuredAdvert = 'featuredAdvert',
  featuredAuction = 'featuredAuction',
  slideButton = 'slideButton',
  preliminaryOrder = 'preliminaryOrder',
  appKindModal = 'appKindModal',
  appKindSelect = 'appKindSelect',
}

export const sendGaEvent = (eventInfo: { category: GACategory; action: GAAction; label?: string; value?: string }) => {
  const googleTagManagerId = select(getGoogleTagManagerId);

  if (isMobileApp && !!window?.allowAnalyticsTracking) {
    return;
  }

  if (googleTagManagerId) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'Event',
      eventCategory: eventInfo.category,
      eventAction: eventInfo.action,
      eventLabel: eventInfo.label,
      eventValue: eventInfo.value,
    });
  }
};
