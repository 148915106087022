/* eslint-disable max-lines */

import { ActivitySizeMeasureType, OrderStatus, OrderType } from '@Graphql/graphqlTypes.generated';
import { toLowercase } from '@Utils/util';

export enum Messages {
  TODO = 'placeholder_message_do_not_translate',

  // Header and pages
  websiteTitle = 'msg_title_website_title',
  marketPlace = 'msg_page_marketplace',
  headerLogin = 'msg_page_login',
  myFavourites = 'msg_page_my_favourites',
  myOrders = 'msg_page_my_orders',
  myAds = 'msg_page_my_adverts',
  myAuctions = 'msg_page_my_auctions',
  auctions = 'msg_page_auctions',
  myAccount = 'msg_page_my_account',
  loginPage = 'msg_page_login',
  forumPage = 'msg_page_forum',
  staticPage = 'msg_page_static',
  emailVerificationPage = 'msg_page_email_verification',
  authenticationPage = 'msg_page_authentication',
  newAdPage = 'msg_page_new_advert',
  goToNewAdPage = 'msg_page_go_to_new_ad',
  forgotPasswordPage = 'msg_page_forgot_password',
  joinCompanyPage = 'msg_page_join_company',
  userPage = 'msg_page_user',
  landingPage = 'msg_page_landing',
  introPage = 'msg_page_intro',
  createCompanyPage = 'msg_page_create_company',
  registerPage = 'msg_page_register',
  registerSuccessPage = 'msg_page_register_success',
  companyAdsPage = 'msg_page_company_ads',
  myAuctionsPage = 'msg_page_my_auctions',
  auctionsPage = 'msg_page_auctions',
  companyOrdersPage = 'msg_page_company_orders',
  advertPageTitle = 'msg_page_advert_title',
  activityPage = 'msg_page_activity_title',
  calendarPage = 'msg_page_calendar_title',
  faqPage = 'msg_page_faq_title',
  notFoundPage = 'msg_page_not_found',
  invitationKeyNotFoundPage = 'msg_page_invitation_key_not_found',
  contactPage = 'msg_page_contact',
  aboutPage = 'msg_page_about',
  categoryPage = 'msg_page_category',
  bulkAdvertsUploadPage = 'msg_page_bulk_adverts_upload',

  // Meta
  defaultMetaTitle = 'msg_label_meta_title',
  defaultMetaDescription = 'msg_label_meta_description',

  // Switcher
  switcherTopic = 'msg_switcher_topic',
  switcherKindAgro = 'msg_switcher_kind_agro',
  switcherKindAgroShort = 'msg_switcher_kind_agro_short',
  switcherKindConstructions = 'msg_switcher_kind_constructions',
  switcherKindConstructionsShort = 'msg_switcher_kind_constructions_short',
  switcherKindAgroParagraph = 'msg_switcher_kind_agro_paragraph',
  switcherKindConstructionsParagraph = 'msg_switcher_kind_constructions_paragraph',
  switcherButtonGo = 'msg_switcher_button_go',

  // User page
  userPageCompanyUpdateTab = 'msg_userpage_update_company_tab',
  userPageCompanyReviewsTab = 'msg_userpage_company_reviews_tab',
  userPagePasswordUpdateTab = 'msg_userpage_change_password_tab',
  userPageBillingInfoUpdateTab = 'msg_userpage_update_billing_info_tab',
  userPagePaymentsTab = 'msg_userpage_change_payments_tab',
  userPageInvoiceInfoTab = 'msg_userpage_invoice_info_tab',
  userPageTransferInfoTab = 'msg_userpage_transfer_info_tab',
  userPageCompanyUsersInfoTab = 'msg_company_users_info_tab',
  transferInfoFormCompanyName = 'msg_userpage_transfer_info_form_company_name',
  transferInfoFormIban = 'msg_userpage_transfer_info_form_iban',
  transferInfoFormBankName = 'msg_userpage_transfer_info_form_bank_name',
  updateTransferInfoButton = 'msg_userpage_transfer_info_button_update',
  createTransferInfoButton = 'msg_userpage_transfer_info_button_create',

  // Login and register forms
  register = 'msg_label_register',
  registerTermsOfUsage = 'msg_register_terms_of_usage',
  registerMarketingMaterial = 'msg_register_marketing_material',
  login = 'msg_label_login',
  loginWithGoogle = 'msg_btn_login_google',
  loginWithFacebook = 'msg_btn_login_fb',
  loginWithApple = 'msg_btn_login_apple',
  loginFormForgotPassword = 'msg_login_form_forgot_password',
  loginFormLogin = 'msg_login_form_login',
  registerFormRegister = 'msg_register_form_register',
  logout = 'msg_btn_logout',
  joinCompanyTitle = 'msg_join_company_title',

  // Registration success
  registrationSuccessTitle = 'msg_registration_success_title',
  registrationSuccessSubtitle = 'msg_registration_success_subtitle',
  continueToAccount = 'msg_registration_success_continue',

  // Forgot password
  forgotPasswordTitle = 'msg_forgot_password_title',
  forgotPasswordSubTitle = 'msg_forgot_password_sub_title',
  forgotPasswordSendTitle = 'msg_forgot_password_sent_title',
  forgotPasswordSendSubTitle = 'msg_forgot_password_sent_sub_title',

  // Change password
  changePasswordHeading = 'msg_change_password_heading',
  changePasswordSubheading = 'msg_change_password_subheading',
  changePasswordNewPassword = 'msg_change_password_new_password_placeholder',
  changePasswordOldPassword = 'msg_change_password_old_password_placeholder',
  changePasswordSuccess = 'msg_change_password_success',

  // Change username
  changeUsernameHeading = 'msg_change_username_heading',
  changeUsernameSubtitle = 'msg_change_username_subtitle',
  changeUsernameNewUsername = 'msg_change_username_new_username_placeholder',
  changeUsernameSuccess = 'msg_change_username_success',

  // Email verification
  verificationEmailTitle = 'msg_verification_email_title',
  verificationEmailSubtitle = 'msg_verification_email_subtitle',
  verificationBtn = 'msg_btn_verification',

  // Reset password
  resetPasswordTitle = 'msg_reset_password_title',
  resetPasswordSubtitle = 'msg_reset_password_subtitle',
  resetPasswordSuccessTitle = 'msg_reset_password_success_title',
  resetPasswordSuccessSubtitle = 'msg_reset_password_success_subtitle',

  // Ad creation form
  createAdTopTitle = 'msg_advert_create_advert_top_title',
  createAdType = 'msg_advert_create_advert_type',
  createAdTypeSell = 'msg_advert_create_advert_type_sell',
  createAdTypeBuy = 'msg_advert_create_advert_type_buy',
  createAdServiceType = 'msg_advert_create_service_type',
  createAdServiceTypeGood = 'msg_advert_create_service_type_good',
  createAdServiceTypeService = 'msg_advert_create_service_type_service',
  createAdTitleFieldPlaceholder = 'msg_advert_create_title_field_placeholder',
  createAdCategorySelect = 'msg_advert_create_advert_category_select',
  createAdCategorySelectPlaceholder = 'msg_advert_create_advert_category_select_placeholder',
  createAdLocationFieldPlaceholder = 'msg_advert_create_advert_location_field_placeholder',
  createAdOtherInformation = 'msg_advert_create_other_information',
  createAdPriceFieldPlaceholder = 'msg_advert_create_price_field_placeholder',
  createAdPriceTypeSelectionPlaceholder = 'msg_advert_create_price_type_selection_placeholder',
  createAdScheduleEnabledField = 'msg_advert_create_schedule_enabled_field',
  createAdScheduleEnabledFieldDescription = 'msg_advert_create_schedule_enabled_field_description',
  createAdStatusSelectionPlaceholder = 'msg_advert_create_status_selection_placeholder',
  createAdDeliveryPriceFieldPlaceholder = 'msg_advert_create_delivery_price_field_placeholder',
  createAdStatusSelectionFieldDescription = 'msg_advert_create_status_selection_field_field_description',
  createAdDeliveryPriceFieldDescription = 'msg_advert_create_delivery_price_field_description',
  changeAdDeliveryType = 'msg_advert_change_delivery_type',
  createAdDescriptionField = 'msg_advert_create_description_field',
  createAdDescriptionFieldDescription = 'msg_advert_create_description_field_description',
  createAdDescriptionFieldPlaceholder = 'msg_advert_create_description_field_placeholder',
  createAdImagesField = 'msg_advert_images_field',
  createAdPricingTypeNegotiablePrice = 'msg_advert_create_advert_pricing_type_negotiable_price',
  createAdPricingTypeFreeItem = 'msg_advert_create_advert_pricing_type_free_item',
  createAdLocationFieldExplanation = 'msg_advert_create_advert_location_field_explanation',
  createAdValidUntil = 'msg_advert_create_advert_valid_until',
  adCreatedTitle = 'msg_advert_display_ad_created_title',
  advertPageHeroTitle = 'msg_advert_page_hero_title',
  advertPageHeroSubtext = 'msg_advert_page_hero_subtitle',
  adCreatedModalBtn = 'msg_btn_add_new_add',
  defaultSelectPlaceholder = 'msg_label_default_select_placeholder',
  createAdSaveAdBtn = 'msg_advert_create_advert_save_advert_btn',
  updateAdSaveBtn = 'msg_advert_update_advert_save_advert_btn',
  createAdTabGeneralInformation = 'msg_advert_create_tab_general_information',
  createAdTabAdditionalInformation = 'msg_advert_create_tab_additional_information',
  adPricingTypeNegotiablePrice = 'msg_advert_pricing_type_negotiable_price',
  adPricingTypeFreeItemPrice = 'msg_advert_pricing_type_free_item_price',
  adDeliveryTypeRequired = 'msg_advert_delivery_type_required',
  adDeliveryTypeOptional = 'msg_advert_delivery_type_optional',
  adDeliveryTypeNoDelivery = 'msg_advert_delivery_type_no_delivery',
  adStatusActiveSelection = 'msg_ad_status_active_selection',
  adStatusPrivateSelection = 'msg_ad_status_private_selection',

  // Create company form
  companyFormFarmCode = 'msg_label_company_form_farm_code',
  companyFormCompanyCode = 'msg_label_company_form_company_code',
  companyFormFarmActivity = 'msg_label_company_form_farm_activity',
  companyFormHectare = 'msg_label_company_form_hectares',
  companyFormVatCode = 'msg_label_company_form_vat_code',
  companyFormName = 'msg_label_company_form_name',
  companyFormPhone = 'msg_label_company_form_phone',

  createCompanyDeleteYourAccount = 'msg_create_company_delete_your_account',
  createCompanyCustomerSupportEmail = 'msg_company_customer_support_email',
  createCompanyButton = 'msg_btn_create_company',
  createAdditionalCompanyButton = 'msg_btn_create_additional_company',
  companyCreated = 'msg_label_company_created',
  companyMakeActive = 'msg_btn_make_company_active',
  updateForm = 'msg_btn_update_form',
  companyUpdatedTitle = 'msg_company_updated_title',
  companyUpdatedSubTitle = 'msg_company_updated_subtitle',

  /// Create billing form
  billingInfoFormAdress = 'msg_label_billing_info_form_adress',
  billingInfoCompanyCode = 'msg_label_billing_info_form_company_code',
  billingInfoFormVatCode = 'msg_label_billing_info_form_vat_code',
  billingInfoFormName = 'msg_label_billing_info_form_name',

  billingInfoUpdatedTitleEdit = 'msg_billing_info_updated_title_edit',
  billingInfoUpdatedSubTitleEdit = 'msg_billing_info_updated_subtitle_edit',
  billingInfoUpdatedTitleCreate = 'msg_billing_info_updated_title_create',
  billingInfoUpdatedSubTitleCreate = 'msg_billing_info_updated_subtitle_create',

  bankInfoUpdatedTitleEdit = 'msg_bank_info_updated_title_edit',
  bankInfoUpdatedTitleCreate = 'msg_bank_info_updated_title_create',
  bankInfoUpdatedSubTitleEdit = 'msg_bank_info_updated_subtitle_edit',
  bankInfoUpdatedSubTitleCreate = 'msg_bank_info_updated_subtitle_create',

  /// My company users tab
  companyUsersListTitle = 'msg_company_users_list_title',
  inviteUserButton = 'msg_btn_invite_user',
  inviteUserModalButton = 'msg_btn_invite_user_modal',
  inviteUserModalTitle = 'msg_invite_user_modal_title',
  inviteUserSuccess = 'msg_invite_user_success',
  /// My Invoices
  invoiceFile = 'msg_label_invoice_file',
  invoiceNumber = 'msg_label_invoice_number',

  createInvoiceButton = 'msg_btn_create_invoice',

  // Company
  companyJoinedLabel = 'msg_company_joined_label',
  companyActiveAds = 'msg_company_active_ads',
  companyReviews = 'msg_company_reviews',
  companyEmptyReviews = 'msg_company_empty_reviews',
  companyEmptyAds = 'msg_company_empty_ads',
  companyMyCompanyReviews = 'msg_company_my_company_reviews',
  companyAdsHeaderName = 'msg_company_ads_header_name',
  companyAdsHeaderActions = 'msg_company_ads_header_actions',
  companyAdsHeaderPrice = 'msg_company_ads_header_price',
  companyAdsHeaderStats = 'msg_company_ads_header_stats',
  companyAdsVisibilityTitle = 'msg_company_ads_visibility_title',
  companyAdsVisibilityMessage = 'msg_company_ads_visibility_description',
  companyAdsInvisibleStatusTitle = 'msg_company_advert_invisible_status_title',
  companyAdPrivateStatusTitle = 'msg_company_advert_private_status_title',
  companyAdPrivateStatusSubtitle = 'msg_company_advert_private_status_subtitle',
  companyAdsInvisibleStatusSubtitle = 'msg_company_advert_invisible_status_subtitle',
  companyAdsFilterPlaceholder = 'msg_company_ads_filter_placeholder',
  companyAdsFilterAll = 'msg_company_ads_filter_all',
  companyAdsFilterActive = 'msg_company_ads_filter_active',
  companyAdsFilterInactive = 'msg_company_ads_filter_inactive',
  companyAdIsHidden = 'msg_company_advert_is_hidden',
  companyAdIsSetToPrivate = 'msg_company_advert_is_set_to_private',
  companyAdIsVisible = 'msg_company_advert_is_visible',
  updateAdTopTitle = 'msg_company_update_advert_top_title',
  companyAdsCalendar = 'msg_company_calendar_action',
  companyAdsPreview = 'msg_company_preview_action',
  companyAdsEdit = 'msg_company_edit_action',
  companyFormModalTitle = 'msg_company_form_modal_title',
  companyPhoneLabel = 'msg_company_phone_label',

  // Ad display
  adExpired = 'msg_ad_expired',
  adDisplayCreateCompanyFirst = 'msg_advert_display_create_company_first',
  adDisplayCreateAd = 'msg_advert_display_advert_create',
  adDisplayViewsCount = 'msg_advert_display_advert_views_count',
  adDisplayNoMyAdsFound = 'msg_advert_display_no_my_ads_found',
  myAdsAvailabilityPage = 'msg_my_adverts_availability_schedule_page',
  myAdsAvailabilityPageHeroTitle = 'msg_my_adverts_availability_page_hero_title',
  myAdsAvailabilityPageHeroSubtitle = 'msg_my_adverts_availability_page_hero_subtitle',
  myAdsViewCount = 'msg_my_adverts_display_views_count',
  myAdsBulkUploadBtn = 'msg_my_ads_bulk_upload_button',

  // Ads sorting
  adsSortBy = 'msg_sort_adverts_by',
  adSortByTitle = 'msg_sort_adverts_asc_title',
  adSortByTitleDesc = 'msg_sort_adverts_desc_title',
  adSorByNewest = 'msg_sort_adverts_option_advert_newest',
  adSorBytLowestPrice = 'msg_sort_adverts_option_advert_lowest_price',
  adSorBytHighestPrice = 'msg_sort_adverts_option_advert_highest_price',
  adSorByHighestRating = 'msg_sort_adverts_option_advert_highest_rating',

  // Ad Page
  adContactUs = 'msg_ad_contact_us',

  // Auctions sorting
  auctionSortByExpirationDate = 'msg_auctions_sort_by_expiration_date',
  auctionSortByExpirationDateDesc = 'msg_auctions_sort_by_expiration_date_desc',
  auctionsFilterPlaceholder = 'msg_auctions_filter_placeholder',
  auctionsFilterValueLabel = 'msg_auctions_filter_value_label',

  // Edit, update ad form
  editAdSuccessTitle = 'msg_label_edit_advert_title',
  editAdSuccessSubTitle = 'msg_label_edit_advert_subtitle',
  editAdSuccessButton = 'msg_btn_edit_advert_modal',
  updateAdFormBtn = 'msg_btn_update_advert_form_submit',
  createAdFormBtn = 'msg_btn_create_advert_form_submit',

  // Favorite ads
  favoriteAdsHeaderName = 'msg_favorite_adverts_header_name',
  favoriteAdsHeaderAction = 'msg_favorite_adverts_header_action',
  favoriteAdsHeaderFarmName = 'msg_favorite_adverts_header_farm_name',
  favoriteNoAdsFound = 'msg_favorite_no_adverts_found',

  // No results
  noActivityFound = 'msg_no_activity_found',
  noAdsInCategoryFound = 'msg_no_ads_in_category_found',

  // Validations, errors
  errorFieldRequired = 'msg_error_field_required',
  errorDatesIsOverlapping = 'msg_error_dates_is_overlapping',
  errorPasswordsAreEqual = 'msg_error_passwords_are_equal',
  errorInvalidEmail = 'msg_error_invalid_email',
  errorInvalidUsername = 'msg_error_invalid_username',
  errorInvalidPhone = 'msg_error_invalid_phone',
  errorPasswordFormat = 'msg_error_password_format',
  errorTextContainsNumber = 'msg_error_text_contains_number',
  errorOverMaxLength = 'msg_error_over_max_length',
  errorOverMaxSize = 'msg_error_over_max_size',
  errorOverMaxPriceAmount = 'msg_error_over_max_price_amount',
  errorInvalidIBAN = 'msg_error_invalid_iban',
  errorMinLength = 'msg_error_min_text_length',
  errorMinAmount = 'msg_error_min_amount',
  errorInvalidDate = 'msg_error_invalid_date',
  errorInvalidDateDiff = 'msg_error_invalid_date_diff',
  errorEndTimeIsBeforeStartTime = 'msg_error_end_time_is_before_start_time',
  errorTimeIsBeforeStartTime = 'msg_error_time_is_before_start_time',
  errorEndTimeIsEqualToStartTime = 'msg_error_end_time_is_equal_start_time',
  errorOrderOnlyForLoggedInUsers = 'msg_error_order_only_for_logged_in_users',
  errorOrderDateDiffInvalid = 'msg_error_order_date_diff_invalid',
  errorOrderDateDiffTooShort = 'msg_error_order_date_diff_too_short',
  errorSecondNumberShouldBeGrater = 'msg_error_second_number_should_be_grater',
  errorOnlyNumbersAllowed = 'msg_error_only_numbers_allowed',
  errorOnlyWholeNumbersAllowed = 'msg_error_only_whole_numbers_allowed',
  errorOnlyLettersAndNumbers = 'msg_error_only_letters_and_numbers_allowed',
  errorImageSizeTooLarge = 'msg_error_image_size_too_large',
  errorFileSizeTooLarge = 'msg_error_file_size_too_large',
  errorOrderSelectedDateIsBeforeToday = 'msg_error_selected_day_is_before_today',
  errorStripeCardWithThisIdAlreadyExists = 'msg_error_stripe_card_with_this_id_already_exists',
  errorUserWithThisEmailAlreadyExists = 'msg_error_user_with_this_email_already_exists',
  errorYouDoNotHavePermissionToPerformThisAction = 'msg_error_you_do_not_have_permission_to_perform_this_action',
  errorValuesIsNotAValidUuid = 'msg_error_values_is_not_a_valid_uuid',
  errorThePasswordMustBeAlphabetical = 'msg_error_the_password_must_be_alphabetical',
  errorThisPasswordIsTooCommon = 'msg_error_this_password_is_too_common',
  errorEnterAValidEmailAddress = 'msg_error_enter_a_valid_email_address',
  errorThisPasswordIsEntirelyNumeric = 'msg_error_this_password_is_entirely_numeric',
  errorThisFieldCannotBeBlank = 'msg_error_this_field_cannot_be_blank',
  errorThisPasswordIsTooShortItMustContainAtLeast_5Characters = 'msg_error_this_password_is_too_short_it_must_contain_at_least_5_characters',
  errorOrderRatingWithThisOrderAndReportedCompanyAlreadyExists = 'msg_error_order_rating_with_this_order_and_reported_company_already_exists',
  unexpectedError = 'msg_error_unexpected',
  errorOccurred = 'msg_error_error_occurred',
  errorWrongNumber = 'msg_error_wrong_number',
  errorOverMaxAmount = 'msg_error_over_max_amount',
  errorInvalidMinAmount = 'msg_error_invalid_min_amount',
  errorInvalidPriceFormat = 'msg_error_invalid_price_format',
  errorSmallerNumber = 'msg_error_smaller_number',
  errorGreaterNumber = 'msg_error_greater_number',
  errorInvalidIdSpecifiedCode = 'msg_error_invalid_id_specified_code',
  errorWrongImageFormat = 'msg_error_wrong_image_format',
  errorTooManyImages = 'msg_error_advert_has_too_many_images',
  errorWrongCoordinatesFormat = 'msg_error_wrong_coordinates_format',
  errorWrongDistance = 'msg_error_wrong_distance',
  errorWrongAddressSize = 'msg_error_wrong_address_size',
  errorInvalidAdvertPrice = 'msg_error_invalid_advert_price',
  errorInvalidAdvertPricingType = 'msg_error_invalid_advert_pricing_type',
  errorInvalidAdvertCategory = 'msg_error_invalid_advert_category',
  errorCompanyActivityRequired = 'msg_error_company_activity_required',
  errorWrongCompanyInput = 'msg_error_wrong_company_input',
  errorCannotSendCompanyCodeForNaturalPerson = 'msg_error_cannot_send_company_code_for_natural_person',
  errorInvalidSchedules = 'msg_error_invalid_schedules',
  errorCannotHaveSchedulesForGoods = 'msg_error_cannot_have_schedules_for_goods',
  errorCannotSetDeliveryPriceOnNoDelivery = 'msg_error_cannot_set_delivery_price_on_no_delivery',
  errorInvalidCategory = 'msg_error_invalid_category',
  errorInvalidAmount = 'msg_error_invalid_amount',
  errorInvalidStatus = 'msg_error_invalid_status',
  errorInvalidAmountValue = 'msg_error_invalid_amount_value',
  errorOrderInformationMismatch = 'msg_error_order_information_mismatch',
  errorChatFileExtensionNotAllowed = 'msg_error_chat_file_extension_not_allowed',
  errorChatFileSizeTooLarge = 'msg_error_chat_file_size_too_large',
  errorNoActiveCompany = 'msg_error_no_active_company',
  errorInvalidGlobalId = 'msg_error_invalid_global_id',
  errorAuctionsAreDisabled = 'msg_error_auctions_are_disabled',
  errorPaymentFeatureIsDisabled = 'msg_error_payments_feature_is_disabled',
  errorInvalidOrderDates = 'msg_error_invalid_order_dates',
  errorCanNotPlaceOrder = 'msg_error_cannot_place_order',
  errorInvalidPaymentType = 'msg_error_invalid_payment_type',
  errorOrderDatesOutOfSchedule = 'msg_error_dates_out_of_schedule',
  errorInvalidScheduleDates = 'msg_error_invalid_schedule_dates',
  errorAdvertDoesNotHaveDelivery = 'msg_error_advert_does_not_have_delivery',
  errorAdvertRequiresDeliveryToBeIncluded = 'msg_error_advert_requires_delivery_to_be_included',
  errorCannotCalculateTotalPrice = 'msg_error_cannot_calculate_total_price',
  errorInvalidSlug = 'msg_error_invalid_slug',
  errorOrderHasPayment = 'msg_error_order_has_payment',
  errorInvalidStripePayment = 'msg_error_invalid_stripe_payment',
  errorPaymentMethodDeclined = 'msg_error_payment_method_declined',
  errorCouldNotWithdrawPayment = 'msg_error_could_not_withdraw_payment',
  errorNoSuchPaymentMethod = 'msg_error_no_such_payment_method',
  errorNoPaymentInfoProvided = 'msg_error_no_payment_info_provided',
  errorPaymentIsMissingForInvoiceGeneration = 'msg_error_payment_is_missing_for_invoice_generation',
  errorWhileCreatingPdf = 'msg_error_while_creating_pdf',
  invoiceTemplateMissingInAdmin = 'msg_error_invoice_template_missing_in_admin',
  companyPhoneMissing = 'msg_error_company_phone_missing',
  errorGettingGoogleUserInfo = 'msg_error_getting_google_user_info',
  errorGettingFacebookUserInfo = 'msg_error_getting_facebook_user_info',
  errorFacebookLetShareEmail = 'msg_error_facebook_let_share_email',
  errorGettingAppleUserInfo = 'msg_error_getting_apple_user_info',
  errorAlreadyVerified = 'msg_error_already_verified',
  errorUserAlreadyAddedToCompany = 'msg_error_invited_user_already_exists_in_company',
  errorUsernameExists = 'msg_error_username_exists',
  errorConditionsNotAccepted = 'msg_error_conditions_not_accepted',
  errorInvalidCredentials = 'msg_error_invalid_credentials',
  errorInvalidToken = 'msg_error_invalid_token',
  errorInvalidPassword = 'msg_error_invalid_password',
  errorPasswordKeyExpired = 'msg_error_password_key_expired',
  errorInvalidLanguageCode = 'msg_error_invalid_language_code',
  errorInvalidPermission = 'msg_error_invalid_permission',
  errorInvalidLookupField = 'msg_error_invalid_lookup_field',
  errorRefreshToken = 'msg_error_refresh_token',
  errorInvalidField = 'msg_error_invalid_field',
  errorContentIsTooLong = 'msg_error_content_is_too_long',
  errorInvalidModel = 'msg_error_invalid_model',
  internalServerError = 'msg_internal_server_error',
  errorCustomFieldsUnknown = 'msg_error_custom_fields_unknown',
  errorCustomFieldsRequiredField = 'msg_error_custom_fields_required_field',
  errorCustomFieldsUnexpectedField = 'msg_error_custom_fields_unexpected_field',
  errorCustomFieldsInvalidString = 'msg_error_custom_fields_invalid_string',
  errorCustomFieldsInvalidInteger = 'msg_error_custom_fields_invalid_integer',
  errorCustomFieldsInvalidFloatingPoint = 'msg_error_custom_fields_invalid_floating_point',
  errorCustomFieldsInvalidBoolean = 'msg_error_custom_fields_invalid_boolean',
  errorCustomFieldsMinLength = 'msg_error_custom_fields_min_length',
  errorCustomFieldsMaxLength = 'msg_error_custom_fields_max_length',
  errorCustomFieldsLessThanMin = 'msg_error_custom_fields_less_than_min',
  errorCustomFieldsMoreThanMax = 'msg_error_custom_fields_more_than_max',
  errorCustomFieldsLessThanExclusiveMin = 'msg_error_custom_fields_less_than_exclusive_min',
  errorCustomFieldsMoreThanExclusiveMax = 'msg_error_custom__fields_more_than_exclusive_max',
  errorCustomFieldsShouldBeEmptyBecauseNoSchema = 'msg_error_custom_fields_should_be_empty_because_no_schema',
  errorCsvImportInvalidValue = 'msg_error_csv_import_invalid_value',
  errorCsvImportInvalidFileFormat = 'msg_error_csv_import_invalid_file_format',
  errorCsvImportSpecifyErrorLocation = 'msg_error_csv_import_specify_error_location',

  // Filters
  filtersFilters = 'msg_filters_label_filters',
  filtersCategories = 'msg_filters_label_categories',
  filtersAdType = 'msg_filters_field_group_label_add_type',
  filtersPriceType = 'msg_filters_field_group_label_price_type',
  filtersPriceRange = 'msg_filter_field_group_label_price_range',
  filtersAdTypeAll = 'msg_filters_fields_adType_label_all',
  filtersAdTypeOffers = 'msg_filters_fields_adType_label_offers',
  filtersAdTypeSearch = 'msg_filters_fields_adType_label_search',
  filtersAdTypeSale = 'msg_filters_fields_adType_label_sale',
  filtersAdTypePurchase = 'msg_filters_fields_adType_label_purchase',
  filtersPricingPerHour = 'msg_filters_fields_pricing_label_per_hour',
  filtersPricingPerHa = 'msg_filters_fields_pricing_label_per_ha',
  filterPricingPerItem = 'msg_filters_fields_pricing_label_per_item',
  filtersPricingPerKilogram = 'msg_filters_fields_pricing_label_per_kilogram',
  filtersPriceRangeInputFromPlaceholder = 'msg_filters_price_range_from_placeholder',
  filtersPriceRangeInputToPlaceholder = 'msg_filters_price_range_to_placeholder',
  filterPricingPerLiter = 'msg_filters_fields_pricing_label_per_liter',
  filterPricingPerTon = 'msg_filters_fields_pricing_label_per_ton',
  filterPricingPerMeter = 'msg_filters_fields_pricing_label_per_meter',
  filterPricingPerNight = 'msg_filters_fields_pricing_label_per_night',
  filterAcceptedEvents = 'msg_filters_fields_accepted_events',
  filterPendingEvents = 'msg_filters_fields_pending_events',
  filterCustomEvents = 'msg_filters_fields_custom_events',
  filterDateFrom = 'msg_label_from',
  filterDateTo = 'msg_label_to',

  filtersAdServiceTypeAll = 'msg_filters_fields_ad_service_type_label_all',
  filtersAdServiceTypeGood = 'msg_filters_fields_ad_service_type_label_good',
  filtersAdServiceTypeService = 'msg_filters_fields_ad_service_type_label_service',

  // Order
  createOrder = 'msg_order_create_order',
  createOrderFrom = 'msg_order_create_order_from',
  createOrderTo = 'msg_order_create_order_to',
  fieldSize = 'msg_field_size',
  createOrderHectareAmount = 'msg_order_create_hectare_amount',
  itemAmount = 'msg_item_amount',
  createOrderItemAmount = 'msg_order_create_item_amount',
  kilogramAmount = 'msg_kilogram_amount',
  literAmount = 'msg_liter_amount',
  meterAmount = 'msg_meter_amount',
  tonAmount = 'msg_ton_amount',
  createOrderKilogramAmount = 'msg_create_order_kilogram_amount',
  createOrderHoursPlaceholder = 'msg_create_order_hours_placeholder',
  createOrderDateFromPlaceholder = 'msg_create_order_date_from_placeholder',
  createOrderDateToPlaceholder = 'msg_create_order_date_to_placeholder',
  createOrderMeterAmount = 'msg_create_order_meter_amount_placeholder',
  createOrderLiterAmount = 'msg_create_order_liter_amount_placeholder',
  createOrderTonAmount = 'msg_create_order_ton_amount_placeholder',
  filtersDistance = 'msg_filters_distance',
  orderCreateSuccess = 'msg_order_create_order_success',
  preliminaryOrderCreateSuccess = 'msg_preliminary_order_create_order_success',
  preliminaryOrderCreateSuccessSubtext = 'msg_preliminary_order_create_order_success_subtext',
  orderReviewCreateSuccess = 'msg_order_create_review_success',
  orderEditSuccess = 'msg_order_edit_order_success',
  orderFormulateOrder = 'msg_order_formulate_order',
  orderChoosePreliminaryDates = 'msg_order_choose_preliminary_dates',
  orderReviewCreate = 'msg_order_review_create',
  orderReviewFormPlaceholder = 'msg_order_review_form_placeholder',
  orderReviewYourRating = 'msg_order_review_your_rating',
  orderConfirmServicesReceivedHeader = 'msg_order_confirm_services_received_header',
  orderInConfirmServicesReceived = 'msg_order_in_confirm_services_received',
  orderIdInConfirmServicesReceived = 'msg_order_id_in_confirm_services_received',
  orderAdTitleInConfirmServicesReceived = 'msg_order_advert_title_in_confirm_services_received',
  orderAdCompanyConfirmServicesReceived = 'msg_order_advert_company_in_confirm_services_received',
  orderDateInConfirmServicesReceived = 'msg_order_date_in_confirm_services_received',
  myOrdersPage = 'msg_company_orders_page',
  sentOrdersTab = 'msg_sent_orders_tab',
  receivedOrdersTab = 'msg_received_orders_tab',
  orderPaymentTypePlaceholder = 'msg_order_payment_type_placeholder',
  orderPaymentFormHeader = 'msg_order_payment_form_header',
  orderPaymentGeneralError = 'msg_stripe_order_error_general_error',
  orderCardPaymentFormCardNumberField = 'msg_order_card_payment_form_card_number_field',
  orderCardPaymentFormExpiryDateField = 'msg_order_card_payment_form_expiry_date_field',
  orderCardPaymentFormCvcCodeField = 'msg_order_card_payment_form_cvc_code_field',
  orderCardPaymentFormSubmit = 'msg_order_card_payment_form_submit',
  orderCardPaymentFormSubmitError = 'msg_order_card_payment_form_submit_error',
  orderCardPaymentSuccess = 'msg_order_card_payment_success',
  orderTransferPaymentFormHeaderSeller = 'msg_order_transfer_payment_form_header_seller',
  orderTransferPaymentFormHeaderBuyer = 'msg_order_transfer_payment_form_header_buyer',
  confirmTransferReceivedMessage = 'msg_order_transfer_received_header',
  transferInfoForBuyerCompanyName = 'msg_transfer_info_for_buyer_company_name',
  transferInfoForBuyerIban = 'msg_transfer_info_for_buyer_iban',
  transferInfoForBuyerBankName = 'msg_transfer_info_for_buyer_bank_name',
  confirmTransferReceivedBtn = 'msg_confirm_transfer_received_btn',
  confirmTransferReceivedTitle = 'msg_confirm_transfer_received_title',
  confirmTransferReceivedSubtitle = 'msg_confirm_transfer_received_subtitle',
  confirmTransferReceivedConfirmedTitle = 'msg_confirm_transfer_received_confirmed_title',
  companyNoOrdersSent = 'msg_company_no_orders_sent',
  companyNoOrdersRequested = 'msg_company_no_orders_requested',
  companyOrdersGoToMarketplace = 'msg_btn_company_orders_go_to_marketplace',
  companyFilterAll = 'msg_company_orders_filter_all',
  orderDeliveryPrice = 'msg_order_delivery_price',
  orderSummaryHeaderTitle = 'msg_order_summary_header_title',
  orderAdvertSummaryHeaderTitle = 'msg_order_advert_summary_header_title',
  orderAdvertSummaryDescriptionTitle = 'msg_order_advert_summary_description_title',
  orderDeliveryOption = 'msg_order_delivery_option',
  orderDeliveryOptionWithPrice = 'msg_order_delivery_option_with_price',
  orderSummaryAmount = 'msg_order_summary_amount',
  orderSummaryDuration = 'msg_order_summary_duration',
  orderSummaryStartDate = 'msg_order_summary_start_date',
  orderSummaryEndDate = 'msg_order_summary_end_date',
  orderSummaryLocation = 'msg_order_summary_location',
  orderSummaryPhoneNumber = 'msg_order_summary_phone_number',
  orderSummaryPrice = 'msg_order_summary_price',
  orderSummaryPriceHe = 'msg_order_summary_price_he',
  orderSummaryPriceKg = 'msg_order_summary_price_kg',
  orderSummaryPriceMeter = 'msg_order_summary_price_meter',
  orderSummaryPriceLiter = 'msg_order_summary_price_liter',
  orderSummaryPriceTon = 'msg_order_summary_price_ton',
  orderSummaryPriceNight = 'msg_order_summary_price_night',
  orderSummaryNegotiablePrice = 'msg_order_summary_negotiable_price',
  orderSummaryPriceItem = 'msg_order_summary_price_item',
  orderSummaryVat = 'msg_order_summary_vat',
  orderSummarySubtitleAtTheBottom = 'msg_order_summary_subtitle_at_the_bottom',
  orderSummaryFinalPrice = 'msg_order_summary_final_price',
  orderSummaryAdministrationFee = 'msg_order_summary_administration_fee',
  orderSummaryAcceptOrder = 'msg_btn_accept_order',
  orderSummaryCancelOrder = 'msg_btn_cancel_order',
  orderSummaryDeclineOrder = 'msg_btn_decline_order',
  orderSummaryEditOrder = 'msg_btn_edit_order',
  orderSummaryOrderAccepted = 'msg_order_summary_title_order_was_accepted',
  orderSummaryOrderCancelled = 'msg_order_summary_title_order_was_cancelled',
  orderSummaryOrderDeclined = 'msg_order_summary_title_order_was_declined',
  orderSummaryOrderFinished = 'msg_order_summary_order_was_finished',
  orderAcceptConfirmationTitle = 'msg_order_accept_title',
  orderAcceptConfirmationSubtitle = 'msg_order_accept_subtitle',
  orderCancelConfirmationTitle = 'msg_order_cancel_title',
  orderCancelConfirmationSubtitle = 'msg_order_cancel_subtitle',
  orderDeclineConfirmationTitle = 'msg_order_decline_title',
  orderDeclineConfirmationSubtitle = 'msg_order_decline_subtitle',
  orderDeclineConfirmationBtn = 'msg_btn_order_decline',
  orderFinishConfirmationTitle = 'msg_order_finish_title',
  orderFinishConfirmationSubtitle = 'msg_order_finish_subtitle',
  orderFinishConfirmationBtn = 'msg_btn_order_finish',
  orderPaymentPendingConfirmationTitle = 'msg_order_payment_pending_title',
  orderPaymentPendingConfirmationSubtitle = 'msg_order_payment_pending_subtitle',
  orderPaymentPendingConfirmationFinished = 'msg_order_payment_pending_finish',
  orderPaymentPendingConfirmationBtn = 'msg_btn_order_payment_pending',
  orderEditSubmitChanges = 'msg_btn_order_edit_accept',
  orderEditCancelChanges = 'msg_btn_order_edit_cancel',
  orderEditDescription = 'msg_order_edit_description',
  serviceIsNotReceivedHeader = 'msg_service_is_not_received_header',
  serviceIsNotReceivedSubheader = 'msg_service_is_not_received_subheader',
  serviceIsNotReceivedModalButton = 'msg_service_is_not_received_modal_button',
  btnViewCompanyOrder = 'msg_btn_view_company_order',
  btnPreviewAdd = 'msg_btn_preview_add',
  btnPreviewInMap = 'msg_btn_preview_in_map',
  orderBtnNo = 'msg_btn_order_no',
  orderSummaryFreeItemPrice = 'msg_order_summary_free_item_price',
  ordersAllOrders = 'msg_orders_all_orders',
  ordersReceivedOrders = 'msg_orders_received_orders',
  ordersRequestedOrders = 'msg_orders_requested_orders',
  orderItemOrderDate = 'msg_order_item_order_date',
  orderItemTotalOrderPrice = 'msg_order_total_order_price',
  ordersMapNoResultsFound = 'msg_orders_map_no_results_found',

  // Preliminary Order
  openPreliminaryOrder = 'msg_open_preliminary_order',
  submitPreliminaryOrder = 'msg_submit_preliminary_order',
  cancelPreliminaryOrder = 'msg_cancel_preliminary_order',
  createPreliminaryOrder = 'msg_create_preliminary_order',
  orderFormIsDisabled = 'msg_order_form_is_disabled',
  preliminaryOrderStartDateOlderToday = 'msg_preliminary_order_start_date_older_today',
  preliminaryOrderEmailNotValid = 'msg_preliminary_order_email_not_valid',
  preliminaryFormContactMethodMissing = 'msg_preliminary_form_contact_method_missing',
  preliminaryFormContactOnlyOneMethod = 'msg_preliminary_form_contact_only_one_method',
  preliminaryFormPhoneNotProvided = 'msg_preliminary_form_phone_not_provided',
  preliminaryFormEmailNotProvided = 'msg_preliminary_form_email_not_provided',
  preliminaryFormContactPlatformNoUser = 'msg_preliminary_form_contact_platform_no_user',
  preliminaryOrderCreation = 'msg_preliminary_order_creation',
  preliminaryOrderDate = 'msg_preliminary_order_date',
  preliminaryOrderComment = 'msg_preliminary_order_comment',
  preliminaryOrderContactTypeTitle = 'msg_preliminary_order_contact_type_title',
  preliminaryOrderContactViaPhone = 'msg_preliminary_order_contact_via_phone',
  preliminaryOrderContactViaEmail = 'msg_preliminary_order_contact_via_email',
  preliminaryOrderContactViaPlatform = 'msg_preliminary_order_contact_via_platform',
  preliminaryOrderPhone = 'msg_preliminary_order_phone',
  preliminaryOrderEmail = 'msg_preliminary_order_email',
  preliminaryOrderName = 'msg_preliminary_order_name',

  // Search
  searchResults = 'msg_search_results',
  searchBarPlaceholder = 'msg_search_bar_placeholder',
  searchBarSmallPlaceholder = 'msg_search_bar_small_placeholder',

  // Labels
  labelOr = 'msg_label_or',
  labelNoResults = 'msg_label_no_results',
  labelNoPaymentCards = 'msg_label_no_payment_cards',
  labelSchedulesDisplay = 'msg_label_schedule_display',
  labelUploadFile = 'msg_label_upload_file',
  labelUploadMain = 'msg_label_upload_main',
  labelUploadFileDescription = 'msg_label_upload_file_description',
  labelUpload = 'msg_label_upload',
  labelAdvertId = 'msg_label_advert_id_label',
  labelEmail = 'msg_label_email',
  labelUsername = 'msg_label_username',
  labelPassword = 'msg_label_password',
  labelFavorite = 'msg_label_favorite',
  labelFavorited = 'msg_label_favorited',
  labelUploadedImage = 'msg_label_uploaded_image',
  labelEur = 'msg_label_eur',
  labelAgo = 'msg_label_ago',
  labelGridView = 'msg_label_grid_view',
  labelListView = 'msg_label_list_view',
  labelAgreedPaymentType = 'msg_label_agreed_payment_type',
  labelCreditCardPaymentType = 'msg_label_credit_card_payment_type',
  labelBankTransferPaymentType = 'msg_label_bank_transfer_payment_type',
  labelSeeMoreAuctions = 'msg_label_see_more_auctions',
  labelSeeMoreAdverts = 'msg_label_see_more_adverts',
  labelFeaturedAuctionsNoAuctions = 'msg_label_featured_auctions_no_auctions',
  labelSomethingWentWrong = 'msg_label_something_went_wrong',
  labelCreditCardDeletionInfo = 'msg_label_credit_card_deletion_info',
  labelAddPaymentMethod = 'msg_label_add_payment_method',
  labelAddCard = 'msg_label_add_card',

  // Btn
  btnSend = 'msg_btn_send',
  btnSubmit = 'msg_btn_submit',
  btnChange = 'msg_btn_change',
  btnDone = 'msg_btn_done',
  btnOk = 'msg_btn_ok',
  btnFetchMore = 'msg_btn_fetch_more_items',
  btnCancelAction = 'msg_btn_cancel_action',
  btnSearch = 'msg_btn_search',
  btnChangeOrderStatusToFinished = 'msg_btn_change_order_status_finished',
  btnEditAdvert = 'msg_btn_edit_advert',
  btnAddSchedule = 'msg_btn_add_schedule',
  btnAddImage = 'msg_btn_add_image',
  btnFiltersModal = 'msg_btn_filters_modal',
  btnFiltersModalSearch = 'msg_btn_filters_modal_search',
  btnModalClose = 'msg_btn_modal_close',
  btnReturnBack = 'msg_label_return_back',
  btnCancel = 'msg_btn_cancel',
  btnContinue = 'msg_btn_continue',
  btnGoBack = 'msg_btn_go_back',
  btnSliderCta = 'msg_btn_slider_cta',
  btnCreatePaymentCard = 'msg_btn_create_payment_card',
  btnAddCardSuccess = 'msg_btn_add_card_success',
  btnConfirmTransferReceived = 'msg_btn_confirm_transfer_received',
  btnDeleteCreditCard = 'msg_btn_delete_credit_card',
  btnClearFilters = 'btn_label_clear_filters',
  btnShowLocationBar = 'msg_btn_show_location_bar',
  btnHideLocationBar = 'msg_btn_hide_location_bar',
  btnSubmitFilters = 'msg_btn_submit_filters',
  btnCsvUpload = 'msg_btn_csv_upload',

  // Cookies
  cookiesBtnAccept = 'msg_btn_cookies_accept',
  cookiesBtnAcceptAll = 'msg_btn_cookies_accept_all',
  cookiesHeader = 'msg_cookies_header',
  cookiesText = 'msg_cookies_text',
  cookiesSettings = 'msg_cookies_settings',
  cookiesModalHeader = 'msg_cookies_modal_header',
  cookiesModalText = 'msg_cookies_modal_text',
  cookiesModalButtonAccept = 'msg_btn_cookies_modal_btn_accept',

  // Forum
  forumThreadsPageTitle = 'msg_forum_threads_page_title',
  forumThreadPageTitle = 'msg_forum_thread_page_title',
  forumThreadsTableSubtopic = 'msg_forum_threads_table_subtopic',
  forumThreadsTableComments = 'msg_forum_threads_table_comments',
  forumThreadsTableCommentCount = 'msg_forum_threads_table_comment_count',
  forumThreadsTableLastComment = 'msg_forum_threads_table_last_comment',
  forumThreadsTableAuthor = 'msg_forum_threads_table_author',
  forumThreadNoRecordsMessage = 'msg_forum_thread_no_records_message',
  forumReplayToMessage = 'msg_forum_replay_to_message',
  forumThreadCreated = 'msg_label_forum_thread_created',
  threadCreatedAtDate = 'msg_thread_created_at_date',
  threadPostedByAuthor = 'msg_thread_posted_by_author',
  threadLoggedOutCreateForm = 'msg_thread_logged_out_create_form',
  threadReplySuccessTitle = 'msg_thread_reply_success_title',
  threadReplyAuthorInfo = 'msg_thread_reply_author_info',
  threadReplyFormLoggedOut = 'msg_thread_reply_logged_out_form',
  addThreadReply = 'msg_thread_add_thread_reply',
  yourThreadReply = 'msg_thread_your_thread_reply',
  createForumThreadPageTitle = 'msg_create_forum_thread_page_title',
  createThreadHeaderTitle = 'msg_create_thread_header_title',
  createThreadSubTitle = 'msg_create_thread_header_subtitle',
  createThreadTitlePlaceholder = 'msg_create_thread_title_placeholder',
  createThreadContentPlaceholder = 'msg_create_thread_content_placeholder',
  createForumThreadSuccessButton = 'msg_create_forum_thread_success_button',
  createForumThreadSuccessTitle = 'msg_create_forum_thread_success_title',
  createForumThreadSuccessSubtitle = 'msg_create_forum_thread_success_subtitle',
  btnSubmitNewThread = 'msg_btn_submit_new_thread',
  btnCreateForumThread = 'msg_btn_create_forum_thread',
  btnThreadReplySuccess = 'msg_btn_thread_reply_success_button',
  btnAskExperts = 'msg_btn_ask_experts_button',
  expertsPageTitle = 'msg_experts_page_title',

  // Calendar
  calendarDate = 'msg_calendar_date',
  calendarTime = 'msg_calendar_time',
  calendarEvent = 'msg_calendar_event',
  calendarAllDay = 'msg_calendar_all_day',
  calendarWeek = 'msg_calendar_week',
  calendarWorkWeek = 'msg_calendar_work_week',
  calendarDay = 'msg_calendar_day',
  calendarMonth = 'msg_calendar_month',
  calendarPrevious = 'msg_calendar_previous',
  calendarNext = 'msg_calendar_next',
  calendarYesterday = 'msg_calendar_yesterday',
  calendarTomorrow = 'msg_calendar_tomorrow',
  calendarToday = 'msg_calendar_today',
  calendarAgenda = 'msg_calendar_agenda',
  calendarShowMore = 'msg_calendar_show_more',
  calendarNoEventsInRange = 'msg_calendar_no_events_in_range',
  calendarAddCustomEvent = 'msg_calendar_add_custom_event',
  calendarFormEventTitle = 'msg_calendar_event_title',
  calendarFormEventDescription = 'msg_calendar_event_description',
  calendarFormEventStartDate = 'msg_calendar_event_start_date',
  calendarFormEventEndDate = 'msg_calendar_event_end_date',
  calendarFormEventStartTime = 'msg_calendar_event_start_time',
  calendarFormEventEndTime = 'msg_calendar_event_end_time',
  calendarEventCreateSuccess = 'msg_calendar_event_create_success',
  calendarEventUpdateSuccess = 'msg_calendar_event_update_success',
  calendarEventCancelChanges = 'msg_calendar_event_cancel_changes',
  calendarEventDelete = 'msg_calendar_event_delete',
  calendarEventDefaultTitle = 'msg_calendar_event_default_title',
  calendarEventModalTitle = 'msg_calendar_event_modal_title',
  calendarEventModalEditTitle = 'msg_calendar_event_modal_edit_title',
  calendarEventEdit = 'msg_calendar_event_edit',

  // FQA
  faqContactUs = 'msg_faq_contact_us',
  // Helper text
  helperTextCompanyFormName = 'msg_helper_text_company_form_name',
  helperTextBillingInfoFormNameNatural = 'msg_helper_text_company_form_name_natural',
  helperTextBillingInfoFormNameCompany = 'msg_helper_text_company_form_name_company',
  // Not found pages
  notFoundButton = 'msg_btn_not_found',
  invitationKeyNotFoundButton = 'msg_btn_invitation_key_not_found',

  // Footer
  footerText = 'msg_label_footer_text',

  // Sections
  relatedAdvertsSectionTitle = 'msg_label_section_related_adverts_title',
  featuredAdvertsSectionTitle = 'msg_label_section_featured_adverts_title',
  lastPreviewedAdvertsSectionTitle = 'msg_label_section_last_previewed_adverts_title',
  labelLastPreviewedAdvertsNoAdverts = 'msg_label_last_previewed_adverts_no_adverts',

  // Auctions
  auctionAddAuctionBtn = 'msg_add_auction_btn',
  auctionCreateNewAuction = 'msg_auction_create_new_auction',
  auctionPageHeroTitle = 'msg_auction_page_hero_title',
  auctionCreatedTitle = 'msg_auction_auction_created_title',
  auctionCreatedModalBtn = 'msg_btn_auction_created_modal',
  auctionCreateAuctionTopTitle = 'msg_auction_create_auction_top_title',
  auctionCreateAuctionMainInformation = 'msg_auction_create_auction_main_information',
  auctionCreatePriceAndTime = 'msg_auction_create_auction_price_and_time',
  auctionCreatePriceFieldDescriptionWhenPurchase = 'msg_auction_create_price_field_description_when_purchase',
  auctionCreatePriceFieldDescriptionWhenSell = 'msg_auction_create_price_field_description_when_sell',
  auctionCreateTypeBuy = 'msg_auction_create_type_buy',
  auctionCreateTypeSell = 'msg_auction_create_type_sell',
  auctionCreateCategorySelectPlaceholder = 'msg_auction_create_category_select_placeholder',
  auctionCreateTitleFieldPlaceholder = 'msg_auction_create_title_field_placeholder',
  auctionCreatePriceFieldPlaceholder = 'msg_auction_create_price_field_placeholder',
  auctionCreateDescriptionFieldPlaceholder = 'msg_auction_create_description_field_placeholder',
  auctionCreateDescriptionField = 'msg_auction_create_description_field',
  auctionCreateSaveAuctionBtn = 'msg_auction_create_save_auction_btn',
  auctionCreateDurationFieldDescription = 'msg_auction_create_duration_field_description',
  auctionCreateDurationFieldDescriptionPlaceholder = 'msg_auction_create_duration_field_description_placeholder',
  editAuctionSuccessButton = 'msg_edit_auction_success_btn',
  editAuctionSuccessTitle = 'msg_edit_auction_success_title',
  editAuctionSuccessSubTitle = 'msg_edit_auction_success_subtitle',
  editAuctionTitle = 'msg_edit_auction_title',
  editAuctionPageHeroTitle = 'msg_edit_auction_page_hero_title',
  editAuctionPageHeroSubtitle = 'msg_edit_auction_page_hero_subtitle',
  previewAuctionTitle = 'msg_preview_auction_title',
  previewMyAuctionTitle = 'msg_preview_my_auction_title',
  auctionBreadcrumb = 'msg_auction_breadcrumb',
  auctionExpiredStatus = 'msg_auction_expired_status',
  auctionWaitingForApprovalStatus = 'msg_auction_waiting_for_approval_status',
  auctionDeclinedStatus = 'msg_auction_declined_status',
  auctionEndedStatus = 'msg_auction_ended_status',
  auctionActiveStatusWithExpiration = 'msg_auction_active_status_with_expiration',
  auctionActiveStatusNoExpiration = 'msg_auction_active_status_no_expiration',
  auctionBidPrice = 'msg_auction_bid_price',
  auctionStartingPrice = 'msg_auction_starting_price',
  auctionBidders = 'msg_auction_bidders',
  auctionNoBiddersPlaceholder = 'msg_auction_no_bidders_placeholder',
  goToNewAuctionPage = 'msg_page_go_to_new_auction',
  companyNoAuctions = 'msg_company_no_auctions',
  auctionTotalBids = 'msg_auction_total_bids',
  auctionCreateBidPurchaseAmountDescription = 'msg_auction_create_bid_purchase_amount_description',
  auctionCreateBidSaleAmountDescription = 'msg_auction_create_bid_sale_amount_description',
  auctionCreateBidAmountPlaceholder = 'msg_auction_create_bid_amount_placeholder',
  auctionCreateBidPlaceAmountBtn = 'msg_auction_create_bid_place_amount_btn',
  auctionCreateBidSuccessModalTitle = 'msg_auction_create_bid_success_modal_title',
  auctionDescriptionLabel = 'msg_auction_description_label',
  auctionLocationLabel = 'msg_auction_location_label',
  auctionCreateImageField = 'msg_auction_create_image_field',
  auctionWinnerInformationText = 'msg_auction_winner_information_text',
  auctionDeleteSuccessModalTitle = 'msg_auction_delete_success_modal_title',
  auctionDeleteModalTitle = 'msg_auction_delete_modal_title',
  auctionErrorAuctionOnlyForLoggedInUsers = 'msg_error_auction_only_for_logged_in_users',
  auctionsCreatedTab = 'msg_auctions_created_tab',
  auctionsParticipatingTab = 'msg_auctions_participating_tab',
  auctionErrorCreateAuctionOnlyForLoggedInUsers = 'msg_error_auction_create_only_for_logged_in_users',

  // Ad Event
  advertEventDeleteModalTitle = 'msg_advert_event_delete_modal_title',
  advertEventDeleteSuccessModalTitle = 'msg_advert_event_delete_success_modal_title',

  // Order
  orderItemSeller = 'msg_label_order_item_seller',
  orderItemBuyer = 'msg_label_order_item_buyer',
  orderItemStatus = 'msg_label_order_item_status',
  orderItemCreatedAt = 'msg_label_order_item_order_created_at',
  orderItemPrice = 'msg_label_order_item_order_price',

  // Landing page
  landingTopicalAdvertsTitle = 'msg_landing_topical_adverts_title',
  landingFeaturedAdvertsTitle = 'msg_landing_featured_adverts_title',
  landingFeaturedAuctionsTitle = 'msg_landing_featured_auctions_title',
  competitiveAdvantageTitle1 = 'msg_landing_page_competitive_advantage_title_1',
  competitiveAdvantageTitle2 = 'msg_landing_page_competitive_advantage_title_2',
  competitiveAdvantageTitle3 = 'msg_landing_page_competitive_advantage_title_3',
  competitiveAdvantageDescription1 = 'msg_landing_page_competitive_advantage_description_1',
  competitiveAdvantageDescription2 = 'msg_landing_page_competitive_advantage_description_2',
  competitiveAdvantageDescription3 = 'msg_landing_page_competitive_advantage_description_3',
  competitiveAdvantageTitle = 'msg_landing_page_competitive_advantage_section_title',
  landingPageCategoriesHeader = 'msg_landing_page_categories_header',

  // Contact page
  contactPageFacebookLabel = 'msg_contact_page_facebook_label',
  contactPageEmailLabel = 'msg_contact_page_email_label',
  contactPageMobileLabel = 'msg_contact_page_mobile_label',
  contactPageHeading = 'msg_contact_page_heading',
  contactPageDescription = 'msg_contact_page_description',

  // Calendar
  calendarTitle = 'msg_calendar_title',

  // Category Page
  btnCategories = 'msg_categories_btn',

  // Payments
  paymentCardDeleteModalTitle = 'msg_payment_card_delete_modal_title',
  paymentCardDeleteSuccessModalTitle = 'msg_payment_card_delete_success_modal_title',
  paymentCardNumberDisplayFormat = 'msg_payment_card_number_display_format',
  paymentNoCardsYet = 'msg_payment_no_cards_yet',
  paymentPayWithSelectedCard = 'msg_payment_pay_with_selected_card',

  // User Tour
  userTourNextBtn = 'msg_btn_user_tour_next',

  // Bulk upload
  bulkUploadPageHeroTitle = 'msg_bulk_upload_page_hero_title',
  bulkUploadPageHeroSubtext = 'msg_bulk_upload_page_hero_subtext',
  bulkUploadFormTitle = 'msg_bulk_upload_form_title',
  bulkUploadFormAdPrivacyField = 'msg_bulk_upload_form_ad_privacy_field',
  bulkUploadFormAdPrivacyPrivateType = 'msg_bulk_upload_form_ad_privacy_private_type',
  bulkUploadFormAdPrivacyPublicType = 'msg_bulk_upload_form_ad_privacy_public_type',
  bulkUploadFormUploadFileField = 'msg_bulk_upload_form_upload_file_field',
  bulkUploadSubmitButton = 'msg_button_submit_bulk_upload',
  bulkUploadCompletedTitle = 'msg_bulk_upload_completed_title',
  bulkUploadCompletedSubTitle = 'msg_bulk_upload_completed_subtitle',

  contactPhone = 'msg_contact_phone',

  rentInfoPage = 'msg_page_rent_info_title',

  rentInfoBannerTitle = 'msg_rent_banner_title',
  loanAmountError = 'msg_error_loan_amount_cannot_be_greater',
  loanDurationError = 'msg_error_loan_amount_cannot_be_greater',
}

export const buildMessageKeyForEnum = <Key extends string>(key: Key) => `msg_label_${toLowercase(key)}` as const;

export const buildOrderTypeMessage = (type: OrderType) => buildMessageKeyForEnum(`order_type_${toLowercase(type)}`);
export const buildOrderStatusMessage = (type: OrderStatus) =>
  buildMessageKeyForEnum(`order_status_${toLowercase(type)}`);
export const buildMeasurementTypes = (type: ActivitySizeMeasureType) =>
  buildMessageKeyForEnum(`create_company_${toLowercase(type)}`);

export const buildMessageKeyForStripeErrorEnum = (key: string) => `msg_stripe_order_error_${key.toLowerCase()}`;
