import { lazy } from 'react';

import { FeatureToggles } from '@Config/constants';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import withFeatureRouteEnabled from '@Hoc/withFeatureRouteEnabled';
import { authenticatedOnlyComponent } from '@Utils/router';

import { RouteInfo } from '../appRoutes';

const AuctionPreviewPage = lazy(() => import('./AuctionPreviewPage'));
const EditMyAuctionPage = lazy(() => import('./EditMyAuctionPage'));
const MyAuctionPreviewPage = lazy(() => import('./MyAuctionPreviewPage'));
const MyAuctionsPage = lazy(() => import('./MyAuctionsPage'));
const CreateNewAuctionPage = lazy(() => import('./CreateNewAuctionPage'));
const AuctionsPage = lazy(() => import('./AuctionsPage'));

const auctionsRoutes: RouteInfo[] = [
  {
    path: RouteConfig.Auctions.template,
    component: withFeatureRouteEnabled(AuctionsPage, FeatureToggles.auction),
    title: Messages.auctionsPage,
    exact: true,
  },
  {
    path: RouteConfig.MyAuctions.template,
    component: authenticatedOnlyComponent(withFeatureRouteEnabled(MyAuctionsPage, FeatureToggles.auction)),
    title: Messages.myAuctionsPage,
    exact: true,
  },
  {
    path: RouteConfig.AuctionNew.template,
    component: authenticatedOnlyComponent(withFeatureRouteEnabled(CreateNewAuctionPage, FeatureToggles.auction)),
    title: Messages.auctionCreateNewAuction,
    exact: true,
  },
  {
    path: RouteConfig.AuctionEdit.template,
    component: authenticatedOnlyComponent(withFeatureRouteEnabled(EditMyAuctionPage, FeatureToggles.auction)),
    title: Messages.editAuctionTitle,
    exact: false,
  },
  {
    path: RouteConfig.AuctionPreview.template,
    component: withFeatureRouteEnabled(AuctionPreviewPage, FeatureToggles.auction),
    title: Messages.previewAuctionTitle,
    exact: true,
  },
  {
    path: RouteConfig.MyAuctionPreview.template,
    component: authenticatedOnlyComponent(withFeatureRouteEnabled(MyAuctionPreviewPage, FeatureToggles.auction)),
    title: Messages.previewMyAuctionTitle,
    exact: true,
  },
];

export default auctionsRoutes;
