import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';

import { RouteInfo } from '../appRoutes';

const CalendarPage = lazy(() => import('@Routes/calendar/CalendarPage'));

const calendarRoutes: RouteInfo[] = [
  {
    path: RouteConfig.Calendar.template,
    component: CalendarPage,
    title: Messages.calendarPage,
    exact: true,
  },
];

export default calendarRoutes;
