import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import InputContainer from '@Components/forms/inputs/InputContainer';
import Tooltip, { TooltipPosition } from '@Components/Tooltip';
import Typography from '@Components/Typography';
import { useTranslations } from '@Hooks/useTranslations';
import { hasFieldError } from '@Utils/form';
import { getTranslationValue, MessageKeysWithoutParams } from '@Utils/translation';

import styles from './TextAreaInput.module.scss';

export interface TextAreaInputProps<T> extends FormFieldRenderProps<T> {
  placeholder: MessageKeysWithoutParams | string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  className?: string;
  inline?: boolean;
  tooltipMessage?: string;
  tooltipPosition?: TooltipPosition;
  fullWidth?: boolean;
  rows?: number;
  bottomSpacing?: boolean;
  smallerPlaceholder?: boolean;
  description?: MessageKeysWithoutParams;
  tooltipTitle?: string;
}
function textAreaInput<T extends InputHTMLAttributes<'input'>['value']>(
  props: TextAreaInputProps<T>,
): React.ReactElement {
  const {
    input,
    label,
    meta,
    required,
    className,
    disabled,
    placeholder,
    inline,
    smallerPlaceholder,
    description,
    tooltipTitle,
    tooltipMessage,
    bottomSpacing,
    tooltipPosition,
    rows = 5,
    fullWidth,
  } = props;

  const t = useTranslations();
  const translatedPlaceholder = getTranslationValue({ value: placeholder, t });
  const hasError = hasFieldError(meta);

  return (
    <InputContainer
      label={label}
      meta={meta}
      required={required}
      className={classNames(styles.wrapper, className)}
      inline={inline}
      fullWidth={fullWidth}
      showErrorIcon
      bottomSpacing={bottomSpacing}
    >
      {description && (
        <Typography tag="div" msg={t(description)} size="s" color="gray" className={styles.description} />
      )}
      <Tooltip title={tooltipTitle} message={tooltipMessage} position={tooltipPosition}>
        <textarea
          {...input}
          name={input.name}
          placeholder={translatedPlaceholder}
          aria-label={translatedPlaceholder}
          disabled={disabled}
          rows={rows}
          autoComplete="off"
          className={classNames(styles.input, {
            [styles.error]: hasError,
            [styles.smallerPlaceholder]: smallerPlaceholder,
          })}
        />
        {translatedPlaceholder && (
          <label htmlFor={input.name} data-content={translatedPlaceholder}>
            <span className={styles.hidden}>{translatedPlaceholder}</span>
          </label>
        )}
      </Tooltip>
    </InputContainer>
  );
}

export default textAreaInput;
