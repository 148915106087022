import { lazy } from 'react';

import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';

import { RouteInfo } from '../appRoutes';

const CategoryPage = lazy(() => import('@Routes/category/CategoryPage'));

const routes: RouteInfo[] = [
  {
    path: RouteConfig.Category.template,
    component: CategoryPage,
    title: Messages.categoryPage,
  },
];

export default routes;
