import classNames from 'classnames';
import * as React from 'react';

import FloatingNavigation from '@Components/FloatingNavigation';
import DefaultFloatingNavigationContent from '@Components/FloatingNavigation/DefaultFloatingNavigationContent';
import Footer from '@Components/Footer';
import Header, { HeaderBackgrounds } from '@Components/Header';

import styles from './Layout.module.scss';

export enum LayoutBackgrounds {
  grayBackground = 'grayBackground',
  authPageBackground = 'authPageBackground',
}

export interface LayoutProps {
  background?: LayoutBackgrounds;
  headerBackground?: HeaderBackgrounds;
  className?: string;
  noTopPadding?: boolean;
  floatNavigationContent?: React.ReactNode;
}

const Layout: React.FunctionComponent<LayoutProps> = ({
  noTopPadding,
  children,
  className,
  background,
  headerBackground,
  floatNavigationContent,
}) => {
  return (
    <>
      <Header background={headerBackground} />
      <FloatingNavigation>
        {floatNavigationContent === undefined ? <DefaultFloatingNavigationContent /> : floatNavigationContent}
      </FloatingNavigation>
      <main
        className={classNames(
          styles.layout,
          className,
          {
            [styles.noTopPadding]: noTopPadding,
            [styles.withAbsoluteHeader]: headerBackground === HeaderBackgrounds.transparent,
          },
          background && styles[background],
        )}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
