import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getStripePublishableKey } from '@Store/app/app.selectors';

const StripeWrapper: React.FunctionComponent<{}> = ({ children }) => {
  const publishableKey = useSelector(getStripePublishableKey);

  const stripePromise = useMemo(() => (publishableKey ? loadStripe(publishableKey) : null), [publishableKey]);
  if (!publishableKey) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeWrapper;
