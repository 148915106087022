import classNames from 'classnames';
import * as React from 'react';

import styles from './Container.module.scss';

export interface ContainerProps {
  fluid?: boolean;
  className?: string;
  style?: object;
}

const Container: React.FunctionComponent<ContainerProps> = ({ children, fluid, className, style }) => {
  return (
    <div style={style} className={classNames(styles.container, { [styles.fluid]: fluid }, className)}>
      {children}
    </div>
  );
};

export default Container;
