import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { RouteConfig } from '@Config/routes';
import { getFeatureToggle } from '@Store/app/app.selectors';
import { FeatureToggle } from '@Store/app/app.types';
import { HOC } from '@Utils/types';

export default function withFeatureRouteEnabled<P extends JSX.IntrinsicElements>(
  BaseComponent: HOC<P, {}>,
  feature: keyof FeatureToggle,
) {
  return (props: P) => {
    const enabled = useSelector(getFeatureToggle(feature));

    if (!enabled) {
      return <Redirect to={RouteConfig.NotFound.buildLink()} />;
    }

    return <BaseComponent {...props} />;
  };
}
