import i18next from 'i18next';

import { Messages } from '@Config/messages';
import { Errors } from '@Graphql/graphqlTypes.generated';

import { MessageKeysWithoutParams } from './translation';

const errorToMessage: { [key in Errors]: Messages | MessageKeysWithoutParams } = {
  [Errors.AdvertIdsMessageInvalidIdSpecifiedCode]: Messages.errorInvalidIdSpecifiedCode,
  [Errors.WrongImageFormat]: Messages.errorWrongImageFormat,
  [Errors.OrderRatingWithThisOrderAndReportedCompanyAlreadyExists]:
    Messages.errorOrderRatingWithThisOrderAndReportedCompanyAlreadyExists,
  [Errors.StripeCardWithThisIdAlreadyExists]: Messages.errorStripeCardWithThisIdAlreadyExists,
  [Errors.InvalidUsername]: Messages.errorInvalidUsername,
  [Errors.UserWithThisEmailAlreadyExists]: Messages.errorUserWithThisEmailAlreadyExists,
  [Errors.YouDoNotHavePermissionToPerformThisAction]: Messages.errorYouDoNotHavePermissionToPerformThisAction,
  [Errors.ValuesIsNotAValidUuid]: Messages.errorValuesIsNotAValidUuid,
  [Errors.ThePasswordMustBeAlphabetical]: Messages.errorThePasswordMustBeAlphabetical,
  [Errors.ThisPasswordIsTooCommon]: Messages.errorThisPasswordIsTooCommon,
  [Errors.EnterAValidEmailAddress]: Messages.errorEnterAValidEmailAddress,
  [Errors.ThisPasswordIsEntirelyNumeric]: Messages.errorThisPasswordIsEntirelyNumeric,
  [Errors.ThisPasswordIsTooShortItMustContainAtLeast_5Characters]:
    Messages.errorThisPasswordIsTooShortItMustContainAtLeast_5Characters,
  [Errors.ThisFieldCannotBeBlank]: Messages.errorThisFieldCannotBeBlank,
  [Errors.ImageSizeTooLarge]: Messages.errorImageSizeTooLarge,
  [Errors.TooManyImages]: Messages.errorTooManyImages,
  [Errors.WrongCoordinatesFormat]: Messages.errorWrongCoordinatesFormat,
  [Errors.WrongDistance]: Messages.errorWrongDistance,
  [Errors.WrongAddressSize]: Messages.errorWrongAddressSize,
  [Errors.InvalidAdvertPrice]: Messages.errorInvalidAdvertPrice,
  [Errors.InvalidAdvertPricingType]: Messages.errorInvalidAdvertPricingType,
  [Errors.InvalidAdvertCategory]: Messages.errorInvalidAdvertCategory,
  [Errors.SizeMustBeWholeNumber]: Messages.errorOnlyWholeNumbersAllowed,
  [Errors.CompanyActivityRequired]: Messages.errorCompanyActivityRequired,
  [Errors.WrongCompanyInput]: Messages.errorWrongCompanyInput,
  [Errors.CannotSendCompanyCodeForNaturalPerson]: Messages.errorCannotSendCompanyCodeForNaturalPerson,
  [Errors.OverlappingDates]: Messages.errorDatesIsOverlapping,
  [Errors.DatesDifferenceTooSmall]: Messages.errorOrderDateDiffTooShort,
  [Errors.InvalidSchedules]: Messages.errorInvalidSchedules,
  [Errors.EndDateTooBig]: Messages.errorInvalidDateDiff,
  [Errors.CannotHaveSchedulesForGoods]: Messages.errorCannotHaveSchedulesForGoods,
  [Errors.CannotSetDeliveryPriceOnNoDelivery]: Messages.errorCannotSetDeliveryPriceOnNoDelivery,
  [Errors.EndDateCannotBeEarlierStartDate]: Messages.errorEndTimeIsBeforeStartTime,
  [Errors.InvalidCategory]: Messages.errorInvalidCategory,
  [Errors.TooBigAmountValue]: Messages.errorOverMaxAmount,
  [Errors.InvalidStatus]: Messages.errorInvalidStatus,
  [Errors.InvalidAmountValue]: Messages.errorInvalidAmount,
  [Errors.OrderInformationMismatch]: Messages.errorOrderInformationMismatch,
  [Errors.ChatFileExtensionNotAllowed]: Messages.errorChatFileExtensionNotAllowed,
  [Errors.ChatFileSizeTooLarge]: Messages.errorChatFileSizeTooLarge,
  [Errors.NoActiveCompany]: Messages.errorNoActiveCompany,
  [Errors.InvalidGlobalId]: Messages.errorInvalidGlobalId,
  [Errors.AuctionsAreDisabled]: Messages.errorAuctionsAreDisabled,
  [Errors.PaymentFeatureIsDisabled]: Messages.errorPaymentFeatureIsDisabled,
  [Errors.InvalidOrderDates]: Messages.errorInvalidOrderDates,
  [Errors.CannotPlaceOrder]: Messages.errorCanNotPlaceOrder,
  [Errors.InvalidPaymentType]: Messages.errorInvalidPaymentType,
  [Errors.OrderDatesOutOfSchedule]: Messages.errorOrderDatesOutOfSchedule,
  [Errors.InvalidScheduleDates]: Messages.errorInvalidScheduleDates,
  [Errors.AdvertDoesNotHaveDelivery]: Messages.errorAdvertDoesNotHaveDelivery,
  [Errors.AdvertRequiresDeliveryToBeIncluded]: Messages.errorAdvertRequiresDeliveryToBeIncluded,
  [Errors.CannotCalculateTotalPrice]: Messages.errorCannotCalculateTotalPrice,
  [Errors.InvalidSlug]: Messages.errorInvalidSlug,
  [Errors.OrderHasPayment]: Messages.errorOrderHasPayment,
  [Errors.InvalidStripePayment]: Messages.errorInvalidStripePayment,
  [Errors.PaymentMethodDeclined]: Messages.errorPaymentMethodDeclined,
  [Errors.CouldNotWithdrawPayment]: Messages.errorCouldNotWithdrawPayment,
  [Errors.NoSuchPaymentMethod]: Messages.errorNoSuchPaymentMethod,
  [Errors.NoPaymentInfoProvided]: Messages.errorNoPaymentInfoProvided,
  [Errors.PaymentIsMissingForInvoiceGeneration]: Messages.errorPaymentIsMissingForInvoiceGeneration,
  [Errors.ErrorWhileCreatingPdf]: Messages.errorWhileCreatingPdf,
  [Errors.InvoiceTemplateMissingInAdmin]: Messages.invoiceTemplateMissingInAdmin,
  [Errors.GoogleErrorMessage]: Messages.errorGettingGoogleUserInfo,
  [Errors.FacebookErrorMessage]: Messages.errorGettingFacebookUserInfo,
  [Errors.FacebookLetShareEmail]: Messages.errorFacebookLetShareEmail,
  [Errors.AppleErrorMessage]: Messages.errorGettingAppleUserInfo,
  [Errors.AlreadyVerified]: Messages.errorAlreadyVerified,
  [Errors.InvalidInvitationKey]: Messages.invitationKeyNotFoundPage,
  [Errors.UserAlreadyAddedToCompany]: Messages.errorUserAlreadyAddedToCompany,
  [Errors.UsernameExists]: Messages.errorUsernameExists,
  [Errors.ConditionsNotAccepted]: Messages.errorConditionsNotAccepted,
  [Errors.InvalidCredentials]: Messages.errorInvalidCredentials,
  [Errors.InvalidToken]: Messages.errorInvalidToken,
  [Errors.InvalidPassword]: Messages.errorInvalidPassword,
  [Errors.PasswordKeyExpired]: Messages.errorPasswordKeyExpired,
  [Errors.InvalidLanguageCode]: Messages.errorInvalidLanguageCode,
  [Errors.InvalidPermission]: Messages.errorInvalidPermission,
  [Errors.InvalidLookupField]: Messages.errorInvalidLookupField,
  [Errors.RefreshToken]: Messages.errorRefreshToken,
  [Errors.InvitedUserAlreadyExistsInCompany]: Messages.errorUserAlreadyAddedToCompany,
  [Errors.InvitedUserAlreadyHasBeenInvited]: Messages.errorUserAlreadyAddedToCompany,
  [Errors.InvalidField]: Messages.errorInvalidField,
  [Errors.ContentIsTooLong]: Messages.errorContentIsTooLong,
  [Errors.InvalidModel]: Messages.errorInvalidModel,
  [Errors.InternalServerError]: Messages.internalServerError,
  [Errors.CustomFieldsUnknown]: Messages.errorCustomFieldsUnknown,
  [Errors.CustomFieldsRequiredField]: Messages.errorCustomFieldsRequiredField,
  [Errors.CustomFieldsUnexpectedField]: Messages.errorCustomFieldsUnexpectedField,
  [Errors.CustomFieldsInvalidString]: Messages.errorCustomFieldsInvalidString,
  [Errors.CustomFieldsInvalidInteger]: Messages.errorCustomFieldsInvalidInteger,
  [Errors.CustomFieldsInvalidFloatingPoint]: Messages.errorCustomFieldsInvalidFloatingPoint,
  [Errors.CustomFieldsInvalidBoolean]: Messages.errorCustomFieldsInvalidBoolean,
  [Errors.CustomFieldsMinLength]: Messages.errorCustomFieldsMinLength,
  [Errors.CustomFieldsMaxLength]: Messages.errorCustomFieldsMaxLength,
  [Errors.CustomFieldsLessThanMin]: Messages.errorCustomFieldsLessThanMin,
  [Errors.CustomFieldsMoreThanMax]: Messages.errorCustomFieldsMoreThanMax,
  [Errors.CustomFieldsLessThanExclusiveMin]: Messages.errorCustomFieldsLessThanExclusiveMin,
  [Errors.CustomFieldsMoreThanExclusiveMax]: Messages.errorCustomFieldsMoreThanExclusiveMax,
  [Errors.CustomFieldsShouldBeEmptyBecauseNoSchema]: Messages.errorCustomFieldsShouldBeEmptyBecauseNoSchema,
  [Errors.CsvImportInvalidValue]: Messages.errorCsvImportInvalidValue,
  [Errors.CsvImportInvalidFileFormat]: Messages.errorCsvImportInvalidFileFormat,
  [Errors.OrderFormIsDisabled]: Messages.orderFormIsDisabled,
  [Errors.PreliminaryFormContactMethodMissing]: Messages.preliminaryFormContactMethodMissing,
  [Errors.PreliminaryFormContactOnlyOneMethod]: Messages.preliminaryFormContactOnlyOneMethod,
  [Errors.PreliminaryFormContactPlatformNoUser]: Messages.preliminaryFormContactPlatformNoUser,
  [Errors.PreliminaryOrderEmailNotValid]: Messages.preliminaryOrderEmailNotValid,
  [Errors.PreliminaryFormEmailNotProvided]: Messages.preliminaryFormEmailNotProvided,
  [Errors.PreliminaryFormPhoneNotProvided]: Messages.preliminaryFormPhoneNotProvided,
  [Errors.PreliminaryOrderStartDateOlderToday]: Messages.preliminaryOrderStartDateOlderToday,
  [Errors.LoanAmountCannotBeGreaterThanMaxLoanAmount]: 'msg_error_loan_amount_cannot_be_greater',
  [Errors.LoanDurationCannotBeGreaterThanMaxLoanDuration]: 'msg_error_loan_amount_cannot_be_greater',
};

export default function hasOwnProperty<T>(obj: T, key: PropertyKey): key is keyof T {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export const convertErrorCodeToMessage = (key: string): string => {
  if (hasOwnProperty(errorToMessage, key)) {
    return i18next.t(errorToMessage[key]);
  }
  return i18next.t(Messages.unexpectedError);
};
